@import '../../../containers/colors';
@import '../../../containers/mixin';

.overview-navigation {
  width: 62px;
  @include position(fixed, 0 0 0 none);
  background: $overview_navigation;
  text-align: center;

  .nav-container {
    min-height: 750px;
    position: relative;
    height: 100%;
  }

  .logo {
    display: block;
    padding: 16px 0 0;
    height: 62px;

    &.active {
      background-color: $dark_sidebar_primary;
    }
  }

  .rankings {
    &.active {
      background-color: $dark_sidebar_primary;
    }
  }


  #mask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none; /* to make clicks pass through */
    // box-shadow: 0 0 10px #000000 inset;
    @include box-shadow(3px, 0px, 15px, rgba(0, 0, 0, 0.2), true)

  }

  .logo {
    border-bottom: 1px solid lighten($overview_navigation, 15%);

    .icon-home {
      &__active {
        display: none;
      }
    }

    &:hover {
      .icon-home {
        fill: transparent;
        stroke: $green;
      }
    }

    &.active {
      .icon-home {
        display: none;

        &__active {
          display: inline-block;
        }
      }
    }


  }

  .controls {

    .fill-icon {
      stroke: #ffffff;
      fill: transparent;

      &.match {
        stroke: transparent;
        fill: #ffffff;
      }
    }

    .settings,
    .help,
    .intercom {
      svg {
        fill: none;
        stroke: currentColor;
      }
    }

    .help {
      padding: 0;
      border-top: 1px solid lighten($overview_navigation, 15%);
      text-align: center;

      svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }

    .settings {
      border-bottom: none;
    }

    .filter-toggle {
      border-top: 1px solid lighten($overview_navigation, 15%);
    }

    a, span.btn {
      display: block;
      height: 62px;
      width: 62px;
      line-height: 62px;
      color: #fff;
      cursor: pointer;
      border-bottom: 1px solid lighten($overview_navigation, 15%);

      span.text {
        font-size: 18px;
        font-weight: 300;
      }

      > span {
        padding: 0;
        line-height: 62px;
      }

      &.rotate {
        @include transform(rotate(180deg));
      }

      [class^="icon-"] {
        color: #fff;
      }

      &:hover {
        color: $green;

        [class^="icon-"] {
          color: $green;
        }

        .fill-icon {
          stroke: $green;

          &.match {
            stroke: transparent;
            fill: $green;
          }
        }

        .icon-home {
          stroke: $green;
          fill: transparent;
        }
      }

      &.active {

        .icon-home {
          stroke: #FFF;
          fill: #FFF;
        }

        .fill-icon {
          stroke: #fff;
          fill: #fff;

          &.match {
            stroke: none;
            fill: $dark_sidebar_primary;
            background-color: white;
          }
        }
      }
    }

    .controls-group {
      @include transition(max-height .5s ease-out);
      max-height: 62px;
      overflow: hidden;

      &.active {
        background-color: $dark_sidebar_primary;
        max-height: 62px * 4;

        a {
          &:last-child {
            border: none;
          }
        }
      }

    }

    .controls-group + .controls-group {

      &.active {
        max-height: 62px * 4;
      }
    }

    &.controls--top {
      margin: 5px 0 0;
    }

    &.controls--bottom {
      @include position(absolute, none 0 0 0);
    }
  }
}
