@import "../../containers/colors";
@import "../../containers/mixin";

.modal-overlay {
  background: rgba($black_blue, .5);
  @include position(fixed, 0);
  z-index: 255;
}

.modal {
  background: $black_blue;
  padding: 29px;
  width: 387px;
  margin: 10% auto 0;
  box-shadow: 0 2px 4px rgba(#000, .5);
  max-width: 100%;

  .modal-subtitle {
    text-transform: uppercase;
    color: #ADADAD;
    letter-spacing: 0.05em;
    font-size: 15px;
    margin-top: 10px;
  }

  form {
    margin-top: 28px;

    input {
      background: #485D6A;
      border-radius: 2px;
      border: none;
      padding: 12px 14px;
      font-weight: 400;
      font-size: 18px;
    }

    .btn {
      margin-top: 16px;
      min-width: 100px;
    }

    .btn:disabled {
      background: grey !important;
    }
  }
}
