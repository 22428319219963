@import '../../../containers/colors';
@import '../../../containers/mixin';

.history-chart {
  .chart-line,
  .added-line,
  .middle-line,
  .chart-line-2 {
    fill: transparent;
    stroke-width: 1px;
  }

  .chart-line {
    stroke: #96B8C8;
  }

  .chart-line-2 {
    stroke: orange;
  }

  .added-line {
    stroke: $neongreen;
    shape-rendering: crispEdges;
  }

  .middle-line {
    stroke: #D6D6D6;
    shape-rendering: crispEdges;
  }
}