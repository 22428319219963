@import '../../../containers/colors';
@import '../../../containers/mixin';

.top-players-list {
  color: #fff;
  border-radius: 8px;
  overflow: hidden;

  .heading {
    min-height: 94px;
    position: relative;

    .heading__bg {
      @include position(absolute, 0);
      background: {
        size: cover;
        position: center;
        repeat: no-repeat;
      };

      &:after {
        content: '';
        @include position(absolute, 0);
        display: block;
        background: linear-gradient(180deg, rgba(#000, 0.75) 13.56%, rgba(#000, 0) 93.65%);
      }
    }

    .heading__container {
      padding: 16px;
      position: relative;
    }

    .heading__title {
      font-size: 18px;
      line-height: 22px;
    }

    .heading__description {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
