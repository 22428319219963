@import '../../containers/colors';
@import '../../containers/mixin';

.loading {
  background-color: darken($dark_background_primary, 8%);
  @include position(absolute, 0);
  z-index: 100000;

  .progress {
    .progressbar {
      width: 100%;
      height: 100%; //30px
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      background: darken($dark_background_primary, 8%); //lighten($color_dark_blue_background, 10%);

      .meter {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $dark_background_primary; //$color_active;
        transition: all 0.5s ease;
      }
    }
  }

  .loading-container {
    position: absolute;
    height: 100%;
    width: 100%;

    .logo-centered {
      @include centerer;

      .logo-icon {
        color: $neongreen;
        text-align: center;

        svg {
          width: 96px;
          height: 96px;
        }
      }

      .logo-text {
        font-weight: 700;
        color: #fff;
        font-size: 28px;
        letter-spacing: -0.5%;
        margin-top: 16px;
        line-height: 36px;
      }
    }

    .message-centered {
      @include position(absolute, none none 0 100%);
      margin-left: 32px;
      -webkit-animation: fadeIn 1s ease-out 1; /* Chrome, Opera 15+, Safari 5+ */
      animation: fadeIn 1s ease-out 1; /* Chrome, Firefox 16+, IE 10+, Opera */
      font-size: 26px;
      font-weight: 300;
      color: #FFF;
      line-height: 34px;
      white-space: nowrap;
    }
  }

  .loader-container {
    .loader-message {
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      top: 50%;
      left: 50%;
      position: fixed;
      transform: translateX(-50%) translateY(150px);
      text-align: center;
      color: #FFF;
      opacity: .8;
    }
  }

  .loader-container .loader {
    width: 300px;
    height: 300px;
    margin: -150px 0 0 -150px;

    @media (min-width: $mq-tablet-portrait + 1) {
      display: none;
    }

    @media (min-width: $mq-tablet-portrait + 1) and (max-height: 560px) {
      margin: -200px 0 0 -200px;
    }
  }

  .loader-quote {
    color: #fff;
    opacity: .8;
    text-align: center;
    font-size: 18px;
    width: auto;
    -webkit-animation: fadeIn 1s ease-out 1; /* Chrome, Opera 15+, Safari 5+ */
    animation: fadeIn 1s ease-out 1; /* Chrome, Firefox 16+, IE 10+, Opera */
    position: absolute;
    top: 50%;
    left: 16%;
    margin-top: 134px;
    font-weight: 300;
    line-height: 140%;
    padding-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, .07);

    @media (max-height: 560px) {
      display: none;
    }

    @media (min-width: $mq-tablet-portrait + 1) {
      width: 68%;
    }

    .quotemarker {
      font-size: 24px;
      opacity: .7;
      font-weight: bold;
      vertical-align: bottom;
      color: $neongreen;
      padding: 0 6px 0 3px;
    }

    .author {
      color: lighten($neongreen, 6%);
      margin-top: 5px;
      font-size: 15px;
      font-weight: 300;
    }
  }
}

.loader-container {
  position: absolute;
  height: 100%;
  width: 100%;
}

.loader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #72B4A6;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #50AE9A;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #2EA98E;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.loader-inline {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #72B4A6;
  -webkit-animation: spin 1s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader-inline:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #50AE9A;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

.loader-inline:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #2EA98E;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
