@import '../../../../containers/colors';
@import '../../../../containers/mixin';


.league-table {
  color: $black_blue_dark;
  width: 100%;

  thead {
    tr {
      td {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 16px;
        border-bottom: 1px solid $squad_thead_border;

        a {
          display: block;
          padding: 5px;
          cursor: pointer;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (min-width: $mq-tablet-portrait + 1) {
            padding: 8px 13px;
          }

          span {
            display: inline-block;
            position: relative;
          }

          &:hover {
            color: $squad_link_hover;
          }

          &.active {
            font-weight: 500;

            span:before {
              @include position(absolute, 50% none none 100%);
              margin-top: -2px;
              margin-left: 6px;
              content: '';
              border: 0 solid transparent;
              border-left-width: 3px;
              border-right-width: 3px;
            }

            &.asc span:before {
              border-bottom: 4px solid;
            }

            &.desc span:before {
              border-top: 4px solid;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 8px 5px;
        line-height: 18px;
        font-size: 14px;

        @media (min-width: $mq-tablet-portrait + 1) {
          padding: 12px 13px;
        }

        &.age {
          width: 132px;
        }

        &.td-team-name {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 0;
          white-space: nowrap;

          a.team-link {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
          }
        }

        &.text-right {
          text-align: right;
        }

        a {
          color: $squad_link_hover;

          &:hover {
            color: $black_blue_dark;
          }

          &.team-link {
            display: inline-block;
            position: relative;
            padding-left: 25px;

            .pic {
              @include position(absolute, 50% none none 0);
              width: 18px;
              height: 23px;
              margin-top: -12px;
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background-color: $squad_tr_color;
        }
      }

      &.highlight {
        td {
          background: rgba($dark_background_primary, .5);
          color: white;

          > * {
            color: white !important;
          }
        }
      }
    }
  }
}
