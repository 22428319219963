@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.card-charts {
  background: #fff;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 20px 0 15px;

  > div {
    padding-bottom: 5px;

    .axis_name {
      font-size: 12px;
    }

    .Polarchart__pie-label__bg {
      fill: #ffffff;
    }
  }

}
