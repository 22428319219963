//
// Select Menu
// ------------------------------


// wrapper around the menu

.Select-menu-outer {
  // Unfortunately, having both border-radius and allows scrolling using overflow defined on the same
  // element forces the browser to repaint on scroll.  However, if these definitions are split into an
  // outer and an inner element, the browser is able to optimize the scrolling behavior and does not
  // have to repaint on scroll.
  background-color: #fff;
  box-sizing: border-box;
  max-height: $select-menu-max-height;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: $select-menu-zindex;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: -1px;
}


// wrapper

.Select-menu {
  max-height: ($select-menu-max-height);
  overflow-y: auto;
}


// options

.Select-option {
  padding: 12px 13px;
  color: #113245;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &.is-focused {
    background-color: $select-option-focused-bg;
    color: #333;
  }

  &.is-disabled {
    color: $select-option-disabled-color;
    cursor: default;
  }

}


// no results

.Select-noresults {
  box-sizing: border-box;
  color: $select-noresults-color;
  cursor: default;
  display: block;
  padding: $select-padding-vertical $select-padding-horizontal;
}
