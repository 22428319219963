@import '../../../containers/colors';
@import '../../../containers/mixin';

.shadow-team {
  &__content {
    display: flex;
    padding: 35px 0 0;
  }

  &__players-container {
    max-width: 420px;
    margin-right: 50px;
    width: 43.3%;

    h2.heading {
      margin-bottom: 20px;
    }
  }

  &__players-scroll-container {
    border-bottom: 1px solid $squad_thead_border;
  }

  &__lineup-container {
    width: calc(56.7% - 50px);
    max-width: 700px;
  }

  &__players-table {
    width: 100%;
    color: $blue;
    table-layout: fixed;
    font-size: 14px;
    line-height: 1.2;


    th, td {
      padding: 10px 5px;
      vertical-align: middle;

      a {
        color: $blue_neon;

        &:hover {
          color: $primary-color;
        }
      }
    }

    th {
      padding-top: 0;
      padding-bottom: 7px;
      text-align: left;
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      border-bottom: 1px solid $squad_thead_border;
      color: rgba(53, 73, 92, 0.9);
      white-space: nowrap;
      color: $squad_link_hover;
      cursor: pointer;

      &:hover {
        color: $black_blue_dark;
      }

      &.active {
        font-weight: 600;
      }

      .tri {
        color: $squad_link_hover;
        display: inline-block;
        width: 0;
        height: 0;
        vertical-align: middle;
        position: relative;
        top: -1px;

        &.desc {
          border-bottom: none;
          border-top: 4px solid rgba(53, 73, 92, 0.9);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }

        &.asc {
          border-top: none;
          border-bottom: 4px solid rgba(53, 73, 92, 0.9);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }

      .sort {
        width: 6px;
        height: 3px;

        transform: rotate(180deg);

        &.sort-asc {
          transform: rotate(0deg);
        }
      }
    }

    .roundedRat {

      border-radius: 100%;
      border: 1px solid $blue_neon;
      color: $blue_neon;
      width: 32px;
      height: 32px;
      display: block;
      font-weight: 500;

      text-align: center;
      vertical-align: middle;
      line-height: 30px;
      font-size: 12px;
      position: relative;


      &.fill {
        background: $blue_neon;
        color: #FFF;
      }
    }

    .drag-handle {
      width: 24px;
      height: 24px;
      position: relative;
      display: block;

      cursor: pointer;

      color: $blue_neon;

      &:hover {
        color: $primary-color;
      }

      svg {
        width: 20px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translateX(-10px) translateY(-6px);
        position: absolute;
      }
    }

    .pos__name {
      // color: rgba(53, 73, 92, 0.6);
    }

    .col__rating {
      width: 48px;
    }

    .col__img {
      padding: 0 0 0 5px;
      width: 29px;

      img {
        width: 24px;
        height: auto;
      }
    }

    .col__pos {
      width: 40px;
    }

    .col__val {
      width: 40px;
    }

    .col__mins {
      width: 45px;
    }

    .col__drag {
      width: 34px;
      padding-left: 10px;
    }

    tr:nth-child(odd) {
      td {
        background: rgba(196, 211, 225, .2);
      }
    }

    .choosen-one {
      opacity: .5;

      .drag-handle {
        pointer-events: none;
      }
    }
  }

  &__lineup {
    width: 100%;
    height: 0;
    padding-top: 66.7%;
    position: relative;
  }

  &__lineup-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #DBDDE0;

    &:after {
      content: '';
      position: absolute;
      display: block;
      background-color: #DBDDE0;
      width: 1px;
      bottom: 0;
      top: 0;
      left: 50%;
    }

    .home, .away {
      position: absolute;
      display: block;
      color: #DBDDE0;
      width: 15.87%;
      height: 45.45%;
      border-top: 1px solid;
      border-bottom: 1px solid;
      top: 50%;
      transform: translateY(-50%);

      &:before {
        position: absolute;
        display: block;
        content: '';
        height: 60%;
        width: 50%;
        border-top: 1px solid;
        border-bottom: 1px solid;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .home {
      left: 0;
      border-right: 1px solid;

      &:before {
        left: 0;
        border-right: 1px solid;
      }
    }

    .away {
      right: 0;
      border-left: 1px solid;

      &:before {
        right: 0;
        border-left: 1px solid;
      }
    }

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__settings {
    @include clearfix;
    margin: 0 0 25px;
  }

  &__name, &__formation, &__autofill {
    float: left;

    label {
      font-size: 12px;
      line-height: 1.2;
      text-transform: uppercase;
      color: rgba(53, 73, 92, .6);
    }

    .input {
      input, select {
        display: block;
        width: 100%;
        color: $blue;
        font-size: 14px;
        border-bottom: 1px solid;
        border-radius: 0;
      }

      .Select-control {
        input {
          border-bottom: none;
        }
      }
    }
  }

  &__name {
    width: 180px;
    margin-right: 20px;
  }

  &__formation {
    width: 120px;
    margin-right: 20px;
  }

  &__autofill {
    width: 120px;
  }

  &__settings {

  }

  .player-mark {
    position: absolute;
    z-index: 2;
    color: $blue;
    cursor: pointer;

    border: 1px solid rgba(15, 89, 131, 1);
    border-radius: 100%;

    width: 38px;
    height: 38px;
    transform: translate(-50%, -50%);
    background: #f5f6f7;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.preview {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      z-index: 3;
    }

    &:hover, &.hovered {
      color: $primary-color;
    }

    &.inactive {
      color: grey;
    }

    &__with-player {
      display: block;
      border-radius: 50%;
      background: rgba(15, 89, 131, .9);
      text-align: center;

      .rating {
        width: 38px;
        height: 38px;
        font-weight: 500;
        color: #fff;
        vertical-align: middle;
        line-height: 38px;
        font-size: 13px;
        position: relative;
      }

      .name {
        position: absolute;
        text-align: center;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        margin-top: 2px;
        padding: 2px 5px;
        background: #f5f6f7;
        color: #0F5983;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
      }

      .swap {
        position: absolute;
        top: -10px;
        right: -10px;

        width: 10px;
        height: 10px;
        display: block;

        color: $grey_darkness;
      }
    }

    &__no-player {

      svg {
        position: absolute;
        width: 45%;
        height: 45%;
        top: 27.5%;
        left: 27.5%;
        fill: rgba(15, 89, 131, .9);
      }
    }
  }
}
