/*
 * Style for overview, table under scatterplot
 */
@import '../../../containers/colors';
@import '../../../containers/mixin';

.ReactVirtualized__Table {
  border-top: 1px solid darken($dark_background_tertiary, 1%);
  background-color: lighten($dark_background_secondary, 2%);
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 110%;

  & > *:focus {
    outline: none;
  }
}

.ReactVirtualized__Grid {
  -webkit-overflow-scrolling: touch;
}

.headerRow,
.evenRow,
.oddRow {
  background-color: $dark_background_secondary;
  color: #DBE4EE;
}

.oddRow {
  background-color: lighten($dark_background_secondary, 2%);
}

.ReactVirtualized__Table__row:hover {
  color: white !important;
  background-color: $primary-color !important;
  cursor: pointer;
}

.headerRow {
  background: $dark_background_tertiary;
  color: #fff;

  .headerColumn {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;

    .sortActive {
      font-weight: 700;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      height: 1.8em;
      width: 1.8em;
      fill: currentColor;
      margin-top: -3px;
    }
  }
}

.ReactVirtualized__Table__rowColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

