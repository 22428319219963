@import '../../containers/colors';
@import '../../containers/mixin';

.welcome {
  width: 100%;
  min-height: 100%;

  & > .row {
    display: flex;
  }

  .welcome_search {
    display: flex;
    width: 700px;

    .btn-stroked {
      margin: 0 0 0 16px;
      padding-top: 12px;
      padding-bottom: 12px;

      .icon-compare {
        display: inline-block;
        padding: 0 10px 0 0;
      }
    }

    .wc-search {
      flex: auto;
    }
  }

  .welcome__team-logo {
    width: 62px;
    height: 62px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 14px;
  }

  .welcome__team {
    .wrapper {
      box-sizing: border-box;
      height: 100%;
      padding: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-right: 1px solid black;
    }

    height: 100%;
    width: 29%;
    max-width: 340px;
    background: #CC0000;

    color: #FFF;
  }

  .welcome__user {
    h1 {
      margin: 0;
      font-size: 32px;
      line-height: 38px;
      font-weight: 300;

      strong {
        font-weight: 500;
      }
    }

    .team-name, .team-league {
      font-size: 16px;
      line-height: 22px;
    }

    .team-name {
      margin-top: 12px;
    }

    .team-league {
      color: rgba(255, 255, 255, .6);
    }
  }

  .welcome__matches-block {
    margin-top: 10px;
    padding-top: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .15);

    p {
      font-size: 16px;
      // text-transform: uppercase;
      line-height: 22px;

    }

    ul {
      display: flex;
      justify-content: space-between;
      margin: 12px 0 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 10px;
        width: 33%;
        text-align: center;

        .image-wrapper {
          width: 42px;
          height: 42px;
          display: block;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 100%;
          position: relative;
          margin: 0 auto;
        }

        svg {
          border-radius: 100%;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
          width: 20px;
          height: 20px;
          z-index: 2;
          bottom: 0px;
          right: -2px;
          position: absolute;
        }

        .link {
        }

        .team-logo-small, .player-photo {
          display: block;
          position: absolute;
          width: 32px;
          height: 32px;
          background-size: contain;
          background-repeat: no-repeat;
          left: 50%;
          top: 50%;
          background-position: center center;
          margin-left: -16px;
          margin-top: -16px;
        }

        .player-rating {
          font-size: 14px;
          width: 32px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          color: #000;
          font-weight: 500;
          padding: 0;
          border-radius: 10px;
          background: rgba(255, 255, 255, .9);
          position: absolute;
          bottom: 0;
          right: -16px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1);
        }

        .player-photo {
          width: 40px;
          height: 40px;
          margin-left: -20px;
          margin-top: -20px;
          border-radius: 50%;
        }

        .name {
          font-size: 14px;
          line-height: 120%;
          margin-top: 4px;
          opacity: .7;
          color: white;
          display: block;
        }

        .result {
          font-size: 14px;
          width: 42px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          color: #000;
          font-weight: 500;
          padding: 0;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.9);
          position: absolute;
          bottom: 0;
          right: -24px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

          &.wc-won {
            color: #3ba757;
          }

          &.wc-lost {
            color: #ea4335;
          }

          &.wc-draw {
            color: #000;
          }

          &.small {
            width: 20px;
            right: 0;
          }
        }
      }
    }
  }

  .btn-stroked {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .8px;
    text-transform: uppercase;
    color: #FFF;
    padding: 10px 22px;
    border: 1px solid rgba(255, 255, 255, .5);
    display: inline-block;
    border-radius: 6px;

    svg {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 8px;
      margin-top: -4px;
    }

    &:hover {
      background: $color_active;
      border-color: $color_active;
    }
  }

  .welcome__screen {
    height: 100%;
    flex-grow: 1;

    .wrapper {
      box-sizing: border-box;
      min-height: 100%;
      padding: 35px 108px 35px 48px;
    }

    .btn-stroked {
      &.outlinedbutton {
        svg {
          stroke: #ffffff;
          fill: transparent;
        }
      }
    }

    .wc-search {
      display: block;
      background: #3C5164;
      color: rgba(255, 255, 255, .5);
      border-radius: 6px;
      font-size: 16px;
      line-height: 17px;
      padding: 14px;
      vertical-align: middle;

      svg {
        display: inline-block;
        margin-right: 6px;
        fill: #FFF;
        width: 17px;
        height: 17px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, .4);
      }
    }
  }

  .welcome__tops {
    margin: 72px -12px 0;
    position: relative;
    display: inline-block;

    .welcome__tops__column {
      display: inline-block;
      width: 338px;
      margin: 0 12px;
      vertical-align: top;
    }

    .top-controls {
      text-align: center;
      margin-top: 16px;
    }
  }

  .presets-control {
    @include position(absolute, none 12px 0 none);
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    width: 700px;

    @media (max-width: 1919px) {
      width: 338px;
    }

    button {
      color: #FFF;
      cursor: pointer;
      padding: 12px;
      line-height: 0;
      border-radius: 6px;
      position: relative;
      opacity: 1;
      transition: opacity 0.5s;
      pointer-events: auto;

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        background: #405262;
      }

      &[disabled] {
        pointer-events: none;
        opacity: 0;
      }
    }

    .prev-button {
      svg {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  .welcome__top-heading {
    display: flex;
    justify-content: space-between;
  }

  .welcome__top {
    display: flex;
    flex-direction: column;
    position: relative;

    h2 {
      margin: 0 0 12px;
      font-size: 18px;
      line-height: 24px;
      color: #FFF;
    }

    p {
      font-size: 14px;
      color: white;
      opacity: .5;
      line-height: 120%;
      padding: 15px 15px 10px;
      min-height: 58px;
    }

    .list {
      flex-grow: 1;
    }

    .btn-stroked {
      max-width: 280px;
    }
  }

  .welcome__top + .welcome__top {
    margin-left: 35px;
  }

  .welcome__top-player {
    display: flex;
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    margin-top: 1px;
    align-items: start;

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: rgba(#fff, 0.24 - ($i * 0.04));
      }
    }

    &:hover {
      color: $color_active;
    }

    &.inactive {

    }

    .player-photo {
      display: block;
      width: 38px;
      height: 38px;
      background: #4f6a83;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .image-wrapper {
      position: relative;
      width: 40px;
      margin-right: 10px;
      flex-shrink: 0;

      .player-photo {
        display: block;
        width: 38px;
        height: 38px;
        background: #4f6a83;
        border-radius: 50%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .player-locked {
        width: 22px;
        height: 22px;
        text-align: center;
        color: #000;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.9);
        position: absolute;
        bottom: 0;
        right: -5px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

        svg[name="icon-lock"] {
          fill: $blue;
          height: 18px;
          width: 18px;
          margin: 4px -5px 0px 3px;
        }
      }
    }


    .player-info {
      flex: auto;
      max-width: calc(100% - 110px);

      .player-name {
        white-space: nowrap;
        margin-right: 10px;
        line-height: 16px;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        max-width: 100%;

        .name {
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
          padding-right: 16px;
        }

        svg {
          width: 16px;
          height: 16px;
          position: absolute;
          margin-left: -16px;
        }
      }

      .player-description {
        margin-top: 4px;
        font-size: 14px;
        line-height: 16px;

        .player-description-separator {
          opacity: .5;
        }

        .team-logo-small {
          width: 14px;
          height: 20px;
          margin-right: 4px;
          display: inline-block;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          vertical-align: middle;
        }
      }
    }

    .player-rating {
      width: 60px;
      flex-shrink: 0;
      flex-grow: 0;
      display: block;
      color: #FFF;
      white-space: nowrap;

      .player-rating__indicator {
        display: flex;

        .player-rating__indicator__icon {
          width: 18px;
        }

        .player-rating__indicator__value {
          flex: auto;
          text-align: right;
        }
      }

      svg {
        width: 18px;
        height: 18px;
        display: inline-block;
        position: relative;
        top: -1px;
        margin-right: 8px;
      }

      .player-rating__rise {
        color: #03C99E;
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        &.negative {
          color: $red;

          svg {
            transform-origin: 50% 50%;
            transform: rotate(180deg)
          }
        }
      }

      .player-rating__rating {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #03C99E;
      }
    }
  }

  .welcome-banners {
    margin: 52px -12px -12px;
  }

  .welcome__banner {
    display: inline-block;
    position: relative;
    background-size: cover;
    border-radius: 8px;
    color: #FFF;
    font-size: 20px;
    line-height: 24px;
    height: 164px;
    background: {
      color: #405262;
      repeat: no-repeat;
      size: cover;
    };
    overflow: hidden;
    width: 338px;
    margin: 12px 12px;
    vertical-align: top;

    &:hover {
      color: #FFF;
      -webkit-filter: brightness(110%);
      filter: brightness(110%);
      transition: all 0.1s ease-out;
    }

    .welcome__banner__new {
      background: $red;
      color: #fff;
      @include position(absolute, 0 0 none none);
      padding: 5px 8px;
      border-radius: 0 0 0 8px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
    }
  }

  .welcome__banner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: {
      color: rgba(64, 82, 98, 0.65);
      repeat: no-repeat;
      size: cover;
    };

    .b_title {
      display: block;
      position: relative;
      z-index: 2;
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
    }

    .b_button {
      position: relative;
      display: inline-block;
      z-index: 2;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 10px 20px;
      border: 1px solid #fff;
      background-color: transparent;
      border-radius: 6px;
      align-self: flex-start;
      flex-grow: 0;
      transition: box-shadow .35s ease-out;

      svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 8px;
        position: relative;
        top: -2px;

        &[name="wc-rankings"] {
          fill: transparent;
          stroke: #fff;
          stroke-width: 1px;
        }
      }
    }
  }

  .top-players-list__welcome-heading {
    overflow: hidden;
    background: #4E5F70;
    position: relative;
    padding: 16px;
    margin: -16px;
    min-height: 94px;

    svg {
      @include position(absolute, -10px 10px none none);
      width: 124px;
      height: 124px;
    }
  }
}
