@import '../../containers/colors';
@import '../../containers/mixin';

.favorites {
  background-color: $light_background_primary;
  position: relative;
  z-index: 1;
  will-change: transform, opacity;

  .h4 {
    .description {
      display: block;
      font-size: 14px;
      font-weight: normal;
      color: $squad_link_hover;
    }
  }

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-enter-active & {
    opacity: 1;
    @include transform(scale(1));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  .modal-leave & {
    opacity: 1;
    @include transform(scale(1));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  .header-container {
    background: $primary-color;
    color: #fff;
    height: 70px;

    .info-logo {
      padding: 20px 0 0;

      svg {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        display: inline-block;
        vertical-align: middle;
        fill: none;
        stroke: currentColor;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        margin: 0 0px -2px 0px;
        font-weight: 300;
      }
    }

    .pull-right {
      float: right;
    }

    .btn {
      padding: 2px 0;

      .icon-pdf {
        color: white;
        display: inline-block;
        padding: 15px 18px 12px;

        &:hover {
          color: $blue;
        }
      }
    }

  }

  .container {
    margin: 0 auto;
    width: 95%;
  }

  .content-container {
    .container {
      padding: 0px 0px 40px 0;
      font-size: 13px;

      .favorites-filter {
        display: block;
        width: 50%;
        padding: 0 0px;
        margin: 18px 0px 14px 0px;
        position: relative;
        z-index: 1;

        label {
          line-height: 100%;
          font-size: 12px;
          line-height: 1.2;
          text-transform: uppercase;
          font-weight: 400;
          opacity: 0.6;
          display: block;
          margin: 4px 0 3px;
          letter-spacing: .5px;
          color: rgba(53, 73, 92, 0.8);
        }

        .slider-on-white {
          display: block;
          float: left;
          width: 29%;
          vertical-align: top;
          height: 35px;
          margin-right: 2%;

          label {
            margin: 4px 0 5px;
          }
        }

        .position-filter {
          width: 29%;
          display: block;
          float: left;
          margin-left: 4%;
        }

        .position-filter {
        }

        .Select {
          .Select-value {
            color: $squad_link_hover;
            background-color: rgba(15, 89, 131, 0.08);
            border-color: rgba(15, 89, 131, 0.24);
            margin-bottom: 5px;
          }

          .Select-input {
            display: none !important;
          }
        }
      }
    }
  }

  .favorites-list {
    width: 100%;
    margin: 0 0 50px 0;
    color: $black_blue_dark;

    .download {
      cursor: pointer;
    }

    td {

    }

    thead {
      tr {
        td {
          text-transform: uppercase;
          font-size: 13px;
          line-height: 16px;
          border-bottom: 1px solid $squad_thead_border;

          &:first-child {
            color: transparent;
          }

          & > span {
            display: block;
            padding: 8px 5px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a {
            display: block;
            padding: 8px 5px;
            cursor: pointer;
            // white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              display: inline-block;
              position: relative;
            }

            color: $squad_link_hover;

            &:hover {
              color: $black_blue_dark;
            }

            &.active {
              font-weight: 600;

              span:before {
                @include position(absolute, 50% none none 100%);
                margin-top: -2px;
                margin-left: 6px;
                content: '';
                border: 0 solid transparent;
                border-left-width: 3px;
                border-right-width: 3px;
              }

              &.asc span:before {
                border-bottom: 4px solid;
              }

              &.desc span:before {
                border-top: 4px solid;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 12px 5px;
          line-height: 18px;
          font-size: 14px;

          &:first-child,
          &:last-child {
            width: 1%;
          }

          &.age {
            width: 132px;
          }

          &.not_defined {
            color: darken($grey_dark, 20%);
          }

          &.text-right {
            text-align: right;
          }

          a {
            color: $squad_link_hover;

            &:hover {
              color: $black_blue_dark;
            }

            &.player-link {
              display: inline-block;
              position: relative;
              padding-left: 38px;

              .pic {
                @include position(absolute, 50% none none 0);
                width: 26px;
                height: 34px;
                margin-top: -17px;
              }
            }

            &.team-link {
              display: inline-block;
              position: relative;
              padding-left: 23px;

              .pic {
                @include position(absolute, 50% none none 0);
                width: 15px;
                height: 20px;
                margin-top: -10px;
              }
            }

            &.last-rating {
              svg {
                margin-right: 5px;
                display: inline-block;

                &[name="arrow-small-up"] {
                  fill: $green;
                }

                &[name="arrow-small-down"] {
                  fill: $red;
                }
              }

              .gray-circle {
                width: 8px;
                height: 8px;
                display: inline-block;
                margin-right: 5px;
                background: #969696;
                border-radius: 50%;
              }
            }

            &.history-rating {
              width: 120px;
              display: inline-block;
              margin: -6px 0;
            }
          }

          .favorite-actions {
            position: relative;
            padding: 0px 10px;

            a {
              .icon-favorite {
                fill: $squad_link_hover;
                stroke: $squad_link_hover;

                &.active {
                  fill: #fff !important;
                }

                &.category-0 {
                  fill: #407B9C;
                  stroke: #407B9C;
                }

                &.category-1 {
                  fill: #f0df00;
                  stroke: #f0df00;
                }

                &.category-2 {
                  fill: #e6b901;
                  stroke: #e6b901;
                }

                &.category-3 {
                  fill: #e03e3e;
                  stroke: #e03e3e;
                }
              }

              &:hover {
                .icon-favorite {
                  fill: $blue;
                  stroke: $blue;

                  &.category-0 {
                    fill: darken(#407B9C, 5%);
                    stroke: darken(#407B9C, 5%);
                  }

                  &.category-1 {
                    fill: darken(#f0df00, 5%);
                    stroke: darken(#f0df00, 5%);
                  }

                  &.category-2 {
                    fill: darken(#e6b901, 5%);
                    stroke: darken(#e6b901, 5%);
                  }

                  &.category-3 {
                    fill: darken(#e03e3e, 5%);
                    stroke: darken(#e03e3e, 5%);
                  }
                }
              }
            }

            .favorite-confirm {
              @include position(absolute, 50% none none 50%);
              margin: 5px 0 0 5px;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
              background: $neongreen;
              border-radius: 4px;
              display: block;
              text-align: center;
              color: #fff;
              overflow: hidden;
              z-index: 1;

              &.transition-enter {
                opacity: 0.01;
                @include transform(translateY(5px));
              }

              &.transition-enter.transition-enter-active {
                opacity: 1;
                @include transition(opacity 100ms ease-out, transform 100ms ease-out);
                @include transform(translateY(0));
              }

              &.transition-leave {
                opacity: 1;
              }

              &.transition-leave.transition-leave-active {
                opacity: 0.01;
                @include transition(opacity 100ms ease-out);
              }
            }

            .confirm-title {
              display: block;
              padding: 20px;
              white-space: nowrap;
            }

            .confirm {
              display: inline-block;
              width: 50%;
              padding: 10px;
              background: $blue;
              text-transform: uppercase;
              color: #fff;

              &:hover {
                color: $neongreen;
              }
            }
          }

          .player-nation {
            display: inline-block;

            .pic {
              position: relative;
              width: 20px;
              vertical-align: middle;
              display: inline-block;
            }
          }
        }

        &:nth-child(odd) {
          td {
            background-color: $squad_tr_color;
          }
        }
      }
    }
  }

  .group-check {
    margin: 0 0 25px;
    color: $squad_link_hover;
    padding-top: 15px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    .checkbox {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
      border: 1px solid $squad_link_hover;
      margin-right: 8px;
      position: relative;

      .icon-check {
        position: absolute;
        top: 4px;
        left: 2px;
        font-size: 12px;
        color: $squad_link_hover;
      }
    }

    label {
      cursor: pointer;
    }

    label.checked {
      opacity: 1;
    }
  }

  .category-select {
    .Select-control {
      border: none;
      background: none;
    }
  }

  .is-searchable.is-open {
    .Select-control {
      background: none;
    }
  }
}
