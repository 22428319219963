@import '../../containers/colors';
@import '../../containers/mixin';

.confirm-modal {
  width: 470px;

  @media (max-width: $mq-tablet-portrait) {
    width: 80%;
    min-width: 320px;
  }

  .body {
    margin: 0 0 20px;

    a {
      color: $neongreen;

      &:visited {
        color: $neongreen;
      }
    }
  }

  .buttons {
    text-align: right;

    .btn {
      text-transform: uppercase;
      display: inline-block;
      margin-left: 20px;
      color: #FFF !important;
      background: $green_palette;
      min-width: 200px;
      text-align: center;

      &:hover {
        color: #FFF !important;
        background: $neongreen;
      }

      &:disabled {
        background: grey;
        cursor: default;
        color: rgba(200, 200, 200, 1) !important;

        &:hover {
          background: grey;
          color: rgba(200, 200, 200, 1) !important;
        }
      }
    }
  }
}

.privacy-confirm {
  p {
    margin-top: 15px;
    margin-bottom: 30px;
    color: #FFF;
  }

  .h2 {
    font-size: 24px;
    margin: 0 0 1px;
    color: rgba(#fff, .95);
  }

  .with-checkbox {
    position: relative;
    padding-left: 35px;
  }

  .checkbox {
    position: absolute;
    left: 0;
    top: 6px;
    width: 24px;
    height: 24px;
    border: 1px solid #FFF;
    cursor: pointer;
    text-align: center;

    .icon-check {
      display: none;
      font-size: 15px;
      line-height: 20px;
    }

    &.checked {
      .icon-check {
        display: block;
      }
    }
  }
}
