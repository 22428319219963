@import '../../containers/colors';
@import '../../containers/mixin';

.auth-login {
  color: #fff;
  margin-top: 14px;

  input {
    border: 1px solid transparent;
    border-bottom-color: #A7A7A7;
    color: #fff;
    line-height: 18px;
    padding: 11px 1px;
    font-size: 15px;
    width: 100%;
    margin-top: 38px;

    &::-webkit-input-placeholder {
      color: #fff;
      transition: opacity .2s;
      opacity: 1;
    }

    &::-moz-placeholder {
      color: #fff;
      transition: opacity .2s;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #fff;
      transition: opacity .2s;
      opacity: 1;
    }

    &:-moz-placeholder {
      color: #fff;
      transition: opacity .2s;
      opacity: 1;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus
    &:-webkit-autofill {
      border-bottom-color: $primary-color;
      -webkit-text-fill-color: #fff;
      -webkit-box-shadow: 0 0 0px 1000px $blue inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
      border-bottom-color: $primary-color;

      &::-webkit-input-placeholder {
        opacity: 0;
      }

      &::-moz-placeholder {
        opacity: 0;
      }

      &:-ms-input-placeholder {
        opacity: 0;
      }

      &:-moz-placeholder {
        opacity: 0;
      }
    }
  }

  .error-message {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: -30px;
    color: $red;
    display: none;

    &.visible {
      display: block;
    }

    .inactive {
      color: #fff;

      a {
        cursor: pointer;
        color: $primary-color;
      }
    }
  }

  button {
    margin-top: 40px;
    display: inline-block;
    background: #53B78F;
    line-height: 45px;
    min-width: 148px;
    padding: 0 15px;
    text-align: center;
    border-radius: 2px;
    font-weight: 500;

    @media (max-width: $mq-tablet-portrait) {
      width: 100%;
    }

    &:hover {
      color: #fff !important;
      background: $primary-color;
    }
  }
}
