@import '../../../containers/mixin';
@import '../../../containers/colors';

.player-rankings {
  &__flex-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 64px 160px 0;
    align-items: stretch;
  }

  &__rank-container {
    width: calc(50% - 64px);
    margin-left: 64px;
    margin-top: 64px;

    &:hover {
      position: relative;
      z-index: 10;
    }

    @media (min-width: 1439px) {
      width: calc(33.33% - 64px);
    }

    @media (min-width: 1919px) {
      width: calc(33.33% - 64px);
    }
  }

  &__filters {
    position: relative;
    z-index: 25;
    padding: 64px 64px 0;
    display: flex;

    .color-filter {
      margin-left: 64px;
      width: 30%;

      .Select {
        .Select-input {
          height: 39px;
        }

        .Select-value {
          line-height: 39px;

          .Select-value-label {
            padding-left: 0;
          }
        }
      }
    }

    .export {
      margin-left: 64px;
      padding-top: 21px;

      .inline-loader-container {
        width: 129px;
        line-height: 41px;
        white-space: nowrap;
        color: #35495C;
        font-weight: bold;

        .loader-inline {
          margin-right: 8px;
          margin-left: -2px;
          vertical-align: middle;
        }
      }

      .btn-export {
        color: #35495C;
        display: inline-block;
        background: none;
        line-height: 41px;
        padding: 0;
        text-align: center;
        border-radius: 2px;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;

        .pdf-export-icon {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -2px;
        }

        &:hover {
          background: none;
          color: $neongreen;
        }
      }
    }

    .rank-filter {
      width: calc(70% - 64px);

      label {
        svg {
          width: 16px;
          height: 16px;
          margin-left: 8px;
          top: -1px;
          position: relative;
          cursor: pointer;
          display: inline-block;
        }
      }

      .Select--multi {

        .Select-multi-value-wrapper {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 5px;
        }

        .Select-value {
          font-size: 13px;
          line-height: 19px;

          .Select-value-icon {
            display: inline-block;
            color: #016e53;
          }

          .Select-value-label {
            color: $black_blue;
            font-size: 13px;
            line-height: 19px;
            white-space: nowrap;
          }
        }

        &.has-value {
          .Select-input {
            z-index: 1;
            width: auto !important;

            input {
              width: auto !important;
            }
          }
        }

        &.is-focused {

        }
      }

      .Select-aria-only {
        display: none !important;
      }

      .Select-value-icon {
        display: inline-block;
      }
    }

  }
}

.report-name-confirm {
  .h2 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 22px;
    color: #FFF;
    margin: 0 0 25px;
  }

  input {
    color: #FFF;
    border-bottom: 1px solid $neongreen;
    display: block;
    margin: 0 0 25px;
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;

    &:focus {
      border-bottom: 1px solid #FFF;
    }

    &::placeholder {
      color: rgba(255, 255, 255, .75);
    }
  }
}
