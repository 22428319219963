@import '../../../containers/colors';

.data-item {
  display: inline-block;

  .data-text {
    color: $black;
    font-size: 16px;
    line-height: 1;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;

    .pic {
      height: auto;
      width: 25px;
      display: inline-block;
      position: relative;
      margin-bottom: -6px;
    }

    svg[name="icon-lock"] {
      fill: $blue;
      height: 18px;
      width: 18px;
      margin: 0px 0px 0px 2px;
    }
  }

  a.data-text {
    color: $squad_link_hover;

    &:hover {
      color: darken($squad_link_hover, 10%);
    }
  }

  .data-desc {
    line-height: 100%;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.6;
    display: block;
    margin: 1px 0 0 0;
    letter-spacing: .5px;
    color: $blue_light;
  }

  &.reverse {
    .data-text,
    .data-desc {
      color: #fff;
    }
  }
}
