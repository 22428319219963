@import '../../containers/colors';
@import '../../containers/mixin';

.favorites {
  .nav-container {
    /*height: 43px;*/
    background: $dark_background_primary;
    position: relative;

    a {
      padding: 12px 10px 9px;

      &.active {
        color: $primary-color;
        font-weight: 500;
        border-bottom: 3px solid $primary-color;
        padding: 12px 10px 8px;
      }

      &:hover {
        color: $primary-color;
        font-weight: 500;
      }
    }
  }
}
