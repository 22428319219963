@import '../../containers/colors';
@import '../../containers/mixin';

.styled-react-select {
  font-size: 14px;

  &.Select--single {
    .Select-control {
      height: auto;
      border: none;
      background: transparent;
      border-bottom: 1px solid $blue !important;

      input {
        border-bottom: none;
        padding-top: 3px;
      }

      .Select-value {
        line-height: 26px;
        left: 0;

        .Select-value-label {
          padding: 0;
        }
      }

      .Select-input {
        height: 22px;
        padding-left: 0;

        input {
          padding-left: 0;
          background: transparent;
        }
      }
    }
  }

  &.has-value.Select--single, &.is-focused.Select--single, &.has-value.is-pseudo-focused.Select--single {
    .Select-control {
      .Select-value {
        .Select-value-label {
          padding: 0 !important;
        }
      }
    }
  }
}
