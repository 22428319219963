@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.notes-desktop-container {
  .notes-filter {
    width: 26%;
    min-width: 150px;
    max-width: 292px;
    flex-grow: 1;
    flex-shrink: 1;
    background: #fff;
    // border-right: 1px solid $color_light_border;

    &__section {
      margin: 20px 15px 30px;
    }

    &__section-header {
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
      color: $anthrazit;
      // padding-bottom: 18px;
      margin: 35px 15px 0px;
    }

    &__section-title {
      font-size: 13px;
      font-weight: 500;
      padding-left: 12px;
      margin-bottom: 4px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $blue;
      opacity: .6;
    }


    &__search {
      font-size: 12px;
      line-height: 18px;
      /*border-bottom: 1px solid $color_light_border;*/
      padding: 3px 12px;
      width: 100%;
      display: block;
      margin: 5px 0;
      color: $color_text_light;

      &::placeholder {
        color: $color_placeholder
      }
    }


    &__item {
      display: flex;
      position: relative;
      margin-top: 1px;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      color: $color_link;
      padding: 3px 12px 3px 25px;
      cursor: pointer;
      border-radius: 2px;
      background: transparent;

      transition: background $time_transition ease-out;

      &.player {
        padding-left: 12px;
      }

      &.all-items {
        padding-left: 12px;
      }

      &.category {
        &:before {
          content: '#';
          position: absolute;
          left: 12px;
        }
      }

      &.author {
        padding-left: 12px;

        &.active {
          padding-left: 30px;

          .icon-check {
            font-size: 12px;
            line-height: 20px;
            position: absolute;
            left: 12px;
          }
        }
      }

      .pic {
        position: absolute;
        top: 50%;
        left: 12px;
        width: 15px;
        height: 20px;
        margin-top: -10px;
      }

      &:hover {
        background: lighten($color_active_bg_blue, 1%);
      }

      .text {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.active {
        background: lighten($color_active_bg_blue, 6%);

        .text {
          font-weight: 500;
        }
      }

      .label {
        flex-grow: 0;
        opacity: .5;
      }
    }

  }
}
