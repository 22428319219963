@import '../../containers/colors';
@import '../../containers/mixin';

.competition-level-window-trigger {
  padding: 0;
  display: inline-block;
  color: $color_link;

  &:hover {
    color: #0a3a55;
  }
}

.competition-level-hint-trigger {
  display: inline-block;
  width: 14px;
  height: 14px;
  color: #000;
  padding: 0;
  margin: 0 0 0 6px;

  svg {
    width: inherit;
    height: inherit;
  }
}
