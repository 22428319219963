/*
 * Style for compare, header
 */

@import '../../../containers/colors';

.compare .header-container {
  background-color: $primary-color;
  color: white;

  .header {
    height: 55px;

    .header__actionButtons {
      height: 100%;
      position: relative;
    }

    .download {
      float: right;
      padding: 17px 18px;

      .icon-pdf {
        padding: 0;
      }
    }

    & > div {
      height: 100%;
      position: relative;
    }

    .btn {
      padding: 1px;
      float: right;
    }

    .icon-close {
      padding: 17px 20px 17px 30px;
      font-size: 18px;
    }

    .icon-compare {
      padding: 17px 10px;
    }

    .title {
      display: inline-block;
      font-size: 16px;
      padding: 15px 0px 0px 0px;
    }
  }
}


