@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.team-positions {

  .canvas {
    position: relative;
    width: 254px;

    .position {
      position: absolute;

      .jersey_num {
        width: 32px;
        height: 32px;
        display: block;
        margin: -16px 0 0 -16px;
        border-radius: 50%;
        background: rgba(15, 89, 131, .9);
        border: 1px solid rgba(15, 89, 131, 1);
        font-weight: 500;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
        font-size: 13px;
        position: relative;
        @include transition(all .1s);
      }

      .position_name {
        color: #0F5983;
        font-size: 12px;
        text-align: center;
        margin: 0 0 0 -35px;
        width: 70px;
        letter-spacing: -0.01em;
        text-shadow: 1px 1px 0 $grey_light, -1px -1px 0 $grey_light, -1px 1px 0 $grey_light, 1px -1px 0 $grey_light,
        2px 2px 0 $grey_light, -2px -2px 0 $grey_light, -2px 2px 0 $grey_light, 2px -2px 0 $grey_light;
        @include transition(all .1s);

        @media (max-width: 1280px) {
          font-size: 11px;
          letter-spacing: 0;
        }
      }

      .substitution {
        @include position(absolute, -2px none none 0);
        margin: 0 0 0 -6px;


        &.substitution-out {
          border: 8px solid transparent;
          border-top: 12px solid $grey_light;
          border-bottom: none;

          &:after {
            content: '';
            @include position(absolute, 0 none none 0);
            border: 6px solid transparent;
            border-top: 9px solid #BB5700;
            border-bottom: none;
            margin-top: -11px;
            margin-left: -6px;
          }
        }

        &.substitution-in {
          border: 8px solid transparent;
          border-bottom: 12px solid $grey_light;
          border-top: none;

          &:after {
            content: '';
            @include position(absolute, 0 none none 0);
            border: 6px solid transparent;
            border-bottom: 9px solid #45BB00;
            border-top: none;
            margin-top: 2px;
            margin-left: -6px;
          }
        }
      }

      .card {
        width: 11px;
        height: 16px;
        border: 1px solid $grey_light;
        display: inline-block;
        @include position(absolute, -7px -4px none none);

        &.yellow {
          background: #FFD855;
        }

        &.yellow-second {
          background: #FFD855;
          margin-right: 5px;

          &:before {
            content: '';
            width: 11px;
            height: 16px;
            border: 1px solid #E4E4E4;
            background: $red;
            @include position(absolute, -1px none none 5px);
          }
        }

        &.red {
          background: $red;
        }
      }

      &:hover {
        .jersey_num {
          background: rgba(1, 186, 140, .8);
          border-color: rgba(1, 186, 140, 1);
        }

        .position_name {
          color: rgba(1, 186, 140, .8);
        }
      }

      &.home {
        .jersey_num {
          background: rgba($team_home, 1);
          border-color: $team_home;
        }

        .position_name {
          color: $team_home;
        }

        &:hover {
          .jersey_num {
            background: $team_home;
            border-color: $team_home;
          }

          .position_name {
            color: $team_home;
          }
        }
      }

      &.away {
        .jersey_num {
          background: rgba($team_away, 1);
          border-color: $team_away;
        }

        .position_name {
          color: $team_away;
        }

        &:hover {
          .jersey_num {
            background: $team_away;
            border-color: $team_away;
          }

          .position_name {
            color: $team_away;
          }
        }
      }
    }
  }

  .line-ups-list {
    // margin-left: 220px;
    &.has-selected {
      .position-item {
        opacity: .5;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
