/*
 * Style for details, summary
 */

@import '../../../containers/colors';
@import '../../../containers/mixin';

.summary {
  h1 {
    margin: 10px 0px 20px;
    font-size: 15px;

    .icon-against:before {
      font-size: 21px;
    }

    .icon-on:before {
      font-size: 22px;
    }

    &.line:after {
      width: 25%;
    }
  }

  .summary_profile {
    & > .row > .col-6 {
      margin-right: 7%;
    }

    .profile-dates {
      .data-text {
        white-space: normal;

        span {
          display: inline-block;
          margin-bottom: 3px;

          &:after {
            content: '\00a0';
          }
        }
      }

      .data-desc {
        margin-top: 0;
      }
    }
  }

  .summary_data .h4 {
    margin-bottom: 30px;
  }

  .ColorScale {
    position: relative;
    right: auto;
    bottom: auto;
    text-align: right;

    svg {
      display: inline-block;
    }
  }

  .data-item:nth-child(1), .data-item:nth-child(2) {
    @media (min-width: $mq-tablet-portrait + 1) {
      margin-top: 4px;
    }
  }

  .line-ups-list .data-item {
    margin-top: 24px;
  }

  .summary_similar {
    margin: 0 0 80px 0;
  }

  .row {
    &.first_el {
      margin-bottom: 20px;
    }
  }

  .bar_thin {
    width: 75%;
    height: 5px;
    left: 0px;
    bottom: 2px;
    position: relative;
    display: block;

    .bar_thin_color {
      background: $primary-color;
      position: absolute;
      left: 0px;
      height: 100%;
      @include border-radius(3px 0px 0px 3px)
    }

    .bar_thin_bg {
      background: $light_divider;
      width: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
      @include border-radius(3px)
    }
  }

  .summary_performance .charts_bar .row {
    margin-bottom: 30px;
  }

  .summary_data .charts_polar h3 {
    text-align: center;
    width: 360px;
  }

  .charts_radar {
    @media (max-width: $mq-tablet-portrait) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 20px -10px;

      .chart_group.radar.radar_1 {
        margin: 0;
      }
    }
  }

  .chart_group.hide {
    display: block;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .chart_group.radar.radar_1, .summary_data .charts_polar.charts_polar_1 h3 {
    margin-left: -35px;
  }

  .direction-indicator {
    .direction-indicator-up {
      color: $neongreen;

      &.direction-indicator-rotate {
        @include transform(rotate(45deg));
      }
    }

    .direction-indicator-circle {
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-right: 5px;
      background: #969696;
      border-radius: 50%;
    }

    .direction-indicator-down {
      color: $red;

      &.direction-indicator-rotate {
        @include transform(rotate(-45deg));
      }
    }
  }

  .external_links {
    line-height: 0;

    a {
      vertical-align: top;
      margin-right: 20px;
      display: inline-block;
    }

    .details-transfermarkt-action {
      width: 22px;
      height: 22px;
      background: {
        size: 22px 22px;
        position: center center;
        repeat: no-repeat;
        image: url("./assets/tm.png");
      }
    }

    .details-google-news-action {
      width: 26px;
      height: 26px;
      position: relative;
      top: -3px;

      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  .positions-list {

    columns: 2;

    .position-item, .position-item-meta {
      width: 100%;
      display: block;
      float: none;
      break-inside: avoid;

      margin-top: 0 !important;
      margin-bottom: 24px;
    }

    .position-item {
      padding-left: 16px;

      &.position_group {
        padding-left: 0;
      }
    }

    &.has-selected {
      .position-item {
        opacity: .6;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

.position-canvas {
  margin-bottom: 24px;
}


