/*
 * Style for details
 */

@import '../../containers/colors';
@import '../../containers/mixin';

.blurred {
  display: none;
}

.no-access {
  svg[name="icon-lock"], svg[name="icon-unlock"] {
    fill: $blue;
    height: 18px;
    width: 18px;
    margin: 2px -5px 0px 3px;
  }

  svg[name="icon-unlock"] {
    fill: white;
    margin: 2px 2px 0px 0px;
  }

  .content_group, .content_group:last-child {
    margin: 0px;
  }

  .summary-text {
    margin-bottom: 10px;
  }

  .paywall {
    background: $light_background_primary;
    width: 100%;
    position: relative;
    padding: 0 0 20px;
    text-align: center;

    &:before {
      display: block;
      position: absolute;
      height: 100px;
      width: 100%;
      top: -100px;
      background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1) 0, $light_background_primary);
      content: "";
      left: 0;
      bottom: 0px;
      z-index: 1;
    }

    h2 {
      &:before {
        position: absolute;
        top: 0;
        width: 70px;
        height: 3px;
        background: $color_active;
        content: "";
        left: 0;
      }

      font-size: 24px;
      font-weight: 500;
      margin-bottom: 5px;
      padding-top: 30px;
      color: $black_light;
      position: relative;
    }

    p {
      font-size: 16px;
      opacity: .6;
    }

    a {
      margin-top: 20px;
      margin-bottom: 60px;
      display: inline-block;
      background: $blue_auth_container;

      line-height: 50px;
      width: 260px;
      padding: 0;
      text-align: center;
      border-radius: 2px;
      font-weight: 500;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: .2px;
      cursor: pointer;

      &:hover {
        color: #fff !important;
        background: $black_blue;
      }
    }
  }
}

.details {
  background-color: $light_background_primary;
  z-index: 1;
  will-change: transform, opacity;

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-enter-active & {
    opacity: 1;
    @include transform(scale(1));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  .modal-leave & {
    opacity: 1;
    @include transform(scale(1));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  .row.keeper {
    margin-bottom: 30px;
  }

  .h4 {
    .description {
      display: block;
      font-size: 14px;
      opacity: .6;
    }
  }

  .h3 {
    .description {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .alternatives {
    .h3 {
      margin: 80px 0 14px;
    }

    &.first .h3 {
      margin: 40px 0 14px;
    }

    .alternatives-filter {
      width: 16%;
      margin: 25px 15px 0 0;
    }
  }

  .similar {
    @media (max-width: $mq-tablet-portrait - 1) and (orientation: landscape) {
      margin: 0 -5px;
    }

    .similar-player {
      position: relative;

      .icon-compare {
        @include position(absolute, 0 0 none none);
        color: $blue;
        padding: 7px 11px 7px 11px;

        &:before {
          font-size: 14px;
        }
      }
    }

    .similar-link {
      background: lighten($light_background_primary, 2%);
      border-bottom: 1px solid lighten($blue, 40%);
      display: block;
      overflow: hidden;

      &:hover {
        background: darken($light_background_primary, 2%);
      }

      + .similar-link {
        margin-top: 10px;

        @media (min-width: $mq-tablet-portrait + 1) {
          margin-top: 0;
        }
      }

      @media (max-width: $mq-tablet-portrait) and (orientation: landscape) {
        width: calc(50% - 20px);
        display: inline-block;
        margin: 0 10px;
      }
    }

    .player_content {
      padding: 0px 0px 5px 5px;
    }

    .playerProfile .pic {
      max-width: 80px;
      height: 108px;
      position: relative;
      // margin-top: 20px;
      > div {
        @include clip-path(polygon(0 0, 99% 0, 75% 100%, 0 100%));
        clip-path: url('#clipPolygon'); /*Firefox*/
        background-position: bottom center !important;
        background-size: cover !important;
        background-color: darken($light_background_primary, 5%);
        height: 100%;
      }
    }

    .teamLogo .pic {
      position: relative;
      margin-top: -31px;
      height: auto;
      width: 22px;
      right: 0;
      margin-left: 63%;
    }

    .player_name {
      font-size: 14px;
      margin: 10px 0 5px;
      display: block;
      color: $squad_link_hover;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      padding-right: 30px;
      text-transform: uppercase;
      letter-spacing: .2px;
    }

    .similar-toggle {
      font-size: 13px;
      color: $blue_neon;
      display: block;
      padding: 25px 10px 36px;
      text-align: center;
      text-transform: uppercase;
      line-height: 13px;

      span {
        display: block;
        font-size: 36px;
        font-weight: 100;
        line-height: 90%;
        opacity: .9;
      }
    }

    .data-item {
      .data-text {
        font-size: 14px;
      }

      .data-desc {
        font-size: 12px;
      }
    }
  }

  .no-alternatives {
    text-transform: uppercase;
    opacity: .6;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .2px;
    margin-top: 25px;
  }

  .chart-legend {
    margin-top: 2px;
    text-align: right;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    color: $blue_lightest;

    .chart-legend-list {
      text-align: left;
      display: inline-table;

      .ColorScale {
        margin: 0 auto 0;
        bottom: 8px;
        left: 0;
        right: auto;
      }

      .chart-legend-list-row {
        display: table-row;
      }

      .chart-legend-list-item {
        display: table-cell;

        .legend-color-col,
        .legend-image-col,
        .legend-label-col {
          vertical-align: top;
          display: table-cell;
          padding-top: 8px;
        }

        .legend-label-col {
          opacity: .7;
        }

        .legend-color-col {
          padding-right: 7px;
          line-height: 0;
          font-size: 0;
          padding-top: 8px;

          .legend-color {
            display: inline-block;
            width: 14px;
            height: 14px;
            @include border-radius(10%);

            &.legend-style-mean {
              background: #b8b8b8;
              background: repeating-linear-gradient(
                  45deg,
                  #f8f9fa 0,
                  #f8f9fa 1px,
                  #b8b8b8 1px,
                  #b8b8b8 2px,
                  #f8f9fa 3px,
                  #f8f9fa 4px
              );
            }
          }
        }

        .legend-image-col {
          padding-right: 5px;
          font-size: 0;
          line-height: 0;

          .pic {
            display: inline-block;
            position: relative;
            width: 14px;
            height: 18px;
            margin: -2px 0;
          }
        }

        & + .chart-legend-list-item {
          padding-left: 24px;
        }
      }
    }
  }


  .react-swipeable-view-container {
    //user-select: ;
  }

  .not-found {
    @include position(fixed, 50% 0 none 0);
    text-align: center;
    font-size: 18px;
    font-weight: 300;

    .not-found-text {
      text-transform: uppercase;
    }

    .not-found-link {
      margin-top: 10px;

      a {
        display: inline-block;
        background: #53B78F;
        line-height: 40px;
        padding: 0 15px;
        text-align: center;
        border-radius: 2px;
        font-weight: 400;
        color: #fff;

        &:hover {
          background: #01BA8C;
        }
      }
    }
  }

  &.mobile {
    margin-top: 50px;

    .bar-container {
      .header {
        height: auto;
        position: relative;

        .header__profile_key {
          .col-3 > .pic {
            width: 90px;
          }

          .header__profile_key_name {
            left: 90px;
          }
        }

        .header__actionButtons_container {
          .icon-pdf, .icon-close {
            vertical-align: middle;
          }

          .details-favorite-action, a {
            vertical-align: top;
            display: inline-block;
          }

          .details-favorite-action,
          .details-transfermarkt-action {
            padding: 13px 14px;
          }

          .details-transfermarkt-action {
            width: 50px !important;
          }

          .icon-pdf {
            padding: 15px 14px 12px;
          }
        }

        .header__profile_detail {
          padding: 5px 0 5px;
          margin: 0 -5px;

          .data-item {
            width: auto;
            margin: 7px 15px;


            .data-text .pic {
              margin-bottom: -3px;
              margin-right: 4px;
            }
          }
        }

        .header__filter_select {
          padding: 0 10px 10px;

          .data-text {
            overflow: visible;
          }
        }
      }
    }

    .row.position {
      margin-bottom: 40px;
    }

    .content-container {
      margin: 0 10px 80px;
    }

    .content_group {
      margin: 0 0 60px 0;
    }

    .summary h1 {
      margin: 50px 0 20px;
    }

    .h4 {
      margin-bottom: 10px;
    }

    .chart-legend {
      text-align: left;
    }

  }

  &.details--match {
    .BarChart .BarChart__bar_rect_item_0 {
      fill: $team_home;
    }

    .Polarchart .Polarchart__item_0 {
      fill: $team_home;
      stroke: $team_home;
    }

    .BarChart .BarChart__bar_rect_item_1 {
      fill: $team_away;
    }

    .Polarchart .Polarchart__item_1 {
      fill: $team_away;
      stroke: $team_away;
    }
  }

  .future-match {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    margin: 35px 0 0;
    font-weight: 300;
  }

  .player-ranking__list-legend {
    .legend {
      text-transform: uppercase;
    }
  }
}

.details > div > div {
  overflow-x: hidden !important;
}

.details.mobile > div > div {
  overflow-x: visible !important;
  padding: 10px;
}
