@import '../../../containers/colors';
@import '../../../containers/mixin';

.note-player-select {

  max-width: 350px;

  &.Select--single,
  &.is-focused.Select--single,
  &.is-pseudo-focused.Select--single,
  &.is-open.Select--single {
    & > .Select-control {
      height: 43px;
      border: none;

      .Select-placeholder {
        height: 43px;
        font-size: 14px;
        line-height: 43px;
        font-weight: 500;
        color: $color_placeholder;
      }

      .Select-value {
        left: 0;
        line-height: 43px;
        height: 43px;

        .Select-value-label {
          display: block;
        }
      }
    }
  }

  .Select-menu-outer .player-text {
    line-height: 20px;
    padding-top: 6px;
  }

  &.filter-select {

    margin-top: 5px;

    .player-card {
      display: flex;
      font-size: 14px;
      line-height: 32px;
      position: relative;

      background: transparent;
      transition: background $time_transition ease-out;

      .count {
        position: absolute;
        right: 0;
        top: 0;
      }

      div, span {
        display: block;
      }

      .player-img {
        width: 16px;
        height: 26px;
        margin-right: 8px;
        margin-top: 5px;

        .player-photo .pic {
          max-width: 17px;
          height: 20px;
          position: relative;

          > div {
            @include clip-path(none);
            clip-path: none; /*Firefox*/
          }
        }
      }

      .player-text {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        .player-name {
          font-weight: 500;
          font-size: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          color: $squad_link_hover;
        }
      }

      &.empty {
        opacity: .5;
      }
    }

    &.Select--single,
    &.is-focused.Select--single,
    &.is-pseudo-focused.Select--single,
    &.is-open.Select--single {
      & > .Select-control {
        height: 32px;
        border: none;
        background: transparent;
        border-bottom: 1px solid $color_light_border;

        .Select-placeholder {
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          font-weight: 500;
          color: $color_placeholder;
        }

        .Select-input {
          height: 32px;

          input {
            padding: 0;
            font-size: 14px;
            line-height: 32px;
          }
        }

        .Select-value {
          left: 0;
          line-height: 32px;
          height: 32px;

          .Select-value-label {
            display: block;
          }
        }
      }
    }
  }


}
