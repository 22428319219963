@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.footer-container {
  @include box-shadow(0px, -2px, 5px, rgba(0, 0, 0, 0.1));
  @include position(fixed, none 0 0);
  height: 45px;
  background: $anthrazit;
  text-align: center;
  line-height: 41px;
  z-index: 2;

  a {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
    height: 45px;
    color: #fff;

    &.disabled {
      opacity: .5;
    }

    &.footer-photo, &.footer-logout {
      opacity: .8;
    }
  }
}
