@import '../../../../containers/colors';

.data-rank {
  .rank-value {
    font-size: 26px;
    color: $anthrazit;
    margin: -6px 0px 4px;
    line-height: 24px;
    color: #2D343C;
    font-weight: 400;
    opacity: .9;
    vertical-align: top;

    .ordinal {
      font-size: 14px;
      font-weight: 300;
      vertical-align: text-top;
      text-transform: uppercase;
      letter-spacing: .5px;
      margin: 0px 1px;
    }
  }

  .data-desc {
    margin-top: 1px;
    font-size: 11px;
    color: $anthrazit;
  }
}