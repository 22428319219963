@import '../../../containers/colors';
@import '../../../containers/mixin';

.notes-desktop-container {
  display: flex;
  padding-bottom: 0 !important;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 0;
      margin: 0;
    }
  }

  .notes-desktop-body {
    display: flex;
    background: white;
  }

  .notes-desktop-body {
    flex-shrink: 1;
    flex-grow: 1;
    width: 78%;
    max-width: 12015px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .notes-list {
      &__item {
        display: flex;
        cursor: pointer;
        border-bottom: 1px solid $color_light_border;
        padding: 10px 12px 7px 10px;
        border-left: 2px solid transparent;
        transition: background $time_transition ease-out, border-color $time_transition ease-out;

        &:hover {
          background: $color_active_light_blue;
        }

        &.active {
          cursor: default;
          padding-left: 12px;
          border-left: 2px solid $color_active;
          background: $color_active_light_blue;
        }

        h4 {
          font-size: 15px;
          line-height: 21px;
          font-weight: 500;
          color: $blue;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .text-preview {
          font-size: 14px;
          line-height: 18px;
          color: $color_text_light;
          height: 36px;
          overflow: hidden;
        }
      }

      &__item-tag .note-tag {
        background: none;
        font-size: 13px;
        color: $color_meta_text;
        padding: 1px 6px 1px 0px;
        font-weight: 500;
      }

      &__item-meta {
        margin-top: 2px;
        font-size: 12px;
        line-height: 17px;
        color: $color_meta_text;
        display: flex;
        text-transform: uppercase;
        letter-spacing: .3px;

        .author {
          flex-grow: 1;
        }

        .date {
          text-align: right;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      &__item-player-pic {
        width: 36px;
        height: 43px;
        margin-right: 12px;

        .player-photo .pic {
          max-width: 36px;
          height: 43px;
          position: relative;

          > div {
            @include clip-path(polygon(0 0, 99% 0, 75% 100%, 0 100%));
            clip-path: url('#clipPolygon'); /*Firefox*/
          }
        }

        .team-logo .pic {
          position: relative;
          margin-top: -20px;
          height: auto;
          width: 16px;
          right: 0;
          margin-left: 21px;
        }
      }

      &__item-text {

      }
    }


  }
}

.player-card {
  display: flex;
  font-size: 14px;
  line-height: 21px;

  background: transparent;
  transition: background $time_transition ease-out;

  div, span {
    display: block;
  }

  .player-img {
    width: 36px;
    height: 43px;
    margin-right: 12px;

    .player-photo .pic {
      max-width: 36px;
      height: 43px;
      position: relative;

      > div {
        @include clip-path(polygon(0 0, 99% 0, 75% 100%, 0 100%));
        clip-path: url('#clipPolygon'); /*Firefox*/
      }
    }

    .team-logo .pic {
      position: relative;
      margin-top: -20px;
      height: auto;
      width: 16px;
      right: 0;
      margin-left: 21px;
    }
  }

  .player-text {
    .player-name-last, .player-name-first {
      text-transform: uppercase;
      color: $squad_link_hover;
      letter-spacing: .5px;
      line-height: 120%;
    }

    .player-name-first {
      font-size: 13px;
    }

    .player-name-last {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}
