@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';


.notes-mobile {
  width: 20px;
  height: 20px;
  margin: 0 12px;
  display: inline-block;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    position: absolute;
    background: #FFF;
    right: 0;
    width: 230px;
    max-height: 280px;
    top: 100%;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);
  }

  &__create {
    display: block;
    width: 100%;
    background: $dark_actionbar;
    color: #FFF;
    padding: 16px 16px;
    border-radius: 0 0 4px 4px;
    font-size: 16px;
    line-height: 22px;

    &:focus, &:hover {
      color: #FFF;
    }
  }

  &__list {
    max-height: 220px;
    overflow-y: auto;
  }

  &__list-item {
    padding: 10px 15px;
    border-top: 1px solid $color_light_border;

    .title {
      color: $color_content_title;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }

    .author {
      font-size: 13px;
      line-height: 18px;
      color: $color_meta_text;
    }

    &:nth-child(1) {
      border-radius: 4px 4px 0 0;
    }
  }

  &__form {
    background: #FFF;
    z-index: 10999;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    // border-radius: 4px;
    padding: 20px 20px 50px;
    overflow-y: auto;

    textarea {
      width: 100%;
      resize: none;
      background: #FFF;
      border: 1px solid $color_light_border;
      padding: 10px 10px;
      border-radius: 2px;
      box-sizing: border-box;
    }

    button {
      display: inline-block;
      font-size: 14px;
      line-height: 26px;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 10px 20px;
      border-radius: 2px;
      min-width: 120px;
      text-align: center;
      border: 1px solid $color_light_border;
      color: $color_content_text;
      box-sizing: border-box;
    }

    .Select--multi .Select-multi-value-wrapper {
      border-bottom: 1px solid $color_light_border;
      width: 100%;
    }

    .note-tag-select.Select.is-searchable.Select--multi {
      .Select-input, .Select-placeholder {
        font-size: 15px;
        line-height: 30px;
        height: 32px;
      }

      .Select-value {
        border-radius: 18px;
        height: 32px;
        padding: 3px 12px 3px 7px;

        .Select-value-icon {
          font-size: 22px;
          line-height: 24px;
        }

        .Select-value-label {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }

  &__form-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $color_content_title;
  }

  &__form-text {
    font-size: 16px;
    line-height: 22px;
    color: $color_content_text;
    margin-top: 5px;
  }

  &__form-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .34);
    z-index: 10998;
  }

  &__form-buttons {
    margin-top: 15px;
    text-align: right;

    .cancel-button {
      font-weight: normal;
    }

    .save-button {
      margin-left: 10px;
      background: $color_active;
      color: #FFF;
      min-width: 130px;
      border: 1px solid $color_active;
    }
  }
}

.ReactModal__Content--after-open {
  border: 0 !important;
  border-radius: 0 !important;
}

.ReactModalPortal {
  position: fixed;
}
