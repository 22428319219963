@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.no-data {
  color: lighten(#000, 20%);
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  margin: 80px 0;

  .no-data-align {
    position: relative;
    width: 520px;
    padding-left: 90px;
    margin: 0 auto;

    @media (max-width: $mq-tablet-portrait) {
      padding-left: 0;
      padding-top: 90px;
      max-width: 520px;
      width: 100%;
      text-align: center;
    }
  }

  svg {
    width: 76px;
    height: 76px;
    @include position(absolute, 50% none none 0);
    margin-top: -38px;

    @media (max-width: $mq-tablet-portrait) {
      @include position(absolute, 0 none none 50%);
      margin-top: 0;
      margin-left: -38px;
    }
  }

  .no-data-title {
    color: #000;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
    margin-top: 2px;
  }
}
