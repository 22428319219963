@import '../../../containers/colors';
@import '../../../containers/mixin';

.squad-list {
  width: 100%;
  margin: 0 0 50px 0;
  color: $black_blue_dark;

  td {

  }

  thead {
    tr {
      td {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 16px;
        border-bottom: 1px solid $squad_thead_border;

        a {
          display: block;
          padding: 8px 13px;
          cursor: pointer;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            display: inline-block;
            position: relative;
          }

          &:hover {
            color: $squad_link_hover;
          }

          &.active {
            font-weight: 500;

            span:before {
              @include position(absolute, 50% none none 100%);
              margin-top: -2px;
              margin-left: 6px;
              content: '';
              border: 0 solid transparent;
              border-left-width: 3px;
              border-right-width: 3px;
            }

            &.asc span:before {
              border-bottom: 4px solid;
            }

            &.desc span:before {
              border-top: 4px solid;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 12px 13px;
        line-height: 18px;
        font-size: 14px;

        &.age {
          width: 132px;
        }

        &.not_defined {
          color: darken($grey_dark, 20%);
        }

        &.text-right {
          text-align: right;
        }

        a {
          color: $squad_link_hover;

          &:hover {
            color: $black_blue_dark;
          }

          &.player-link {
            display: inline-block;
            position: relative;
            padding-left: 38px;

            .player-image {
              @include position(absolute, 50% none none 0);
              width: 26px;
              height: 34px;
              margin-top: -17px;

              svg {
                width: 16px;
                height: 16px;
                position: absolute;
                right: -5px;
                bottom: -5px;
                fill: $red;
              }
            }

            .pic {
              @include position(absolute, 50% none none 0);
              width: 26px;
              height: 34px;
              margin-top: -17px;
            }
          }
        }

        .player-nation {
          display: inline-block;

          .pic {
            position: relative;
            width: 20px;
            vertical-align: middle;
            display: inline-block;
          }
        }
      }

      &:nth-child(odd) {
        td {
          background-color: $squad_tr_color;
        }
      }
    }
  }
}
