@import '../containers/mixin';

/*
 * Icons
 */

@font-face {
  font-family: 'scout';
  src: url('./assets/icon/scout.ttf?hnqrqn') format('truetype'),
  url('./assets/icon/scout.woff?hnqrqn') format('woff'),
  url('./assets/icon/scout.svg?hnqrqn#scout') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'scout' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 100%;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before, &:after {
    font-family: 'scout' !important;
  }
}


.icon-lasso:before {
  content: "\e900";
}

.icon-minus:before {
  content: "\e901";
}

.icon-plus:before {
  content: "\e902";
}

.icon-more:before {
  content: "\e903";
}

.icon-against:before {
  content: "\e904";
  font-size: 21px;
}

.icon-check:before {
  content: "\e905";
}

.icon-selection-part:before {
  content: "\e907";
}

.icon-close:before {
  content: "\e906";
}

.icon-collapse:before {
  content: "\e909";
}

.icon-compare:before {
  content: "\e908";
  font-size: 17px;
}

.icon-dropdown:before {
  content: "\e907";
}

.icon-hide:before {
  content: "\e90a";
  font-size: 12px;
}

.icon-on:before {
  content: "\e90b";
  font-size: 22px;
}

.icon-pass:before {
  content: "\e90c";
}

.icon-player:before {
  content: "\e90d";
}

.icon-profile:before {
  content: "\e90e";
}

.icon-rating:before {
  content: "\e90f";
  font-size: 21px;
}

.icon-search:before {
  content: "\e910";
  font-size: 17px;
}

.icon-edit:before {
  content: "\e912";
}

.icon-download:before {
  content: "\e911";
}

.icon-pdf {
  svg {
    width: 22px;
    height: 22px;
  }

  .header & {
    color: white;
    display: inline-block;
    padding: 15px 18px 12px;
  }
}

.icon-favorite {
  fill: transparent;
  stroke: #fff;

  &.active {
    fill: #fff;

    &.category-0 {
      fill: #407B9C;
      stroke: #407B9C;
    }

    &.category-1 {
      fill: #e6e201;
      stroke: #e6e201;
    }

    &.category-2 {
      fill: #e6b901;
      stroke: #e6b901;
    }

    &.category-3 {
      fill: #e03e3e;
      stroke: #e03e3e;
    }
  }
}

// should be somewhere else
.icon-close, .icon-compare, .header .icon-search, .icon-more {
  color: white;
  font-size: 20px;
  padding: 15px 20px;
  display: inline-block;
  vertical-align: top;
}

.header {
  .help-placeholder {
    font-size: 20px;
    padding: 15px 20px;
    display: inline-block;
    vertical-align: top;
    color: white;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 20px;
      height: 20px;
      display: block;
      stroke: #FFF;
    }

    &:hover {
      svg {
        stroke: #35495C;
      }
    }
  }

  .divider {
    font-size: 20px;
    height: 20px;
    margin: 15px 10px 0;
    vertical-align: top;
    width: 1px;
    background: #FFF;
    opacity: .4;
    display: inline-block;
  }
}


.epi_images {
  width: 100px;
  height: 77px;
  background-image: url('./assets/epi_images.jpg');
}

@media (-webkit-min-device-pixel-ratio: 1.5),
(-o-min-device-pixel-ratio: 3/2),
(min--moz-device-pixel-ratio: 1.5),
(min-resolution: 1.5dppx),
(min-device-pixel-ratio: 1.5) {
  .epi_images {
    background-image: url('./assets/epi_images@2x.jpg');
    background-size: 400px 400px
  }
}

.epi_images-save {
  background-position: 0 0;
}

.epi_images-scoring_effort {
  background-position: -200px 0px
}

.epi_images-scoring {
  background-position: -100px 0px
}

.epi_images-stability {
  background-position: -300px 0px
}

.epi_images-tackle_defence {
  background-position: 0px -77px
}

.epi_images-tackle_offence {
  background-position: 0px -155px
}

.epi_images-take_on {
  background-position: 0px -232px
}

.epi_images-aerial_defence {
  background-position: 0px -310px
}

.epi_images-aerial_offence {
  background-position: -100px -77px
}

.epi_images-crossing {

  background-position: -200px -77px
}

.epi_images-intercept_defence {
  background-position: -300px -77px
}

.epi_images-blocked {
  background-position: -100px -155px
}

.epi_images-intercept_offence {
  background-position: -100px -232px
}

.epi_images-delivery {
  background-position: -100px -310px
}

.epi_images-pass_long {
  background-position: -200px -155px
}

.epi_images-pass_short {
  background-position: -300px -155px
}

.epi_images-defence {
  background-position: -200px -232px
}

.epi_images-offence {
  background-position: -200px -310px
}

.epi_images-reception {
  background-position: -300px -232px
}

.epi_images-season_rating {
  background-position: -300px -310px
}
