@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.similar {
  .data-text {
    color: $light_headline_primary;
  }

  .data-desc {
    color: $light_subheadline_primary;
  }

  .indicators_group + .indicators_group {
    margin-top: 5px;
  }

  & + .similar {
    margin-top: 15px;
  }
}
