@import '../../containers/colors';
@import '../../containers/mixin';

.account {

  .word_highlight {
    background: #01d39f;
  }

  .account-left-col {
    padding: 35px 5% 35px 0;
    border-right: 1px solid #DCDFE3;
    float: left;
  }

  .account-right-col {
    padding: 35px 0 35px 5%;
    float: left;
  }

  .account-change-password {
    width: 230px;

    .title {
      line-height: 34px;
      font-size: 29px;
      margin-top: -7px;
      font-weight: 200;
    }

    .password-form {
      margin-top: 13px;

      input {
        border: 1px solid transparent;
        border-bottom-color: #A7A7A7;
        color: #000;
        line-height: 18px;
        padding: 11px 1px;
        font-size: 15px;
        width: 100%;
        margin-top: 28px;

        &::-webkit-input-placeholder {
          color: #78919e;
          transition: opacity .2s;
          opacity: 1;
        }

        &::-moz-placeholder {
          color: #78919e;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #78919e;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-moz-placeholder {
          color: #78919e;
          transition: opacity .2s;
          opacity: 1;
        }

        &:focus {
          border-bottom-color: $primary-color;

          &::-webkit-input-placeholder {
            opacity: 0;
          }

          &::-moz-placeholder {
            opacity: 0;
          }

          &:-ms-input-placeholder {
            opacity: 0;
          }

          &:-moz-placeholder {
            opacity: 0;
          }
        }
      }

      button {
        margin-top: 50px;
        display: inline-block;
        background: #53B78F;
        line-height: 45px;
        width: 148px;
        padding: 0;
        text-align: center;
        border-radius: 2px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;

        &:hover {
          color: #fff !important;
          background: $primary-color;
        }
      }

      .error-message,
      .success-message {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: -30px;
        color: $red;
        display: none;

        &.visible {
          display: block;
        }
      }

      .error-message {
        color: $red;
      }

      .success-message {
        color: $green;
      }
    }
  }

  .log-out-btn {
    display: inline-block;
    background: $blue_auth_container;
    line-height: 45px;
    width: 148px;
    padding: 0;
    text-align: center;
    border-radius: 2px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: #fff !important;
      background: $black_blue;
    }
  }
}
