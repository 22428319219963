@import '../../containers/colors';
@import '../../containers/mixin';

.account {
  .users-list {
    color: $blue;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    width: 100%;

    th, td {
      text-align: left;
      padding: 15px 10px;
      border-bottom: 1px solid $grey;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      &.controls-col {
        text-align: right;
        width: 150px;
      }
    }

    th {
      font-weight: 400;
      padding-top: 0;
    }

    tr.edit {
      td {
        background: #eceef0;
        border-bottom: none;
      }
    }

    tr.edit + tr {
      td {
        border-top: 1px solid $grey;
      }
    }

    tr.edit + tr.edit {
      td {
        border-top: none;
        border-bottom: 1px solid $grey;
        padding-top: 5px;
      }
    }

    .selected {
      .Dropdown-placeholder {
        color: $black_blue;
      }
    }

    .react-datepicker__input-container {
      display: block;
    }

    .align {
      display: block;
      margin-top: 1px;
      margin-bottom: -1px;
    }

    input {
      width: calc(100% + 10px);
      height: 30px;
      margin: -5px;
      padding: 4px;
      border: 1px solid $grey;
      background: #fff;
      color: $black_blue;

      &.invalid {
        color: $red;

        &::-webkit-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &::-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }
      }

      &::-webkit-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:focus {
        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }
      }
    }
  }

  .users-list-filter {
    position: absolute;
    z-index: 2;
    margin-top: 7px;
    width: 214px;
  }

  .users-list-search {
    position: absolute;
    margin-top: 7px;
    margin-left: 234px;
    z-index: 1;
    width: 214px;

    input {
      background-color: #fff;
      border: 1px solid #dfe3e6;
      color: #35495C;
      height: 36px;
      width: 100%;
      padding: 8px 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .create-container {
    margin: 0 0 25px;

    div.users-list {
      position: relative;
      text-align: right;
      padding: 15px 20px;
    }

    &__open {
      position: relative;
      z-index: 3;
    }
  }

  .blue-btn,
  .orange-btn,
  .green-btn,
  .red-btn {
    padding: 0;
    display: inline;
    font-weight: 400;
  }

  .blue-btn {
    color: $blue_neon;
    margin-right: 20px;
  }

  .orange-btn {
    color: $orange;
    margin-right: 20px;
  }

  .green-btn {
    color: $green;
  }

  .red-btn {
    color: $red;
    margin-left: 20px;
  }

  .Dropdown-root {
    position: relative;
    margin: -5px;
  }

  .Dropdown-placeholder {
    color: #8B8282;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid $grey;
    box-sizing: border-box;
    color: $black_blue;
    cursor: default;
    outline: none;
    padding: 5px 52px 3px 5px;
    transition: all 200ms ease;
    border-radius: 0;
  }

  .Dropdown-control:hover {
    box-shadow: none;
  }

  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -2.5px;
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu {
    background-color: white;
    border: 1px solid $grey;
    box-shadow: none;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    border-radius: 0;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 5px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    color: $black_blue;
    cursor: pointer;
    display: block;
    padding: 5px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .Dropdown-option:hover,
  .Dropdown-option.is-selected {
    background-color: rgba($grey, .4);
    color: $black_blue;
  }

  .Dropdown-option.is-selected {
    cursor: default;
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
}
