@import '../../../../containers/colors';

.data-progress {
  display: block;

  &.position_group {
    font-weight: bold;
  }

  .progress-bar {
    width: 75%;
    height: 5px;
    position: relative;
    display: block;
    background: $light_divider;
    border-radius: 3px;
    overflow: hidden;
    margin: 4px 0 2px;

    .progress-value {
      background: $primary-color;
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 3px 0 0 3px;
    }
  }
}
