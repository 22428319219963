@import '../../../containers/colors';
@import '../../../containers/mixin';

.competition-level-overview {
  @include position(fixed, 0);
  z-index: 250;
  background: #fff;
  display: flex;
  transform: translatey(100%);
  transition: transform .2s ease-out;

  .ReactModal__Content--after-open & {
    transform: translatey(0);
  }

  .competition-level-sidebar {
    width: 316px;
    background: $neongreen;
    padding: 108px 48px 48px;
    overflow: auto;

    form {
      .competition-filter-group + .competition-filter-group {
        margin-top: 90px;
      }

      fieldset + fieldset {
        margin-top: 32px;
      }

      .fieldset-name {
        margin-bottom: 16px;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
      }

      .Select {
        .Select-value {
          right: 50px;
        }

        .Select-value-label {
          padding: 0 !important;
          line-height: 35px !important;
        }

        .Select-input > input {
          font-size: 14px;
          padding: 0;
          line-height: 35px;
        }

        .Select-arrow-zone {
          .Select-arrow {
            position: relative;
            top: -2px;
          }
        }

        .Select-clear-zone {
          display: table-cell;
          color: #016e53;
          width: 25px;

          .Select-clear {
            font-size: 20px;
            line-height: 34px;
          }
        }
      }

      .fieldset-label {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: rgba(#ECEDED, .5);
        display: block;
        margin-bottom: 4px;
      }

      .slider {
        position: relative;

        .horizontal-slider {
          display: block;
          height: 26px;
        }

        .bar {
          background: rgba(#fff, .4);

          &.bar-1 {
            background: #fff;
          }
        }

        .handle {
          background: #fff;
          border: 1px solid $neongreen;
        }

        .slider-value {
          position: absolute;
          top: 1px;
          padding-top: 0;
          line-height: 24px;
          color: #fff;
          font-size: 14px;

          &:first-child {
            left: -44px;
          }

          &:last-child {
            right: -44px;
          }
        }
      }

      .switch {
        .react-switch-bg {
          border: 1px solid rgba(236, 237, 237, 0.5);
        }

        &.active {
          .react-switch-bg {
            background: rgba(236, 237, 237, 0.5);
          }
        }
      }
    }

    .sidebar-link {
      margin-top: 70px;
      font-size: 14px;
      line-height: 18px;

      .link-desc {
        color: #ECEDED;
        opacity: 0.6;
      }

      a {
        color: #fff;
      }
    }

    .difference-text {
      margin-top: 70px;
      color: #fff;
      font-size: 14px;
      line-height: 17px;

      b {
        font-weight: 500;
      }

      .pic {
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
      }
    }
  }

  .competition-level-content {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding: 48px 80px;
    position: relative;
  }

  .competition-level-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    color: #000;
  }

  .competition-level-chart-container {
    flex: auto;
    margin-top: 48px;
  }

  .overview-close {
    @include position(absolute, 48px 80px none none);
    padding: 5px;

    .icon-close {
      color: $neongreen;
      padding: 0;
    }
  }
}


.react-dropdown-tree-select {
  position: relative;

  .search {
    position: static;
    background: none transparent;
    border: 0 none;
    cursor: default;
    display: inline-block;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    font-size: 14px;
    line-height: 25px;
    color: #35495C;
    height: 25px;
    width: 80px;
    padding: 0 5px;

    &::placeholder {
      color: #35495C;
    }
  }

  ul, li {
    list-style: none;
  }

  .dropdown-content {
    background-color: #fff;
    border: 1px solid #dfe3e6;
    box-shadow: 0 0 6px rgba(#000, 0.3);
    border-radius: 4px;
    max-height: 340px;
    overflow: auto;
    @include position(absolute, 100% 0 none);
    z-index: 5;

    .no-matches {
      display: block;
      font-size: 14px;
      line-height: 14px;
      padding: 12px 13px;
    }

    .node {
      position: relative;
      color: #113245;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      border-bottom: 1px solid transparent;

      &:hover,
      &.checked {
        background-color: rgba(1, 186, 140, 0.1) !important;
        color: #333;
      }

      &.tree {
        font-weight: 500;
      }

      input[type="checkbox"] {
        width: 0;
        height: 0;
        position: absolute;
        visibility: hidden;
        overflow: hidden;
      }

      label {
        display: block;
        padding: 12px 13px;
        cursor: pointer;
      }

      .toggle {
        @include position(absolute, 0 0 none none);
        width: 38px;
        height: 38px;
        display: inline-block;
        background: {
          image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='6px' viewBox='0 0 12 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 56 (81588) - https://sketch.com --%3E%3Ctitle%3EPath%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(-3.000000, -6.000000)' stroke='%2301BA8C'%3E%3Cpath d='M14.75,6.5 L9.39,11.3995524 C9.31975236,11.463863 9.22441599,11.5 9.125,11.5 C9.02558401,11.5 8.93024764,11.463863 8.86,11.3995524 L3.5,6.5' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          position: center center;
          repeat: no-repeat;
        }

        &.collapsed {

        }

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }

  .tag-list {
    line-height: 0;

    .tag-item {
      display: inline-block;
      margin: 5px 5px 0 0;
      vertical-align: top;
    }

    .tag-item + .tag-item {
      .search {
        margin-left: 4px;
      }
    }

    .tag {
      background: #fff;
      position: relative;
      border-radius: 2px;
      font-size: 13px;
      line-height: 13px;
      padding: 5px 5px 5px 31px;
      max-width: 205px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      background: rgba(0, 126, 255, 0.08);
      border: 1px solid rgba(0, 126, 255, 0.24);
      display: block;

      .tag-remove {
        width: 25px;
        height: 25px;
        @include position(absolute, 0 none 0 0);
        line-height: 25px;
        border-right: 1px solid rgba(0, 126, 255, 0.24);
        padding: 0;
        text-align: center;
        color: #016e53;
        font-size: 0;

        &:after {
          content: '×';
          font-size: 20px;
        }

        &:hover {
          background: #ddeff7;
        }
      }
    }
  }

  .dropdown {
    max-height: 220px;
    overflow: auto;
  }

  .dropdown-trigger {
    display: block;
    position: relative;
    background: #fff;
    padding: 0 20px 5px 5px;

    &:after {
      @include position(absolute, none 11px 14px none);
      content: '';
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-style: solid;
      border-width: 5px 5px 0;
      display: inline-block;
      height: 0;
      width: 0;
    }

    &.top {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}
