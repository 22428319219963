@import '../../../containers/colors';
@import '../../../containers/mixin';

.green_block {
  background: rgba(#008865, .40);
  padding: 2px;
  color: black;
}

.details .analyse {
  margin: 30px 0 150px 0;
  clear: both;

  .group {
    position: relative;

    & + .group {
      margin-top: 66px;
      padding-top: 66px;
      border-top: 1px solid rgba(#979797, .17);
    }

    &:first-child {
      .group-header {
        .line {
          &:before {
            top: 42px;
          }
        }
      }
    }
  }

  .group-header {
    font-size: 16px;
    text-transform: uppercase;
    color: $blue_lightest;

    .col-header-name {
      padding-left: 5px;
    }

    .middle-line {
      height: 1px;
    }

    .margin {
      max-width: 75%;
    }

    .line {
      margin-left: 50%;

      &:before {
        content: '';
        @include position(absolute, 106px none 0 none);
        border-left: 1px dotted rgba(#4F4F4F, .35);
      }
    }
  }

  .group-body {
    position: relative;
    z-index: 1;

    .col-3 {
      position: relative;
    }

    .group-body-header {
      @include position(absolute, 0 25% none 0);

      .indicator-title {
        @include position(absolute, 0 6px none none);
        font-size: 14px;

        & + .indicator-title {
          right: 50px;
        }
      }
    }
  }

  .group-body-header + .group-position {
    margin-top: 23px;
  }

  .group-position {
    position: relative;
    margin-top: 15px;

    .row {
      margin-top: 2px;
    }

    .highlight {
      .name, .indicator-value, .indicator-index {
        color: white !important;
      }

      font-weight: bold;

      .indicator:before {
        content: "";
        background: rgba($dark_background_primary, .5);
        display: block;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
      }
    }

    .position-name {
      @include position(absolute, none none none -60px);
      font-size: 14px;
      font-weight: 300;
      color: $blue_lightest;
    }

    // & > *:first-child {
    // 	.indicator-index {
    // 		display: inline-block;
    // 	}
    // }
  }

  .indicator {
    position: relative;
    line-height: 19px;
    max-width: 75%;
    height: 19px;
    z-index: 1;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    .indicator-index {
      @include position(absolute, 0 none 0 3px);
      display: inline-block;
      font-weight: 200;
      width: 15px;
      text-align: right;
    }

    .indicator-percent {
      @include position(absolute, 0 none 0 0);
      opacity: .4;
      @include border-radius(2px 2px 0px 0px);
    }

    .indicator-value {
      @include position(absolute, 0 6px none none);
      font-size: 14px;
      color: #56626B;

      & + .indicator-value {
        right: 50px;
      }
    }

    .name {
      position: relative;
      color: #000;
      font-size: 14px;
      padding-left: 25px;

      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 40px;

      // &.dark { color: #000 !important; }
      .pic {
        position: relative;
        top: 2px;
        left: 0px;
        width: 17px;
        height: 21px;
        display: inline-block;
        vertical-align: top;
        margin-right: 2px;
      }
    }

    .gray {
      color: darken($grey_dark, 20%);
    }

    &.red {
      border-bottom: 1px solid $red;

      .indicator-percent {
        background: rgba($red, .40);
      }
    }

    &.green {
      border-bottom: 1px solid rgba($green, .8);

      .indicator-percent {
        background: rgba($green, .40);
      }
    }

    &.gray {
      border-bottom: 1px solid $grey_dark;

      .indicator-percent {
        background: $grey_dark;
      }
    }

    &.blue {
      border-bottom: 1px solid $team_home;

      .indicator-percent {
        background: rgba($team_home, 0.7);
      }
    }

    &.yellow {
      border-bottom: 1px solid $team_away;

      .indicator-percent {
        background: rgba($team_away, 0.7);
      }
    }
  }

  &.league {
    .indicator {
      line-height: 24px;
      height: 24px;
    }

    .highlight .indicator.red .indicator-percent {
      background: rgba($red, 1);
    }

    .highlight .indicator.green .indicator-percent {
      background: rgba($green, 1);
    }

    .middle-line .line:before {
      top: 48px !important;
    }
  }

  &.match,
  &.squad {
    padding-left: 60px;

    .indicator {
      margin-top: 1px;

      .name {
        padding-left: 5px;
      }
    }
  }
}

.analyse-slider {
  width: 20%;
  padding: 0 20px;
  margin: 25px 50px 20px 0px;
}
