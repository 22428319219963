@import '../../containers/colors';
@import '../../containers/mixin';

.page-loader {
  @include position(fixed, 0 -99999px 0 99999px);
  z-index: 255;
  transition: left 0s linear .25s, right 0s linear .25s, opacity .25s linear 0s;
  opacity: 0;
  pointer-events: none;
  background-color: rgba($blue, .8);

  &.loading {
    @include position(fixed, 0);
    opacity: 1;
    pointer-events: auto;
    transition: left 0s linear 0s, right 0s linear 0s, opacity 0s linear 0s;
  }

  .loader {
    background-color: $blue;
    border-color: $blue;
    opacity: .9;
  }
}
