@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.color-picker {
  position: relative;

  button {
    padding: 7px 14px;
    width: 140px;
    text-align: center;
    border: 1px solid $color_active;
  }

  &__container {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;

    .icon-close {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      right: -32px;
      top: 0;
      color: black;
      padding: 0;
      cursor: pointer;
    }

    button {
      display: block;
      width: 100%;
      background: $color_active;
      color: #FFF;

      &:hover {
        color: #FFF !important;
      }
    }
  }
}
