@import '../../containers/colors';
@import '../../containers/mixin';

.info {
  background-color: $light_background_primary;
  position: relative;
  z-index: 1;
  will-change: transform, opacity;
  @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  opacity: 1;
  @include transform(scale(1));

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .header-container {
    background: $primary-color;
    color: #fff;
    // position: fixed;
    height: 60px;
    left: 0;
    right: 0;

    .info-logo {
      padding: 17px 0;

      svg {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        display: inline-block;
        vertical-align: middle;
        fill: none;
        stroke: currentColor;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        margin: 0 0px -2px 0px;
      }
    }

    .pull-right {
      float: right;
    }

    .btn {
      padding: 2px 0;
    }
  }

  .nav-container {
    height: 43px;
    background: $dark_background_primary;

    a {
      padding: 13px 10px 8px;

      &.active {
        color: $primary-color;
        font-weight: 500;
        border-bottom: 3px solid $primary-color;
        padding: 13px 10px 7px;
      }

      &:hover {
        color: $primary-color;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;
    width: 90%;
  }

  .content-container {
    .container {
      padding: 40px 0;
      font-size: 13px;

    }
  }

  h1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 300;
  }

  h3 {
    font-weight: 500;
    font-size: 15px;
    margin: 3em 0 0;
    text-transform: none;
    color: inherit;
  }

  h4 {
    font-weight: 500;
    font-size: 14px;
    margin: 3em 0 0 0;
  }

  h5 {
    font-weight: 300;
    font-size: 13px;
    font-style: italic;
    margin: .5em 0 0 0;
  }

  p {
    margin: 1em 0;
    font-size: 14px;
    width: 68%;
    line-height: 150%;
  }

  .bookmarks {
    font-size: 14px;
    margin: 1.5em 0;
    border-bottom: 1px solid $light_divider;

    &, ul {
      list-style: none;
      padding: 0;
    }

    ul {
      margin: 0 0 0 1em;
    }

    li {
      margin-bottom: 1em;
    }

    a {
      color: $squad_link_hover;
      margin-bottom: 1em;
      display: block;
    }
  }

  .privacy-page {
    a {
      color: $squad_link_hover;
    }
  }
}
