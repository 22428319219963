@import '../../../containers/colors';
@import '../../../containers/mixin';

.account {

  .groups {
    .users-list-search {
      display: block;
      position: relative;
      margin: 0 0 20px;
    }
  }


  .groups-list {
    color: $blue;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    width: 100%;

    tbody {
      td {
        border-top: 1px solid $grey;
      }
    }

    th, td {
      text-align: left;
      padding: 15px 10px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
        min-width: 122px;
      }

      &.controls-col {
        text-align: right;
        width: 150px;
      }
    }

    th {
      font-weight: 400;
      padding-top: 0;
    }

    tbody.edit {
      td {
        background: rgba($grey, .4) !important;
        border-bottom: none;
      }
    }

    .align {
      display: block;
      margin-top: 1px;
      margin-bottom: -1px;
    }

    input[type="text"] {
      width: calc(100% + 10px);
      height: 30px;
      margin: -5px;
      padding: 4px;
      border: 1px solid $grey;
      background: #fff;
      color: $black_blue;

      &.invalid {
        color: $red;

        &::-webkit-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &::-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }
      }

      &::-webkit-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:focus {
        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }
      }
    }

    .checkbox {
      display: block;
      position: relative;
      padding-left: 17px;
      margin-top: 10px;

      input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 3px;
        width: 12px;
        height: 12px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .toggle-container {
      text-align: right;
      margin-top: 15px;
    }
  }

  .create-container {
    margin: 0 0 25px;

    div.users-list {
      text-align: right;
      padding: 15px 20px;
    }
  }

  .green-btn {
    color: $green;
    font-weight: 400;
  }

  .red-btn {
    color: $red;
    margin-left: 20px;
    font-weight: 400;
  }

  .group-options-container {
    padding: 0 !important;
  }

  .group-options {
    position: relative;
    height: 80vh;

    .word_highlight {
      background: #01d39f;
    }

    .group-countries {
      @include position(absolute, 0 none 0 0);
      width: 300px;
      border-right: 1px solid $grey;

      .group-countries-filter {
        padding: 10px 20px;
        position: relative;

        input[type="checkbox"] {
          @include position(absolute, 20px none none 20px);
          cursor: default;
        }

        input[type="text"] {
          margin: 0;
          width: 100%;
        }
      }

      .group-countries-list {
        overflow: auto;
        @include position(absolute, 50px 0 0);
      }

      .country-option {
        padding: 5px 20px 5px 40px;
        margin: 0;
        cursor: pointer;

        input[type="checkbox"] {
          left: 20px;
          top: 8px;
          cursor: default;
        }

        &:hover, &.active {
          background: rgba(#000, .1);
        }

        &.active {
          color: $green;
          font-weight: 400;
        }
      }
    }

    .group-leagues {
      @include position(absolute, 0 0 0 300px);

      .group-leagues-filter {
        padding: 10px 20px;

        input[type="text"] {
          margin: 0;
          width: 100%;
        }
      }

      .group-leagues-list {
        overflow: auto;
        @include position(absolute, 50px 0 0);
        padding: 5px 20px 20px;
      }

      .format-group + .format-group {
        margin-top: 20px;
      }

      .league-format {
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }

  .team-color {
    display: block;
    width: 48px;
    height: 16px;
  }

  .completness {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 13px;
    font-size: 10px;
    line-height: 13px;
    font-weight: bold;
    border: 1px solid #CCC;
    border-radius: 2px;
    padding: 0 3px;
  }

  .completness-progress {
    height: 11px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
  }

  .completness-value {
    position: absolute;
    z-index: 2;
  }
}
