/*
 * Main style file loading for all pages
 */

//import other stlye definitions
@import 'sanitize';
@import 'icons';
@import 'grid';
@import 'grid-new';
@import 'colors';
@import 'mixin';


/* ---------------------------------------
 * Basic Html elements
 -----------------------------------------*/

html, body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: auto;
  -webkit-user-select: none;
  -webkit-touch-callout: none; /*only to disable context menu on long press*/
  background-color: $dark_background_primary;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: hidden;*/
}

html {
  overflow: hidden;
  // overflow: auto;
  -webkit-text-size-adjust: 100%;
}

body {
  // overflow: auto;
  font-family: 'Fira Sans', Times, Helvetica, sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

*, *:before, *:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

// used for headlines detail
.h1, h1 {
  font-size: 15px;
  font-weight: 400;

  &.line {
    margin-bottom: 10px;
    color: $anthrazit; //$blue_lightest;
    &:after {
      border-bottom: 2px solid $anthrazit;
      opacity: .2;
      content: " ";
      width: 2%; //25px
      display: block;
      padding-bottom: 3px;
    }
  }
}

// used for overview
h2, .h2 {
  font-size: 13px;
  line-height: 20px;
  font-weight: normal;
  // letter-spacing: 0.1em;
  &.large {
    font-weight: 500;
    font-size: 14px;
  }
}

//used for performance
h3 {
  font-size: 13px;
  font-weight: 500;
  color: $grey_darkness_ever;
  letter-spacing: .3px;
  /*opacity: .8;*/
  text-transform: uppercase;
  margin-bottom: 8px;
  line-height: 125%;
}

// used for summary epi branding titles
h5 {
  font-size: 16px;
  line-height: 19px;
  color: $epi_color;
  font-weight: 400;
  padding: 7px 5px;
  margin: 0px 20px 25px 0px;
  border-bottom: 1px solid $epi_color;

  [class^="icon-"], [class*=" icon-"] {
    display: inline;
    vertical-align: text-bottom;
  }
}

// used for header with line
.h4 {
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
  letter-spacing: -.1px;
  color: $black_light;
  opacity: .9;

  &.background {
    position: relative;
    z-index: 1;

    &:before {
      border-top: 1px solid $light_divider;
      content: "";
      margin: 0 auto; /* this centers the line to the full width specified */
      position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 11px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: -1;
    }

    span {
      /* to hide the lines from behind the text, you have to set the background color the same as the container */
      background: $light_background_primary;
      padding: 0 15px 0 0;
    }
  }
}

input {
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &:hover, &:focus, &:active {
    outline: none;
    border: none;
  }
}

// prevent ios shadow
input[type=text],
input[type=email],
input[type=password],
textarea {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

a [class^="icon-"], a [class*=" icon-"] {
  color: $dark_link_text;

  &:hover {
    color: $dark_background_primary;
  }
}

.btn, button {
  padding: 10px;
  text-align: left;
  display: block;

  &:hover {
    outline: none;
    cursor: pointer;
    color: $primary-color;
  }

  [class^="icon-"]:hover {
    color: $dark_background_primary;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.center {
  margin: 0 auto;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hidden-mobile {
  @media (max-width: $mq-tablet-portrait - 1) {
    display: none;
  }
}

/* ---------------------------------------
 * General
 -----------------------------------------*/

.callAction {
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  padding: 15px 10px;
  display: block;
  letter-spacing: 0.1em;
  text-align: center;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    outline: none;
  }
}

.trackduck-anonymous {
  @include transform(translate3d(10px, 10px, 0));
  @include transition(transform .2s linear);

  @media (max-width: $mq-tablet-portrait) {
    display: none !important;
  }

  .overview & {
    @media (min-width: $mq-tablet-portrait + 1) {
      @include transform(translate3d(10px, -52px, 0));
      bottom: 10px !important;
    }
  }
}

.epi_branding {
  color: $epi_color;
}

.details .content-container,
.compare .content-container,
.compare .header-container .container,
.search .content-container,
.search .header-container .container {
  margin: 0 10px;
  padding: 1px 0;

  @media (min-width: $mq-tablet-portrait + 1) {
    margin: 0 auto;
    width: 95%;
  }
}

.header-container, .header-container + .nav-container, .nav-container + .nav-container-white {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  position: relative;
}


.content_group {
  margin: 0px 0px 50px 0px;
  position: relative;
  clear: both;

  &:last-child {
    margin: 0px 0px 100px 0px;
  }

  &.performance_ownstats {
    margin-bottom: 0px;

    h1 {
      margin-bottom: 0px;
    }
  }

  &.performance_ownstats .bar_chart_group {
    margin-top: 20px;

    h1 {
      margin-bottom: 10px;
    }
  }

  &.performance_optastats {
    margin: 30px -5% 0px -5%;
    padding: 20px 5% 7% 5%;
    background-color: darken($light_background_primary, 2%);
  }

  &.summary_data .radar {
    margin-top: -15px;
  }

  &.summary_data, &.performance_ownstats, &.history_overtime {

    .chart_group h3 span:hover {
      color: $anthrazit;
    }

    .h4 {
      color: $epi_color;
      font-weight: 500;
      margin-bottom: 20px;

      svg {
        vertical-align: bottom;
        padding-right: 7px;
        background: $light_background_primary;
      }

      .description {
        color: $anthrazit;
        font-weight: 400;
      }

      @media (max-width: $mq-tablet-portrait) {
        &.background:before {
          border: none;
        }
      }
    }
  }

  .summary-text {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 20px;
    margin-top: -15px;
    margin-left: 5px;
    color: $anthrazit;

    .weaknesses {
      color: $red_polar;
    }

    .strengths {
      color: $green_polar;
    }

    svg {
      margin-top: -3px;
      margin-right: 2px;
    }

    .data_point {
      font-weight: 500;

      &.weaknesses, &.strengths {
        text-transform: uppercase;
        letter-spacing: .3px;
        font-size: 14px;
      }

      &.weaknesses:hover {
        border-bottom: 1px solid $red_polar;
      }

      &.strengths:hover {
        border-bottom: 1px solid $green_polar;
      }
    }

    @media (max-width: $mq-tablet-portrait) {
      font-size: 16px;
      line-height: 22px;
      margin-left: 0px;
      margin-bottom: 35px;
      .row {
        margin-bottom: 15px;
      }
      .data_point {
        font-weight: 500;

        &.weaknesses, &.strengths {
          text-transform: uppercase;
          letter-spacing: .3px;
          font-size: 16px;
        }
      }
    }
  }
}


// wappen and competition
.keydata .pic,
.tooltip .tooltip__content_name .pic,
.tooltip .tooltip__content_stats .pic,
.compare .compare_content_cards .team .pic,
.search-link .pic,
.details .bar-container .header .header__profile_key .header__profile_key_name .pic,
.compare .compare_content_cards .league .pic,
.filterView .ReactVirtualized__Grid .levelThird .pic,
.dataView .ReactVirtualized__Table .pic,
.header__actionButtons_container.league .pic {
  height: auto;
  width: 18px;
  display: inline-block;
  position: relative;
  margin-bottom: -6px;
}

.tooltip .tooltip__content_name .pic,
.filterView .ReactVirtualized__Grid .levelThird .pic,
.dataView .ReactVirtualized__Table .pic {
  width: 13px;
  margin-bottom: -4px;
  margin-right: 3px;
}

.details .bar-container .header .header__profile_key .header__profile_key_name .pic,
.compare .compare_content_cards .team .pic, .compare .compare_content_cards .place .pic {
  margin-bottom: -8px;
  margin-right: 2px;
}

// generall datavis
.chart_group {
  position: relative;
}

.bar_chart_group.row {
  margin: 30px 0 0;
}


/* ---------------------------------------
 * Charts
 -----------------------------------------*/

.BarChart {
  border-bottom: 1px solid darken($light_divider, 10%);

  .axis {
    display: none;
  }

  .BarChart__bar_bardesc_1 {
    font-size: 13px;
    font-weight: 500;
  }

  .Barchart__bar_bardesc_mean, .Barchart__bar_bardesc_benchmark, .BarChart__bar_bardesc_2 {
    font-size: 11px;
    font-weight: 400;
  }

  .BarChart .rd3-chart > g > g:nth-child(3) rect {
    width: 30px;
  }

  .BarChart__bar_rect_negative, text.negative {
    //fill: $red;
  }

  .BarChart__bar_rect_positive, text.positive {
    //fill: $green;
  }

  .BarChart__bar_rect_item_0 {
    fill: $light_chart_primary;
  }

  .BarChart__bar_rect_item_1 {
    fill: $light_chart_secondary;
  }

  .BarChart__bar_rect_item_2 {
    fill: $light_chart_tertiary;
  }

  .BarChart__bar_rect_item_3 {
    fill: $light_chart_fourth;
  }

  .BarChart__bar_rect_mean {
    //fill: $light_mean;
  }

  text.mean {
    fill: $light_chart_mean;
  }

  .BarChart__bar_rect_benchmark, text.benchmark {
    fill: $light_benchmark;
  }
}

.Radarchart, .Polarchart {

  .RadarChart__radar {
    fill-opacity: .1;
  }

  .RadarChart__radar_item_0 {
    fill: $light_chart_primary;
    stroke: $light_chart_primary;
  }

  .RadarChart__radar_item_1 {
    fill: $light_chart_secondary;
    stroke: $light_chart_secondary;
  }

  .RadarChart__radar_item_2 {
    fill: $light_chart_tertiary;
    stroke: $light_chart_tertiary;
  }

  .RadarChart__radar_item_3 {
    fill: $light_chart_fourth;
    stroke: $light_chart_fourth;
  }

  .RadarChart__radar_item_mean {
    fill: $light_chart_mean;
    stroke: darken($light_mean, 5%);
  }

  .RadarChart__radar_item_0, .RadarChart__radar_item_1, .RadarChart__radar_item_2, .RadarChart__radar_item_3 {

    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: .3s;
    opacity: 1;

    &.faded {
      opacity: .3;
    }
  }


  .axis path {
    stroke: darken($light_divider, 0%);
  }

  .axis_name {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .1px;
    text-transform: uppercase;
    margin-bottom: 7px;
    line-height: 125%;
    fill: $grey_darkness_ever;
    transition: fill 500ms;

    &:hover {
      fill: $anthrazit;
    }
  }

  .axis_value {
    font-size: 12px;
    fill: darken($light_divider, 20%);
  }

  .axis_value_bg {
    stroke: none;
    fill: $light_background_primary;
  }
}

.Polarchart {
  margin: 0 auto;

  &_label {
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    color: $light_chart_mean;
    text-align: center;
    text-anchor: middle;
  }

  text, .axis text {
    font-family: 'Fira Sans', Times, Helvetica, sans-serif;
  }

  .segment {
    stroke: #eaecef;
  }

  &__pie-label {
    font-size: 13px;
    line-height: 18px;
    text-anchor: middle;
    fill: $grey_darkness_ever;
    opacity: .9;
  }

  &__pie-label__bg {
    fill: $light_background_primary;
  }

  &__axis-border {
    stroke-width: 2px;
    stroke: #dfe3e6
  }

  &__polar {
    fill-opacity: .6;

    &.Polarchart__polar_mean_value {
      fill-opacity: 1;
    }
  }

  &__polar-border {
    fill-opacity: .9;

    &.Polarchart__polar_mean_value {
      fill-opacity: 1;
    }
  }

  &__polar_segment {
    pointer-events: none;
    fill: transparent;
    stroke: #eaecef;
  }

  &__axis-tick {
    stroke: #eaecef;
  }

  &__item_0 {
    fill: $light_chart_primary;
    stroke: $light_chart_primary;
  }

  &__item_1 {
    fill: $light_chart_secondary;
    stroke: $light_chart_secondary;
  }

  &__item_2 {
    fill: $light_chart_tertiary;
    stroke: $light_chart_tertiary;
  }

  &__item_3 {
    fill: $light_chart_fourth;
    stroke: $light_chart_fourth;
  }

  &__item_0, &__item_1, &__item_2, &__item_3 {

    transition-property: opacity;
    transition-timing-function: ease-out;
    transition-duration: .3s;
    opacity: 1;

    &.faded {
      opacity: .2;
    }
  }
}


.ColorScale {

  bottom: auto;
  position: relative;
  margin: 0 auto 35px;

  bottom: 13px;
  right: 65px;

  text {
    font-size: 11px;
    line-height: 16px;
    color: #373737;
    font-weight: 200;
    font-family: 'Fira Sans', sans-serif;
  }

  line {
    stroke: #A8A8A8;
    opacity: .5;
  }

  @media (min-width: $mq-tablet-portrait + 1) {
    position: absolute;
    // right:0;
    bottom: -33px;
    // bottom: 13px;
    right: 65px;
    margin: 0;
  }
}

.chart_bar_value_name, .chart_bar_value_benchmark {
  font-size: 11px;
  text-transform: uppercase;
  float: left;
  margin-top: 3px;
  color: $anthrazit;

  &.negative {
    color: $red;
  }

  &.positive {
    color: $green;
  }
}

.chart_bar_value_name {
  padding-left: 1px;
}

.chart_bar_value_benchmark {
  color: $light_benchmark;
  position: absolute;
  text-align: right;
  padding-right: 1px;

  a, a:visited {
    color: $light_benchmark;
  }
}

.linechart, .history .scatterplot {
  .LineChart__line {
    fill: none;

    &.LineChart__line_rating {
      stroke: $light_chart_primary;
    }

    &.LineChart__line_rating_mean {
      stroke: lighten($light_mean, 5%);
    }

    &.LineChart__line_defence {
      stroke: $light_chart_secondary;
    }

    &.LineChart__line_offence {
      stroke: $light_chart_tertiary;
    }


  }

  .LineChart__text {
    font-family: inherit;
    font-size: 12px;
    // fill: darken($light_divider, 30%);
    &.LineChart__text_rating {
      fill: $light_chart_primary;
    }

    &.LineChart__text_rating_mean {
      fill: lighten($light_chart_mean, 15%);
    }

    &.LineChart__text_defence {
      fill: $light_chart_secondary;
    }

    &.LineChart__text_offence {
      fill: $light_chart_tertiary;
    }
  }

  .axis path {
    // stroke: darken($light_divider, 0%);
    display: none;
  }

  .axis text {
    font-family: 'Fira Sans', Times, Helvetica, sans-serif;
    font-size: 13px;
    fill: darken($light_divider, 40%);
  }

  .axis.x_axis .tick line {
    stroke: lighten($light_divider, 1%);
  }

  // .axis.x_axis .tick:nth-child(2) {
  //   text { fill: none; }
  // }

}


/* ---------------------------------------
 * Loading icons and animations
 -----------------------------------------*/

.loader-small {
  @include position(fixed, 0);
  z-index: 10001;
  @include transition(translateY(100%));

  .loader-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10001;

    .loader {
      background: white;
      border: 10px solid white;
    }
  }

  .loader-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $light_background_primary;
    opacity: .7;
  }

  &.on {
    @include transition(translateY(0%));
  }
}

.app .loader-small {
  .loader-bg {
    background-color: $dark_background_primary;
    opacity: .8;
  }

  .loader-container .loader {
    background-color: $dark_background_primary;
    border-color: $dark_background_primary;
    opacity: .9;
  }

  .loader-message {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    top: 50%;
    margin-top: 100px;
    color: #FFF;
  }
}


.error {
  background-color: $light_background_primary;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 1000000;

  .error-container {
    position: relative;
    height: 100%;
    width: 100%;

    .error-centered {
      @include centerer;

      h1 {
        text-align: center;
        color: $black_blue;
        text-transform: uppercase;
        font-size: 17px;
      }
    }
  }
}

// .slideOut {@include transform( translate3D(0,0,0) ); }
// .slideIn { @include transform( translate3D(100%,0,0) ); }

// loading animation
.page-transition {
  @include transition(.8s cubic-bezier(.52, .01, .16, 1) 0s);

  &.fast {
    @include transition(.8s cubic-bezier(.52, .01, .16, 1) 0.1s);
  }

  &.no-transition {
    @include transition(none);
  }
}

.page-left {
  &.on {
    @include transform(translate3D(0%, 0, 0));
  }

  &.off {
    @include transform(translate3D(-100%, 0, 0));
  }
}

.page-opacity {
  @include transition(opacity 500ms ease-in-out, height 1ms linear 500ms, transform 500ms ease-out);
  opacity: 0;
  overflow: hidden;
  height: 0%;

  &.on {
    opacity: 1;
    height: 100%;
    @include transition(opacity 500ms ease-in-out, height 1ms linear 0ms, transform 500ms ease-out)
  }
}


//menu slider animation
.animation {
  @include transition(.9s cubic-bezier(.52, .01, .16, 1) 0.1s);
}

.slideOut {
  @include transform(translate3D(0, 0, 0));
}

.slideIn {
  @include transform(translate3D(100%, 0, 0));
}

@keyframes modalOverlayFadeIn {
  0% {
    background-color: rgba($blue, 0);
  }
  100% {
    background-color: rgba($blue, .4);
  }
}

@keyframes modalFadeIn {
  0% {
    opacity: 0;
    @include transform(scale(.95));
  }
  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}

.modal-alert-overlay {
  @include position(fixed, 0);
  z-index: 255;
  background-color: rgba($blue, .5);
  -webkit-animation: modalOverlayFadeIn .2s ease-out 1; /* Chrome, Opera 15+, Safari 5+ */
  animation: modalOverlayFadeIn .2s ease-out 1; /* Chrome, Firefox 16+, IE 10+, Opera */

  .modal-alert {
    background: #fff;
    padding: 40px 30px 30px;
    width: 320px;
    margin: 5% auto;
    text-align: left;
    position: relative;
    opacity: 1;
    @include transform(scale(1));
    -webkit-animation: modalFadeIn .2s ease-out 1; /* Chrome, Opera 15+, Safari 5+ */
    animation: modalFadeIn .2s ease-out 1; /* Chrome, Firefox 16+, IE 10+, Opera */

    h2 {
      font-size: 24px;
      font-weight: 200;
    }

    a {
      color: $neongreen;

      &:hover {
        color: $green;
      }
    }

    .icon-close {
      @include position(absolute, 0 0 none none);
      color: $blue;
      padding: 15px;
      cursor: pointer;

      &:hover {
        color: $neongreen;
      }
    }
  }

  &.ReactModal__Overlay--before-close {
    background-color: rgba($blue, 0);
    @include transition(background-color .2s ease-out);

    .modal-alert {
      opacity: 0;
      @include transform(scale(.95));
      @include transition(transform .2s ease-out, opacity .2s ease-out);
    }
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

body .intercom-launcher-discovery-frame, .intercom-lightweight-app-launcher {
  background: none !important;
  box-shadow: none !important;
}

body #intercom-container .intercom-launcher-frame, .intercom-lightweight-app-launcher {
  background: transparent !important;
  transform: scale(.44);
  right: auto !important;
  bottom: -7px !important;
  left: 6px !important;
}

body.auth-page #intercom-container, body.auth-page .intercom-lightweight-app-launcher {
  display: none !important;
  left: auto !important;
}

@media (max-width: $mq-tablet-portrait) {
  body .intercom-messenger-frame, .intercom-lightweight-app-launcher {
    bottom: 65px !important;
    left: 20px !important;
    right: auto !important;
  }
}

@media (min-width: $mq-tablet-portrait + 1) {
  body #intercom-container .intercom-launcher-frame, .intercom-lightweight-app-launcher {
    background: #0F5983 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 255 !important;
    transform: scale(.8);
    left: auto !important;
  }

  body.overview-page, body.welcome-page {
    #intercom-container .intercom-launcher-frame, .intercom-lightweight-app-launcher {
      background: none !important;
      right: 0 !important;
      bottom: 0 !important;
      z-index: 2 !important;
      margin-right: 0 !important;
    }
  }

  body .intercom-messenger-frame {
    bottom: 85px !important;
  }

  body.overview-page, body.welcome-page {
    .intercom-messenger-frame {
      bottom: 75px !important;
    }
  }
}

.popover {
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08), 0 1px 4px rgba(0, 0, 0, 0.12);
  padding: 0px 12px;
  border-radius: 4px;
  z-index: 1;

  .epi_images, .epi_description {
    display: inline-block;
    vertical-align: middle;
  }

  .epi_description {
    max-width: 300px;
    font-size: 15px;
    line-height: 140%;
    color: $anthrazit;
    padding: 12px 0px 12px 10px;
  }

  @media (max-width: $mq-tablet-portrait) {
    top: 70px !important;
    left: 20px !important;
    right: 20px !important;
    padding: 0px 0px 12px 10px;
    .epi_description {
      max-width: 100%;
      font-size: 16px;
      line-height: 24px;
      padding: 0px 0px 12px 10px;
    }
  }
}

.indicator-popup {
  background: rgba(255, 255, 255, .9) !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
  0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
  0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 12px;
  border-radius: 4px;
  z-index: 1;

  &:before, &:after {
    display: none;
  }

  .epi_images {
    opacity: .9;
  }

  .epi_images, .epi_description {
    display: inline-block;
    vertical-align: middle;
  }

  .epi_description {
    max-width: 300px;
    font-size: 15px;
    line-height: 140%;
    color: $anthrazit;
    padding: 12px 0px 12px 10px;
  }

  @media (max-width: $mq-tablet-portrait) {
    top: 70px !important;
    left: 20px !important;
    right: 20px !important;
    padding: 0px 0px 12px 10px;
    &:after {
      display: none;
    }
    .epi_description {
      max-width: 100%;
      font-size: 16px;
      line-height: 24px;
      padding: 0px 0px 12px 10px;
    }
  }

  &.place-top {
    margin-top: -7px;
  }
}


.label-popup {
  background: rgba(255, 255, 255, .75) !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
  0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
  0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 16px;
  color: $anthrazit;
  white-space: nowrap;

  &:before, &:after {
    display: none;
  }

  &.place-right {
    margin-left: 15px;
  }
}


.theoretical-ranks {
  margin-top: 10px;
  text-align: right;
  font-size: 14px;
  color: #FF8585;

  @media (max-width: $mq-tablet-portrait) {
    text-align: left;
  }
}

* {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

@page {
  margin: 0;
  size: A4 landscape;
}

iframe[name="us-entrypoint-button"] {
  margin-right: 25% !important;
}
