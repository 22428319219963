/*
 * Style for details, performance page
 */

@import '../../../containers/colors';

.details--team .performance .ColorScale {
  right: 30px;
  bottom: -655px;
}

.performance, .compare {

  .ColorScale {
    position: relative;
    right: auto;
    bottom: auto;
    text-align: right;

    svg {
      display: inline-block;
    }
  }

  .legend_second {
    bottom: 3px;
  }

  &__goalkeeper {
    .performance_ownstats {
      padding-bottom: 45px;
    }
  }

  .center {
    width: 115px;
  }

  .h4 {
    .description {
      display: block;
      font-size: 14px;
      opacity: .7;
    }
  }

  .performance_ownstats {
    .h4 {
      margin-bottom: 0px;
    }

    .col-6 {
      width: 49%;
      padding-bottom: 30px;
    }

    .divider_vertical {
      float: left;
      width: 1px;
      background: darken($light_background_primary, 9%);
      height: 100%;
      height: 585px;
      margin: 5px .5% 0px 0px;

    }

    .chart_group.radar {
      margin-top: 15px;
    }

    .chart_group.radar > h3 {
      text-align: center;
      letter-spacing: .5px;
      margin-bottom: 1px;
      margin-top: 7px;
    }

    .chart_group.radar.against {
      margin-right: 1%;
    }


    h1 {
      margin: 10px 0px 30px;
      font-size: 15px;

      .icon-against, .icon-on {
        margin-right: 5px;

        .icon-against:before {
          font-size: 20px;
        }

        .icon-on:before {
          font-size: 21px;
        }

        &.line:after {
          width: 23%;
        }
      }
    }

    .Radarchart, .Polarchart {
      margin: 0 auto;
      margin-bottom: 35px;
    }
  }


  .performance_optastats {
    h1 {
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .bar_chart_group.row {
      margin: 0px 0px 60px;
    }
  }
}

