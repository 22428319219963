@use "sass:math";

//----- Extends
%clearfix:after {
  display: block;
  clear: both;
  content: '';
}

//----- Grid System
$grid-spacing: 1%;

.row {
  @extend %clearfix;
  height: 100%;
}

[class^="col-"],
[class*=" col-"] {
  float: left;
  margin-right: $grid-spacing;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:last-child {
    margin-right: 0;
  }

  &.noSpacing {
    margin-right: 0;
  }
}

.col-0_75 {
  width: math.div(100%, 14) - ($grid-spacing * math.div(13, 12));
}

.col-1 {
  width: math.div(100%, 12) - ($grid-spacing * math.div(11, 12));
}

.col-1_5 {
  width: math.div(100%, 7) - ($grid-spacing * math.div(10.5, 12));
}

.col-2 {
  width: math.div(100%, 6) - ($grid-spacing * math.div(10, 12));
}

.col-2_5 {
  width: math.div(100%, 5) - ($grid-spacing * math.div(10, 12));
}

.col-3 {
  width: math.div(100%, 4) - ($grid-spacing * math.div(9, 12));
}

.col-3_5 {
  width: math.div(100%, (3.5)) - ($grid-spacing * math.div(8.5, 12))
}

.col-4 {
  width: math.div(100%, 3) - ($grid-spacing * math.div(8, 12));
}

.col-5 {
  width: math.div(100%, 2.4) - ($grid-spacing * math.div(7, 12));
}

.col-5_5 {
  width: math.div(100%, 2.3)
}

.col-6 {
  width: math.div(100%, 2) - ($grid-spacing * math.div(6, 12));
}

.col-7 {
  width: math.div(100%, 1.714285714285714) - ($grid-spacing * math.div(5, 12));
}

.col-8 {
  width: math.div(100%, 1.5) - ($grid-spacing * math.div(4, 12));
}

.col-8_5 {
  width: math.div(100%, 1.4) - ($grid-spacing * math.div(1, 12));
}

.col-9 {
  width: math.div(100%, 1.333333333333333) - ($grid-spacing * math.div(3, 12));
}

.col-9.noSpacing {
  width: 75.75%;
}

.col-10 {
  width: math.div(100%, 1.2) - ($grid-spacing * math.div(2, 12));
}

.col-11 {
  width: math.div(100%, 1.090909090909091) - ($grid-spacing * math.div(1, 12));
}

.col-12 {
  width: 100%;
}
