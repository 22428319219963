@import '../../../containers/colors';
@import '../../../containers/mixin';

.shadow-team {
  &__popup {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 265px;
    position: absolute;
    z-index: 10;
    color: #FFF;
    border-radius: 5px;

    background: $neongreen;

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      width: 0;
      height: 0;
      left: -9px;
      z-index: -1;
    }

    &.left {
      margin-left: 29px;
      margin-top: -19px;

      &:after {
        right: 100%;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 10px solid $neongreen;
      }
    }

    &.right {
      margin-left: -330px;
      margin-top: -15px;

      &:after {
        left: 299px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 10px solid $neongreen;
      }
    }


    nav {
      display: flex;
    }

    .tab {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      display: block;
      cursor: pointer;
      flex-grow: 1;
      width: 100%;
      text-transform: uppercase;
      text-align: center;
      background: #01936F;
      letter-spacing: .1em;

      &:hover {
        background: darken($neongreen, 10%);
      }

      &:nth-child(1) {
        border-radius: 5px 0 0 0;
      }

      &.active {
        cursor: default;
        background: $neongreen;
      }

      &.close {
        width: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        border-radius: 0 5px 0 0;

        svg {
          display: block;
          position: relative;
          top: 13px;
          left: 13px;
          width: 13px;
          height: 13px;
        }
      }
    }

    .tab-content {
      font-size: 14px;
      line-height: 20px;
      flex-grow: 1;

      p {
        margin: 0 0 6px;
      }

      .team-logo {
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        overflow: hidden;

        img {
          display: inline-block;
          vertical-align: middle;
          max-width: 20px;
          max-height: 20px;
        }
      }

      .ratings {
        margin-top: 5px;
        display: flex;

        .value {
          width: 33%;
          margin-left: 15px;

          &:nth-child(1) {
            margin-left: 0;
          }
        }

        .name {
          font-size: 12px;
          line-height: 16px;
          opacity: .6;
        }
      }

      &__substitutes {
        list-style: none;
        padding: 0;
        margin: 0;

        .substitue-player {
          padding: 6px 15px;
          position: relative;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;


          &:nth-child(even) {
            background: darken($neongreen, 1%);
          }

          .roundedRat {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 100%;
            border: 1px solid #FFF;
            color: #FFF;
            line-height: 28px;
            font-size: 11px;
            text-align: center;
            margin-right: 15px;

            &.fill {
              background: #FFF;
              color: $neongreen;
            }
          }

          .compare-substitute {
            width: 105px;
            padding-left: 14px;
            flex-grow: 0;
            border-left: 1px solid darken($neongreen, 3%);
            color: #FFF;
            cursor: pointer;

            &:hover {
              color: #35495C;
            }

            .compare-icon {
              display: inline-block;
              margin-right: 5px;
              width: 14px;
              height: 14px;
              vertical-align: middle;
            }
          }

          .player {
            flex-grow: 1;
            overflow: hidden;
            cursor: pointer;

            &.inactive {
              pointer-events: none;
              opacity: .6;
            }

            &:hover {
              color: #35495C;
            }
          }

          .swap-icon {
            width: 13px;
            height: 13px;
            vertical-align: middle;
            opacity: .6;
          }
        }
      }

      &__player {
        padding: 10px;

        .row {
          position: relative;
        }

        .col-5 .pic {
          max-width: 100px;
          height: 130px;
          position: relative;
        }

        p.firstname {
          margin: 0 0 0px;
        }

      }
    }
  }

  &__popup-bottom {
    background: #35495C;
    height: 48px;
    display: flex;
    flex-direction: row;
    border-radius: 0 0 5px 5px;

    a {
      display: block;
      text-align: center;
      width: 50%;
      background: #35495C;
      height: 45px;
      color: #FFF;
      line-height: 45px;

      font-size: 13px;
      text-transform: uppercase;
      padding: 1px 10px;
      display: block;
      letter-spacing: .1em;
      white-space: nowrap;

      .link-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: middle;
        top: -2px;
        position: relative;
      }

      &:hover {
        color: $neongreen;
      }

    }
  }

  &__popup-content {
    flex-grow: 1;
    height: 173px;
  }
}
