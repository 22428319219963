@import '../../../containers/colors';
@import '../../../containers/mixin';

.table-filter {
  display: inline-block;
  position: relative;
  top: -2px;

  .table-filter-action {
    width: 13px;
    height: 13px;
    color: $color_link;

    &:hover {
      color: $color_active;
    }

    &.active {
      color: $color_active;
      width: 16px;
      height: 16px;

      .table-filter-list {
        color: $color_active;
      }
    }
  }

  .table-filter-list {
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    z-index: 2;
    top: 22px;
    left: 0;
    background: #FFF;
    min-width: 100px;
    max-width: 250px;
    display: block;
    padding: 8px 8px;


    &__value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
      padding: 8px 16px 7px 34px;
      font-size: 15px;
      font-weight: normal;
      position: relative;
      text-transform: none;
      color: $black;
      letter-spacing: 0px;

      &:hover {
        background: #ebf5ff;
        cursor: pointer;
      }

      .value-checkbox {
        width: 18px;
        height: 18px;
        display: inline-block;
        border: 1px solid;
        font-size: 10px;
        color: $black;
        text-align: center;
        line-height: 18px;
        position: absolute;
        left: 8px;
        top: 7px;

        &.active {
          background: $color_active;
        }
      }
    }
  }
}
