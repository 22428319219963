@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.history_seasons {

  table {
    width: 100%;
  }

  th {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #636464;
    text-align: left;
    padding: 0 15px 9px 16px;
    white-space: nowrap;

    .table-filter {
      margin-left: 8px;
    }

    &:nth-child(1) {
      width: 50px;
      padding-right: 7px;
    }

    &:nth-child(2) {
      width: 80px;
      padding-left: 8px;
    }

    &.epi {
      color: #111;
    }
  }

  .history_seasons-table--row {
    &.open {
      .history_seasons-table__empty {
        height: 24px;
      }

      .history_seasons-table__item {
        border-radius: 8px 8px 0 0px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
        background: #ebf5ff;

        td {
          &:first-child {
            border-radius: 8px 0 0 0px;
          }

          &:last-child {
            border-radius: 0 8px 0px 0;
          }

          .history_seasons-table__collapse-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .history_seasons-table__item {
    // margin-bottom: 15px;
    background: #FFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    td {
      padding: 12px 12px 12px 16px;
      position: relative;
      font-size: 15px;
      line-height: 18px;
      cursor: pointer;

      .nowrap {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      &:before {
        content: '';
        position: absolute;
        background: #DFE0E1;
        left: 0;
        top: 0px;
        width: 1px;
        height: 43px;;
      }

      &:nth-child(1), &:nth-child(2) {
        &:before {
          display: none;
        }
      }

      &:nth-child(1) {
        width: 50px;
        padding-right: 7px;
      }

      &:nth-child(2) {
        width: 80px;
        padding-left: 8px;
      }

      &:nth-child(3) {
        min-width: 230px;
      }

      .icon-epi {
        width: 16px;
        height: 12px;
        display: inline-block;
        position: relative;
        top: -1px;
        fill: #333;
        opacity: .9;
        left: -4px;
      }

      &.rating {
        font-weight: 500;
        color: #111;
      }

      a {
        color: $color_link;
        white-space: nowrap;
        letter-spacing: .5px;

        .history_seasons-table__details-icon {
          width: 16px;
          height: 16px;
          position: relative;
          top: -2px;
          margin-right: 6px;
          fill: $color_link;
        }

        &:hover {
          color: $color_active;

          .history_seasons-table__details-icon {
            fill: $color_active;
          }
        }
      }

      &.centered {
        text-align: center;
      }

      &.borderless:before {
        display: none;
      }

      &.not_defined {
        opacity: .5;
      }
    }

    &:hover {
      .history_seasons-table__collapse-icon {
        stroke: $color_active;
      }

      td {
        background: #ebf5ff;
      }
    }
  }


  .header-icon {
    width: 18px;
    height: 18px;
  }

  .matches-icon {
    stroke: #333;
    fill: none;
  }

  .goals-icon {
    fill: none;
    stroke: #333;
  }

  .assists-icon {
    stroke: #333;
    fill: none;
  }

  .history_seasons-table {

    &__empty {
      background: transparent;
      height: 8px;
    }

    &__details {
      text-transform: uppercase;
    }

    &__collapse-icon {
      stroke: $color_link;
      width: 18px;
      height: 18px;
    }

    .pic {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      top: 4px;
      margin-right: 4px;
      margin-top: -4px;
    }
  }
}
