@import '../../containers/colors';
@import '../../containers/mixin';

.category-selector {
  position: relative;
  display: block;

  .label {
    display: inline-block;
    border-bottom: 4px solid;
    line-height: 11px;
    cursor: pointer;
    white-space: nowrap;

    &:after {
      content: '';
      position: relative;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 4px;
      border-top: 4px solid;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: none;
    }
  }


  &.category-0 {
    .label {
      border-color: rgba(64, 123, 156, .5);
    }
  }

  &.category-1 {
    .label {
      border-color: rgba(240, 223, 0, .5);
    }
  }

  &.category-2 {
    .label {
      border-color: rgba(230, 185, 1, .5);
    }
  }

  &.category-3 {
    .label {
      border-color: rgba(224, 62, 62, .5);
    }
  }

  .list {
    display: block;
    position: absolute;
    top: 100%;
    margin-top: 5px;
    background: #FFF;
    z-index: 3;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .15);
  }

  .list-item {
    padding: 10px;
    cursor: pointer;

    font-size: 13px;
    line-height: 19px;
    color: $blue_neon;

    svg {
      width: 19px;
      height: 19px;
      display: inline-block;
      vertical-align: middle;
      stroke: $blue_neon;
      fill: transparent;
      position: relative;
      top: -2px;
      margin-right: 5px;
    }

    &.category-0 {
      svg {
        color: #407B9C;
        fill: #407B9C;
        stroke: #407B9C;
      }
    }

    &.category-1 {
      svg {
        color: #f9bd07;
        fill: #f9bd07;
        stroke: #f9bd07;
      }
    }

    &.category-2 {
      svg {
        color: #FA9D2A;
        fill: #FA9D2A;
        stroke: #FA9D2A;
      }
    }

    &.category-3 {
      svg {
        color: #D42B2F;
        fill: #D42B2F;
        stroke: #D42B2F;
      }
    }

    &:hover {
      background: rgba(1, 186, 140, 0.1);
    }
  }
}
