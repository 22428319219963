@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.notes-desktop-container {
  .notes-content {
    flex-grow: 1;
    flex-shrink: 1;
    width: 70%;
    padding: 29px 0px 35px 30px;
    position: relative;

    &__wrapper {
      padding-bottom: 35px;
      padding-right: 30px;
    }

    .note-content {
      -ms-user-select: text;
      -webkit-user-select: text;
      -moz-user-select: text;
      user-select: text;
      padding-bottom: 35px;
      border-bottom: 1px solid $color_light_border;
      margin-bottom: 35px;

      &__wrapper {
        overflow: hidden;
      }

      &.player-profile {
        border-bottom: none;
      }

      &__title {
        display: flex;

        h2 {
          flex-grow: 1;
        }
      }

      &__button {
        width: 18px;
        height: 18px;
        display: block;
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        color: rgba(0, 0, 0, .3);
        transition: color $time_transition ease-out;
        margin-left: 20px;
        top: 5px;
        cursor: pointer;

        &:hover {
          color: $color_active;
        }

        &.edit-note {
          svg {
            fill: none;
            stroke: currentColor;
          }
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      h2 {
        color: $color_content_title;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin: 0;
      }

      p {
        font-size: 15px;
        line-height: 22px;
        color: $color_content_text;
        margin-top: 15px;
      }

      &__meta {
        font-size: 12px;
        line-height: 18px;
        margin: 15px 0 0px;
        color: $color_meta_text;
        text-transform: uppercase;
        letter-spacing: .5px;
      }

      &__tags {
        margin-top: 15px;
      }


      &__player {

        margin: 35px 0;

        h4 {
          font-size: 14px;
          line-height: 20px;
          color: $color_meta_text;
          margin: 0 0 10px;
          font-weight: normal;
        }

        .player-card {
          padding: 15px;
          border: 1px solid $color_light_border;
          border-radius: 4px;
          color: $color_meta_text;

          &:hover {
            background: $color_active_light_blue;
          }

          .player-text {
            .player-name {
              color: $color_link;
            }
          }
        }
      }

      &__more {
        margin: 35px 0;

        h4 {
          font-size: 14px;
          line-height: 20px;
          color: $color_meta_text;
          margin: 0 0 10px;
          font-weight: normal;
        }

        .notes-list {
          &__item {
            overflow: hidden;
            border: 1px solid $color_light_border;
            border-radius: 4px;
            padding: 15px;
            transition: background $time_transition ease-out;
            margin: 0px 0px 10px 0;

            &:hover {
              background: $color_active_light_blue;
            }

            h4 {
              font-size: 15px;
              line-height: 21px;
              font-weight: 500;
              color: $blue;
            }

            &-player-pic {
              display: none;
            }

            &-text {
              color: $color_content_text;
            }
          }
        }
      }
    }
  }
}
