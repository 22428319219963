/*
 * Style for details, navigation
 */

@import '../../../../containers/colors';

.details .nav-container {
  background: $dark_background_primary;

  a {
    padding: 12px 3px 9px;
    text-overflow: ellipsis;
    overflow: hidden;

    svg[name="icon-lock"] {
      height: 14px;
      width: 14px;
      margin: 0px;
    }

    &.inactive {
      opacity: .6;
    }


    &.active {
      color: $primary-color;
      font-weight: 500;
      border-bottom: 3px solid $primary-color;
      padding: 12px 10px 8px;
    }

    &:hover {
      color: $primary-color;
      font-weight: 500;
    }
  }
}
