@import '../../../containers/colors';
@import '../../../containers/mixin';

.date-range-dropdown {
  position: relative;

  &.is-open {
    .dropdown-control {
      &:after {
        border-width: 0 5px 5px;
      }
    }
  }

  .dropdown-label {
    color: #888;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: .7px;
    padding: 10px 12px 8px;
    position: relative;
    text-transform: uppercase;
    background: #f2f2f2;
  }

  .dropdown-control {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    color: #fff;
    outline: none;
    padding: 11px 20px 4px 1px;
    transition: all 50ms ease;
    cursor: pointer;

    &:hover {
      color: #35495C;
    }

    &:after {
      @include position(absolute, 16px 10px none none);
      border-left-color: transparent;
      border-right-color: transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content: ' ';
      display: block;
      height: 0;
      width: 0;
      margin-top: -ceil(2.5);
    }
  }

  .dropdown-menu {
    @include position(absolute, 100% 0 none);
    border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    background: #fff;
    z-index: 255;

    .show-all {
      font-size: 13px;
      color: lighten(#113245, 0%);
      opacity: .8;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      max-height: 0;
      transition: all .25s ease-out 0s;

      &:hover {
        opacity: 1;
        background-color: rgba(1, 186, 140, 0.1);
        color: #333;
        cursor: pointer;
      }

      &:hover span {
        background: transparent;
      }

      &.visible {
        max-height: 30px;
        margin-bottom: 0;
      }

      span {
        padding: 6px 12px 8px;
        background: #fff;
        position: relative;
        display: inline-block;
      }
    }

    .menu-options {
      transition: max-height .25s ease-out 0s;
      overflow: hidden;

      .option {
        white-space: nowrap;
        font-size: 14px;
        padding: 12px;
        color: #113245;
        cursor: pointer;
        line-height: 14px;
        box-shadow: inset 0 -1px 0 #f2f2f2;

        &:hover,
        &.active {
          background-color: rgba(1, 186, 140, 0.1);
          color: #333;
        }

        &.active {
          cursor: default;
        }
      }
    }

    .menu-range {
      .slider {
        padding: 0 12px 20px;
        margin-top: 5px;

        .handle {
          width: 24px;
          height: 24px;
          line-height: 24px;
        }

        .slider-value {
          font-size: 13px;
          line-height: 20px;
          top: 26px;
          width: auto;

          &:first-child {
            left: 12px;
          }

          &:last-child {
            right: 12px;
          }
        }
      }
    }
  }
}
