@import '../../../containers/mixin';
@import '../../../containers/colors';

.match-header {
  min-height: 109px;
  padding-bottom: 5px;

  @media (min-width: $mq-tablet-portrait + 1) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .header {
    padding-top: 50px;

    @media (min-width: $mq-tablet-portrait + 1) {
      padding-top: 0;
    }
  }

  & > .row {
    width: 100%;

    & > [class^="col-"] {
      float: none;
      display: inline-block;
      vertical-align: bottom;
      // margin-right: 0%;
    }
  }

  .match-header-main {
    margin-bottom: 10px;

    @media (max-width: $mq-tablet-portrait) {
      margin: 5px 0 20px;
    }

    .team {
      display: inline-block;
      position: relative;
      vertical-align: top;
      color: #fff;
      width: 42%;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 38%;
      }

      &:hover {
        .team-name {
          color: tint($neongreen, 90%);
        }
      }

      .pic {
        height: auto;
        display: inline-block;
        // margin-top: -38px;
        width: 36px;
        @include position(absolute, 50% none none none);

        @media (min-width: $mq-tablet-portrait + 1) {
          width: 58px;
        }
      }

      .team-info {
        // display: inline-block;

        .team-name {
          font-size: 18px;
          line-height: 42px;
          font-weight: 500;
          vertical-align: top;
          max-height: 42px;
          overflow: hidden;
          display: block;

          @media (min-width: $mq-tablet-portrait + 1) {
            font-size: 20px;
            line-height: 46px;
          }

          .align {
            line-height: 21px;
            display: inline-block;
            vertical-align: middle;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            @media (min-width: $mq-tablet-portrait + 1) {
              // line-height: 23px;
            }
          }
        }

        .place {
          font-size: 13px;
          line-height: 17px;
          margin-top: 2px;
          display: block;

        }

        .team-legend-color {
          display: inline-block;
          @include position(absolute, none none 1px none);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid $neongreen;
        }

      }

      &.team-home {
        // padding-left: 70px;
        text-align: right;

        .pic {
          // left: 0;
          right: 0px;
        }

        .team-info {
          position: absolute;
          right: 44px;
          left: 0px;

          @media (min-width: $mq-tablet-portrait + 1) {
            right: 66px;
          }

          .place {
            margin-right: 20px;
          }

          .team-legend-color {
            background: $team_home;
            right: 0px;
          }

        }

      }

      &.team-away {

        .pic {
          left: 0;
        }

        .team-info {
          position: absolute;
          left: 44px;
          right: 0px;

          @media (min-width: $mq-tablet-portrait + 1) {
            left: 66px;
          }

          .place {
            margin-left: 20px;
          }

          .team-legend-color {
            background: $team_away;
            left: 0px;
          }
        }

      }
    }

    .goals {
      width: 16%;
      vertical-align: top;
      display: inline-block;
      text-align: center;
      margin-top: 13px;
      white-space: nowrap;
      position: relative;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 24%;
      }

      .time {
        @include position(absolute, -18px 0 none none);
        width: 100%;
        font-size: 12px;
        line-height: 14px;

        @media (min-width: $mq-tablet-portrait + 1) {
          top: -20px;
        }
      }

      .big {
        font-size: 24px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 5px;

        @media (min-width: $mq-tablet-portrait + 1) {
          font-size: 40px;
          line-height: 24px;
        }
      }

      .small {
        font-size: 14px;
        line-height: 20px;
        color: rgba(#fff, .75);
        margin-top: 4px;

        @media (min-width: $mq-tablet-portrait + 1) {
          font-size: 16px;
          line-height: 25px;
          margin-top: 5px;
        }
      }

      .goals-info {
        font-size: 12px;
        line-height: 14px;
        margin-top: 2px;
      }
    }
  }

  .data-text {
    font-size: 15px;

    .pic {
      height: auto;
      width: 16px;
      display: inline-block;
      position: relative;
      margin-right: 4px;
    }
  }

  .data-item {
    text-align: left;

    @media (min-width: $mq-tablet-portrait + 1) {
      max-width: 42% !important;
      margin-right: 0 !important;
      margin-left: 8%;
    }
  }

  .header__profile_detail_container--left {
    .data-item:nth-child(1) {
      max-width: 56% !important;
    }

    .data-item:nth-child(2) {
      max-width: 28% !important;
    }
  }
}
