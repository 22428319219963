/*
 * Mixins
 */

$mq-mobile-portrait: 320px;
$mq-mobile-landscape: 480px;
$mq-tablet-portrait: 768px;
$mq-tablet-landscape: 1024px;
$mq-desktop: 1382px;

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Everything up to and including the mobile landscape width
@mixin mobile-landscape-and-below {
  @media only screen and (max-width: $mq-tablet-landscape) {
    @content;
  }
}


@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}


@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}


@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition2($transition, $transition2) {
  -moz-transition: $transition, $transition2;
  -o-transition: $transition, $transition2;
  -webkit-transition: $transition, $transition2;
  transition: $transition, $transition2;
}

@mixin transform($transform) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}


@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}


@mixin rotate( $val: -2deg ) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}

@function _unpack-shorthand($shorthand) {
  @if length($shorthand) == 1 {
    @return nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1) nth($shorthand, 1);
  } @else if length($shorthand) == 2 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 1) nth($shorthand, 2);
  } @else if length($shorthand) == 3 {
    @return nth($shorthand, 1) nth($shorthand, 2) nth($shorthand, 3) nth($shorthand, 2);
  } @else {
    @return $shorthand;
  }
}

@function _is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@mixin position(
  $position,
  $box-edge-values
) {

  $box-edge-values: _unpack-shorthand($box-edge-values);

  $offsets: (
    top: nth($box-edge-values, 1),
    right: nth($box-edge-values, 2),
    bottom: nth($box-edge-values, 3),
    left: nth($box-edge-values, 4),
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}


@function _is-color($color) {
  @return (type-of($color) == color) or ($color == "currentColor");
}

@function tint(
  $color,
  $percent
) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `tint` mixin.";
  } @else {
    @return mix(#fff, $color, $percent);
  }
}

@function shade(
  $color,
  $percent
) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `shade` mixin.";
  } @else {
    @return mix(#000, $color, $percent);
  }
}
