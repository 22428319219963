@import '../../../containers/colors';
@import '../../../containers/mixin';

.match-tooltip {
  .row.tooltip__content_name {
    margin-left: 5px;
    margin-top: -5px !important;
  }

  .row.tooltip__content_stats {
    margin: 10px 0 20px !important;

    .goals {
      margin: 5px;
      font-size: 15px;
      font-weight: 500;
      display: none;

    }

    .team {
      font-size: 14px;
    }
  }

  .lastname {
    line-height: 110%;
    font-size: 18px;
  }
}

.heatmap {
  font-size: 0;
  padding: 10px 0 0 20px;
  @include mobile-landscape-and-below {
    padding: 0 10px 0;
  }
  border-bottom: 1px solid darken($dark_background_primary, 8%);

  &.matches .group {
    padding: 0px 20px 0px 0px;
  }

  .group {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    position: absolute;
    @include transition(height 1s ease-in-out, width 1s ease-in-out, left 1s ease-in-out, top 1s ease-in-out);

    .group-name {
      font-size: 13px;
      letter-spacing: .05em;
      color: #B2C8DD;
      line-height: 27px;
      padding: 10px 0 2px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      @include mobile-landscape-and-below {
        padding: 5px 0 2px;
      }
    }

    .group-list {
      display: flex;
      flex-wrap: wrap;
      background-color: darken($dark_background_primary, 2%);

      .match {
        font-size: 13px;
        color: #fff;
        line-height: 120%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        @include transition(background-color 1s);

        &:before {
          display: inline-block;
          content: '';
          padding-top: 61.8%;
          background: black;
          opacity: .1;
          width: 0;
          vertical-align: middle;
          overflow: hidden;
        }

        &.active {
          background-color: #fff !important;
          color: $green;
          vertical-align: middle;
          @include transition(background-color .2s);
        }

        .align {
          width: 100%;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          display: inline-block;
          padding-top: 3%;
        }

        .home,
        .away {
          display: inline-block;
          vertical-align: middle;
          width: 14.25%;

          .pic {
            display: inline-block;
            width: 100%;
            position: relative;
          }
        }

        .team-name {
          margin-top: -2px;
          font-size: 12px;
          letter-spacing: 1px;
          width: 30px;
          display: block;
          margin-left: -25%;
        }

        .goals {
          display: inline-block;
          vertical-align: middle;
          font-size: 22px;
          font-weight: 500;
          margin: -15px 9px 0;
          letter-spacing: -2px;

        }
      }
    }

    &.group-middle {
      border-right: 1px solid transparent;
    }

    &.group-small {
      border-left: 1px solid transparent;
    }
  }

  &.matches {
    .group {
      position: relative;
    }
  }
}
