@import '../../../containers/mixin';
@import '../../../containers/colors';

.summary--match {
  .col-pad-right {
    padding-right: 5%;
  }

  .col-pad-left {
    padding-left: 5%;
  }

  .chart_group {
    .chart_bar_value_benchmark a,
    .chart_bar_value_name a {
      color: $squad_link_hover;
      z-index: 1;
      position: relative;
    }
  }

  .no-events {
    font-size: 14px;
    opacity: .6;
    text-transform: uppercase;
    margin-top: -10px;

    @media (max-width: $mq-tablet-portrait - 1) {
      margin-top: 0;
    }
  }
}

.match-goals,
.match-best-players {
  .player-link {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    line-height: 28px;
    margin: -10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .pic {
      @include position(absolute, 50% none none 0);
      width: 22px;
      height: 28px;
      margin-top: -14px;
    }
  }
}

.match-cards {
  .player-link {
    display: inline-block;
    position: relative;
    line-height: 28px;
    margin: -10px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.match-matchdays {
  display: table;
  table-layout: fixed;
  width: 100%;

  .match-link {
    display: table-row;
    color: $squad_link_hover;

    &:nth-child(odd) {
      .td {
        background-color: $squad_tr_color;
      }
    }

    &.highlight {
      .td {
        background: rgba(#C4D3E1, .8);
      }
    }

    &.show-more {
      cursor: pointer;
      border-top: 1px solid #99afc4;
      display: table-caption;
      caption-side: bottom;

      .td {
        display: block;
        text-align: center;
      }
    }
  }

  a.match-link {
    &:hover {
      color: $black_blue_dark;

      .td {
        background: rgba(#C4D3E1, 1) !important;
        @include transition(background 0s);
      }
    }
  }

  .team {
    display: inline-block;
    position: relative;
    padding-right: 23px;

    .pic {
      @include position(absolute, 50% 0 none none);
      width: 16px;
      height: 21px;
      margin-top: -10px;
    }
  }

  .td {
    display: table-cell;
    padding: 12px 5px;
    line-height: 18px;
    font-size: 13px;
    white-space: nowrap;
    @include transition(background .3s);

    @media (min-width: $mq-tablet-portrait + 1) {
      font-size: 14px;
    }

    &:first-child {
      padding-left: 5px;
      text-align: right;

      @media (min-width: $mq-tablet-portrait + 1) {
        padding-left: 14px;
      }
    }

    &:last-child {
      padding-right: 5px;
      text-align: left;

      @media (min-width: $mq-tablet-portrait + 1) {
        padding-right: 14px;
      }
    }

    &.td-goals {
      width: 46px;
      text-align: center;
      color: $black_blue_dark;
      white-space: nowrap;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 54px;
      }

      .goals-extra-info {
        height: 8px;
        display: block;
        line-height: 8px;
      }
    }

    &.text-right {

      .team {
        padding-right: 0;
        padding-left: 23px;

        .pic {
          @include position(absolute, 50% none none 0);
        }
      }
    }
  }
}

.match-goals,
.match-cards,
.subsitutions-table,
.bench-table,
.match-best-players {
  width: 100%;
  color: $black_blue_dark;

  .team-image {
    display: inline-block;
    width: 15px;
    height: 20px;
    margin: -6px 0;
    position: relative;

    @media (min-width: $mq-tablet-portrait + 1) {
      width: 25px;
      height: 32px;
      margin: -12px 0;
    }
  }

  .card {
    width: 16px;
    height: 24px;
    border: 1px solid #E4E4E4;
    display: inline-block;
    margin: -10px 0;
    position: relative;

    &.yellow {
      background: #FFD855;
    }

    &.yellow-second {
      background: $red;
      margin-left: 10px;

      &:before {
        content: '';
        width: 16px;
        height: 24px;
        border: 1px solid #E4E4E4;
        background: #FFD855;
        @include position(absolute, -1px none none -10px);
      }
    }

    &.red {
      background: $red;
    }
  }

  a {
    color: $squad_link_hover;

    &:hover {
      color: $black_blue_dark;
    }
  }

  tbody {
    tr {
      td {
        padding: 12px 5px;
        line-height: 18px;
        font-size: 13px;
        white-space: nowrap;

        @media (min-width: $mq-tablet-portrait + 1) {
          font-size: 14px;
        }

        &:first-child {
          padding-left: 5px;

          @media (min-width: $mq-tablet-portrait + 1) {
            padding-left: 14px;
          }
        }

        &:last-child {
          padding-right: 5px;

          @media (min-width: $mq-tablet-portrait + 1) {
            padding-right: 14px;
          }
        }

        &.not_defined {
          color: darken($grey_dark, 20%);
        }

        &.text-right {
          text-align: right;

          .player-link {
            padding-right: 30px;
            padding-left: 0;

            .pic {
              @include position(absolute, 50% 0 none auto);
            }
          }

          .team-link {
            padding-left: 0;
            padding-right: 23px;

            .pic {
              @include position(absolute, 50% 0 none auto);
            }
          }
        }

        &.td-team-image {
          width: 25px;

          @media (min-width: $mq-tablet-portrait + 1) {
            width: 35px;
          }

          &:first-child,
          &:last-child {
            width: 25px;

            @media (min-width: $mq-tablet-portrait + 1) {
              width: 44px;
            }
          }
        }

        &.td-min {
          width: 26px;

          @media (min-width: $mq-tablet-portrait + 1) {
            width: 30px;
          }
        }

        &.td-goals {
          width: 34px;
          text-align: center;

          @media (min-width: $mq-tablet-portrait + 1) {
            width: 40px;
          }
        }

        &.clear {
          width: auto;
          padding: 0;
        }

        &.td-position {
          font-size: 14px;
          color: #979797;
          width: 46px;
        }

        &.td-goals-player {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 0;
          width: 50%;
        }

        &.td-cards-player {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 0;
          width: 100%;
        }

        &.td-best-player {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          max-width: 0;
        }

        &.td-card {
          width: 45px;
        }
      }

      &:nth-child(odd) {
        td {
          background-color: $squad_tr_color;
        }
      }

      &.highlight {
        td {
          background: rgba(#C4D3E1, .8);
        }
      }
    }
  }
}

.match-goals {
  table-layout: fixed;
}
