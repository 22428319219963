/*
 * Style for compare, page
 */

@import '../../../containers/colors';
@import '../../../containers/mixin';

.compare__list {
  position: absolute;
  left: 20px;

  .ReactVirtualized__List {
    @include box-shadow(0px, 0px, 6px, rgba(0, 0, 0, .3));
    @include border-radius(4px);
    background-color: white;
    position: relative;
    z-index: 2;

    .position_group {
      font-weight: bold;
    }

    .position_detail {
      padding-left: 24px;
    }

    &:empty {
      display: none;
    }

    .compare__list_item {
      font-size: 14px;
      padding: 7px 13px;
      color: $dark_actionbar;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .pic {
        display: inline-block;
        width: 14px;
        position: relative;
        vertical-align: middle;
        margin: 0 8px 0 -2px;
      }

      &:hover {
        cursor: pointer;
        outline: none;
        background-color: $primary-color;
      }

      &:focus,
      &:active {
        background-color: $primary-color;
        color: $dark_link_text;
      }
    }
  }
}
