@import '../../containers/colors';
@import '../../containers/mixin';


.details .star-category-selector .list {
  top: 30px;
}

.star-category-selector {
  position: relative;
  display: block;

  .label {
    display: inline-block;
    cursor: pointer;
  }


  &.category-0 {
    .label {
      color: #407B9C;

      svg {
        fill: #407B9C;
        stroke: #407B9C;
      }
    }
  }

  &.category-1 {
    .label {
      color: #f0df00;

      svg {
        fill: #f0df00;
        stroke: #f0df00;
      }
    }
  }

  &.category-2 {
    .label {
      color: #e6b901;

      svg {
        fill: #e6b901;
        stroke: #e6b901;
      }
    }
  }

  &.category-3 {
    .label {
      color: #e03e3e;

      svg {
        fill: #e03e3e;
        stroke: #e03e3e;
      }
    }
  }

  .list {
    display: block;
    position: absolute;
    top: 40px;
    right: 5px;
    text-align: left;
    background: #FFF;
    z-index: 3;

    -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;

    color: $blue;
  }

  .list-item {
    cursor: pointer;
    font-size: 14px;
    padding: 12px 13px;
    color: #113245;
    line-height: 1;

    svg {
      width: 19px;
      height: 19px;
      display: inline-block;
      vertical-align: middle;
      stroke: $blue_neon;
      fill: transparent;
      position: relative;
      top: -2px;
      margin-right: 5px;
    }

    &.category-0 {
      svg {
        color: #407B9C;
        fill: #407B9C;
        stroke: #407B9C;
      }
    }

    &.category-1 {
      svg {
        color: #ede101;
        fill: #f0df00;
        stroke: #f0df00;
      }
    }

    &.category-2 {
      svg {
        color: #e6b901;
        fill: #e6b901;
        stroke: #e6b901;
      }
    }

    &.category-3 {
      svg {
        color: #e03e3e;
        fill: #e03e3e;
        stroke: #e03e3e;
      }
    }

    &:hover {
      background: rgba(1, 186, 140, 0.1);
    }
  }
}
