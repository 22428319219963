@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.history-season {
  background: #FFF;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
  border-radius: 0 0 8px 8px;

  th {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    color: #636464;
    background: #FCFEFF;
    padding: 12px 15px 6px;
    border-top: 1px solid #DFE0E1;
    border-bottom: 1px solid #DFE0E1;
  }

  td {
    background: #FFF;
    // border-left: 1px solid #DFE0E1;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 17px;
    color: #333;
    border-bottom: 1px solid #fafafa !important;

    &:first-child {
      border: 0px;
    }

    a {
      text-transform: uppercase;
      color: $color_link;
      letter-spacing: .5px;

      &:hover {
        color: $color_active;
      }
    }

    .green {
      color: #187141;
    }

    .red {
      color: #ff5959;
    }

    .icon-epi {
      width: 16px;
      height: 12px;
      display: inline-block;
      position: relative;
      top: -1px;
      fill: #333;
      opacity: .9;
      left: -4px;
    }

    &.rating {
      font-weight: 500;
      color: #111;
    }
  }


  tr {
    &:nth-child(odd) {
      td {
        background: #f2f4f5;
      }
    }

    &:hover td {
      background: #ebf5ff;
    }

    &:last-child td {
      &:first-child {
        border-radius: 0 0 0 8px;
      }

      &:last-child {
        border-radius: 0 0 8px 0;
      }
    }

    &.red-row {
      td {
        background: rgba(122, 59, 67, .2);
      }
    }

    &.yellow-row {
      td {
        background: rgba(255, 216, 85, .2);
      }
    }

    &.bench-row {
      td {
        background: #ECEDED;
      }
    }
  }

  .centered {
    text-align: center;
  }

  .borderless {
    border: none;
  }

  .not_defined {
    opacity: .5;
  }
}
