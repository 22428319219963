/*
 * Style for overview
 */

@import '../../containers/colors';
@import '../../containers/mixin';

.overview {
  background-color: $dark_background_primary;
  margin-right: 62px;
  @include position(fixed, 0 0 0 0);

  &.player-rankings {
    background: white;
  }

  #toggleMenuShow,
  .resize_handle {
    &:hover {
      color: $primary-color;
      cursor: pointer;

      .icon-collapse {
        color: $primary-color;
      }
    }
  }

  .overview_table,
  .overview_table .fade-out,
  .overview_scatterplot,
  .resize_handle,
  .csv-export {
    @include transition(.6s cubic-bezier(.52, .01, .16, 1) 0.1s);
  }

  .overview_table,
  .overview_scatterplot {
    left: 0;
    right: 0;
  }

  .overview_table {
    position: relative;
    bottom: 0;
  }

  .overview_scatterplot {
    position: absolute;
    top: 0;
  }

  .dataView {
    min-width: 650px;
    margin-right: 0px;
    position: relative;

    .scatterplot_container {
      padding: 20px 20px 0px 15px;
    }

    .scatterplot {
      .axis text {
        font-size: 12px;
        fill: #6C8DAA;
      }

      .axis {
        path {
          stroke: none;
        }

        line {
          stroke: lighten($dark_background_secondary, 2%);
        }

        .tick:nth-child(0) line {
          stroke: none;
        }
      }

      .axis:nth-child(1) {
        line {
          stroke: lighten($dark_background_primary, 2%);
        }

      }

      .axis.x_axis .tick line {
        stroke: none;
      }

      .domain {
        display: none;
      }

      .lasso {
        fill: #fff;
        fill-opacity: 0.2;
        stroke: #fff;
        stroke-dasharray: 8;
        stroke-width: 2px;
      }

      .menu {
        top: 0px;
        left: -3px;
        @include border-radius(8px);

        .item {
          background: inherit;
          color: white;
          padding: 10px 4px 5px 10px;
          width: 100%;
          height: 100%;
          margin-bottom: 10px;

          &:last-child {
            margin: 0px;
          }

          .icon-lasso, .icon-minus, .icon-plus {
            font-size: 20px;
          }

          .icon-plus {
          }

          .icon-minus {
          }

          &:hover {
            cursor: pointer;
          }

          &.active {
            background: lighten($dark_background_tertiary, 15%);
            @include border-radius(2px);
            color: white;
          }
        }
      }
    }

    .resize_handle {
      @include position(absolute, 0 none none 0);
      z-index: 10000;
      width: 6%;
      max-width: 80px;
      text-align: center;

      &.hidden {
        top: -41px;

        .action-down {
          display: none;
        }

        .action-up {
          padding: 8px 8px 8px 8px;
        }

      }

      &.visible {
        .action-up {
          display: none;
        }
      }

      &.hidden,
      &.visible {
        max-width: 58px;
        background: $blue_lightest;

        .resize-action {
          width: 100%;
        }
      }

      .resize-action {
        padding: 8px 0;
        color: white;
        display: inline-block;
        cursor: pointer;
        width: 50%;
        @include transition(color 0.1s);

        &:hover {
          color: $neongreen;
        }

        &.action-up {
          padding: 8px 0px 8px 8px;
        }

        &.action-down {
          padding: 8px 2px 8px 0px;
        }
      }

      .icon-hide:before {
        font-size: 11px;
      }

      #toggleTableUp,
      #toggleTableDown {
        color: white;
        display: inline-block;
      }

      #toggleTableUp {
        @include rotate(-90deg)
      }

      #toggleTableDown {
        @include rotate(90deg)
      }
    }

    .csv-export {
      z-index: 1;
      position: absolute;
      right: 0;
      color: #ddd;
      display: block;
      padding: 7px 2% 7px 0px;
      cursor: pointer;

      svg {
        width: 20px;
        height: 24px;
      }

      &:hover {
        color: $primary-color;
      }
    }
  }

  .filterView {
    background-color: $dark_sidebar_primary;
    @include position(absolute, 0 0 0 none);

    button, button span, .icon-collapse {
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }

      &:focus, &:active {
        color: $dark_link_text;
      }
    }

    a:hover {
      [class^="icon-"],
      [class*=" icon-"] {
        color: $primary-color;
      }
    }

    .filterView_filters {
      height: 100%;
    }

    .filters {
      left: 1px;
      position: relative;
      width: calc(100% - 1px);

      button,
      .filters__slider {
        padding: 9px 17px;
        border-bottom: 1px solid $dark_sidebar_line_hor_seconday;

        &:last-child {
          border: 0;
        }
      }

      .filters__slider:last-child {
        border: none;
      }

      .filters__slider {
        padding: 9px 16px 4px 20px;
        background-color: $dark_sidebar_secondary;

        h2 {
          text-transform: uppercase;
          color: $grey;
        }

        .slider-value {
          font-size: 13px;
          font-weight: 500;
          color: $dark_link_text;
          white-space: nowrap;
        }

        .slider {
          margin-top: 2px;
        }
      }

      button {
        height: 47px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;

        &:hover {
          background-color: $primary-color !important;
          color: white;
          cursor: pointer;
          outline: none;
        }

        &.h1 {
          background-color: $dark_sidebar_primary;
          border-bottom: 1px solid $dark_sidebar_line_hor_primary;
          color: $dark_link_text;
          padding-top: 9px;
          // text-transform: uppercase;
          letter-spacing: .05em;
          font-size: 14px;

          &.open {
            border-bottom: 2px solid darken($dark_sidebar_primary, 6%);
          }

          > div {
            font-size: 15px;
            padding: 2px 0px 0px 0px;
            float: left;
            width: 24px;

            &.icon-on {
              margin: -2px -2px 0px 2px;
              color: #ddd;
            }

            &.open {
              @include rotate(0deg)
            }
          }
        }

        &.h2 {
          border-bottom-color: $black_light;
          color: $dark_link_text;
          font-size: 14px;

          span {
            display: inline-block;
            padding-top: 5px;
          }

          .icon-collapse {
            font-size: 7px;
            padding: 3px 4px 9px 3px;
            float: left;
            @include rotate(-90deg);

            &:hover {
              color: white;
            }

            &.open {

              @include rotate(0deg);
              margin-top: 7px;
              padding: 0px 6px 7px 1px;
            }
          }
        }

        .list-icon-collapse {
          width: 18px;
          height: 18px;
          padding: 9px;
          box-sizing: content-box;
          position: absolute;
          top: 50%;
          margin-top: -18px;
          right: 8px;
          stroke: #777D85;
          cursor: pointer;

          &.open {
            stroke: #FFF;
            transform: rotate(180deg);
          }
        }

      }

      .ReactVirtualized__List {
        color: $dark_link_text;
        background: darken($dark_sidebar_secondary, 1%);

        .icon-check {
          color: $dark_link_text;
        }
      }
    }

    .filterView_header {
      height: 58px;
      border-bottom: 1px solid $dark_sidebar_line_hor_primary;
      color: #fff;
      font-size: 24px;
      font-weight: 200;
      padding: 0 17px;
      line-height: 66px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }

    .filterView_subheader {
      @include position(absolute, none 0 0 0);
      background-color: $dark_sidebar_primary;
      border-top: 1px solid darken($dark_sidebar_line_hor_primary, 5%);
      z-index: 1;

      .total {
        display: inline-block;
        width: 60%;
        padding: 10px 0 10px 22px;
        font-size: 16px;
        font-weight: 200;
        color: lighten($grey_dark, 1%);

        .icon-player {
          padding: 15px 7px 15px 21px;
          font-size: 17px;
          display: block;
          float: left;
          color: $grey_dark;
        }

        .total_label {
          padding-left: 5px;
        }
      }

      .reset.btn {
        display: inline-block;
        text-align: right;
        width: 40%;
        color: $dark_link_text;
        font-size: 13px;
        font-weight: 300;
        padding: 10px 20px 10px 0px;
      }
    }

    .switcher {
      font-size: 14px;

      .switch {
        display: inline-block;
        width: 52%;
        color: #F2F2F4;
        padding: 13px 0px 13px 14px;
        border-bottom: 1px solid $black_light;

        span:first-child {
          border: 1px solid lighten($black_light, 1%);
          border-radius: 50%;
          padding: 5px 3px;
        }

        &:hover {
          background-color: $primary-color;
          color: white;
          cursor: pointer;
          outline: none;
        }

        & + .switch {
          width: 48%;
          padding: 13px 0px 13px 15px;
          border-left: 1px solid $black_light;
        }

        .icon-check {
          opacity: .9;
          color: white;
          margin-top: 2px;
          margin-right: 7px;
          font-size: 10px;

          &.deactivated {
            color: transparent;
          }
        }
      }
    }
  }

  #toggleMenuShow {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50px;
    width: 50px;
    z-index: 1;
    background-color: $dark_sidebar_primary;

    .icon-hide {
      @include rotate(-180deg);
      padding: 26px 0px 18px 0px;
      font-size: 12px;
      display: block;
      text-align: center;
      color: #D6DFE6;
    }
  }
}

.overview-change {
  @include position(fixed, 0 0 0 0);
  background-color: $dark_background_primary;

  .change-list {
    width: 825px;
    border-radius: 4px;
    @include position(absolute, 50% 50% none none);
    margin: -190px -413px 0 0;
    background: $blue_auth_container;

    li {
      display: block;
      width: 33.333%;
      float: left;
      border-right: 2px solid $dark_background_primary;
      text-align: center;

      a {
        display: block;
        border-radius: 4px;
        color: #fff;
        padding: 50px 50px 40px;
        min-height: 374px;

        &:hover {
          background: #1B3248;
          @include transition(background .2s ease-out);
        }

        svg {
          stroke: #5C7B9C;
          stroke-width: .4px;
          fill: transparent;
          opacity: .8;
        }

        .title,
        .desc {
          display: block;
          color: #E5E5E5;
        }

        .title {
          font-size: 40px;
          line-height: 44px;
          margin-top: 20px;
          font-weight: 200;
          position: relative;
          padding-bottom: 10px;
        }

        .desc {
          font-size: 15px;
          line-height: 22px;
          margin-top: 0;
          opacity: .8;
        }
      }
    }
  }
}
