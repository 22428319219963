@import '../../containers/colors';

//colors
$white: white;
$color_celeste_approx: #ccc;
$color_mine_shaft_approx: #333;
$black_6: rgba(0, 0, 0, 0.06);
$color_mountain_mist_approx: #999;
$color_mine_shaft_80_approx: rgba(51, 51, 51, 0.8);
$color_black_squeeze_approx: rgba(1, 186, 140, 0.1);

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  // background-color: $white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 2px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  color: $white;
  outline: none;
  padding: 11px 20px 4px 1px;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all 50ms ease;
  cursor: pointer;

  &:hover {
    color: $blue;
  }

  .Dropdown-placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.Dropdown-disabled {
    border-bottom: none;
    padding: 12px 20px 3px 0px;

    &:hover > * {
      color: $white !important;
      border-color: $white transparent transparent !important;
      cursor: default;
    }
  }

  &.Dropdown-disabled {
    cursor: pointer;
  }

  &.Dropdown-disabled .Dropdown-arrow {
    display: none;
  }
}

.Dropdown-arrow {
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 16px;
  width: 0;
}

.Dropdown-menu {
  background-color: $white;
  // border: 1px solid $color_celeste_approx;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  min-width: 100%;
  z-index: 1000;

  .Dropdown-group > .Dropdown-title {
    /*padding: 12px 13px;*/
    color: #777;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: .7px;
    padding: 9px 12px 8px;
    position: relative;
    text-transform: uppercase;
    background: #f2f2f2;
  }
}

.Dropdown-option {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  color: $color_mine_shaft_80_approx;
  cursor: pointer;
  display: block;
  // padding: 10px 10px;
  font-size: 14px;
  padding: 12px 13px;
  color: #113245;
  box-shadow: inset 0 -1px 0 #f2f2f2;

  &:last-child {
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 2px;
    //Instead of the line below you could use @include border-bottom-left-radius($radius)
    border-bottom-left-radius: 2px;
  }

  &:hover,
  &.is-selected {
    background-color: $color_black_squeeze_approx;
    color: $color_mine_shaft_approx;
  }

  &.is-selected {
    cursor: default;
  }
}

.Dropdown-noresults {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  color: $color_celeste_approx;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.is-open .Dropdown-arrow {

  border-width: 0 5px 5px;
}

.is-open:hover .Dropdown-arrow {

}

.Dropdown--green {
  .Dropdown-control {
    border-bottom-color: $blue_neon;
    color: $blue;
  }

  .Dropdown-arrow {
    margin-top: 5px;
  }

  .is-open .Dropdown-arrow {

  }

  .Dropdown-menu {
    margin-top: 0;
  }
}

.Dropdown-label {
  line-height: 100%;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400;
  opacity: 0.5;
  display: block;
  margin: 3px 0 -5px;
  letter-spacing: .5px;
}
