/*
 * Style for overview, filters on right sidebar
 */
@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.preset-prompt {
  width: 320px !important;

  button.btn {
    min-width: auto !important;
  }

  &__body {

    input {
      border: 1px solid transparent;
      border-bottom-color: #A7A7A7;
      color: #fff;
      line-height: 18px;
      padding: 11px 1px;
      font-size: 15px;
      width: 100%;

      &::-webkit-input-placeholder {
        color: #fff;
        transition: opacity .2s;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #fff;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #fff;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #fff;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill {
        border-bottom-color: $primary-color;
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: 0 0 0px 1000px $blue inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      &:focus {
        border-bottom-color: $primary-color;

        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }
      }
    }
  }
}

.filters__positions-tabs {
  color: #FFF;
  display: flex;

  span {
    flex-grow: 1;
    width: 33.33%;
    text-align: center;
    font-size: 15px;
    padding: 9px 0 7px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    &.active {
      border-bottom-color: #FFF;
    }
  }
}

.filters__list__checkbox {
  // letter-spacing: .05em;
  font-size: 13px;
  color: #fff;
  padding: 8px 10px 9px 28px;
  border-bottom: 1px solid $black_light;
  background-color: darken($dark_sidebar_primary, 2%);
  cursor: pointer;
  opacity: .9;

  span {
    padding: 2px 12px 8px 12px;
    //background: #21262c;
    position: relative;
    display: inline-block;
  }

  &.active {
    font-weight: 500;
  }

  .icon-check {
    color: #F2F2F4;
    opacity: .9;
    margin-top: 2px;
    margin-right: 8px;
    font-size: 11px;
  }

  .icon-selection-part {
    font-family: 'scout' !important;
    color: #F2F2F4;
    opacity: .9;
    margin-top: 2px;
    margin-right: 8px;
    font-size: 11px;

    &:before {
      content: "\e907";
    }
  }

  span.selection-none {
    border-bottom: 1px solid white;
    width: 14px;
    margin-right: 5px;
    display: inline-block;
    opacity: .7;
    margin-top: 2px;
    margin-right: 8px;
    font-size: 11px;
    color: #F2F2F4;
  }

  &:hover {
    background: $neongreen;
    opacity: 1;
  }


  &.hide-transfers {
    font-size: 14px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    opacity: .8;
    background: #21252b;
    border-bottom: 1px solid #171B20;

    &.active {
      font-weight: 500;
      background: transparent;
    }

    .icon-check {
      color: #F2F2F4;
      opacity: .9;
      margin-top: 2px;
      margin-right: 4px;
      font-size: 11px;
      background: transparent;
    }

    span.selection-none {
      background: transparent;
    }

    &:hover {
      background: $neongreen;
      opacity: 1;
    }
  }

}

.sectionContent {
  overflow: hidden;
  @include transition(max-height .1s ease-in);

  &.open {
    border-bottom: 1px solid darken($dark_sidebar_primary, 6%);
  }

  .core &.open {
    border-bottom: none;
  }

  .country_id, .preferred_foot {
    border-bottom: 1px solid #171B20;

    button {
      background: #23282F;
    }
  }

  .preset_id {

    .icon-close {
      width: 16px;
      height: 16px;
      padding: 0;
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      float: right;
    }
  }


  .filter_list_long {
    background-color: $dark_sidebar_secondary;

    input {
      padding: 8px 0 8px 28px;
      font-size: 14px;
      font-weight: 300;
      color: white;
      outline: none;
      border: none;
      border-bottom: 1px solid $dark_sidebar_line_hor_seconday;
      width: 100%;
    }

    .filter-options-collapse {
      transition: max-height .1s ease-out 0s;
      overflow: hidden;
    }

    .show-all {
      font-size: 14px;
      color: darken(#F2F2F4, 20%);
      cursor: pointer;
      overflow: hidden;
      position: relative;
      max-height: 0;
      padding-left: 38px;
      transition: max-height .1s ease-out 0s;

      &:hover {
        color: $neongreen;
      }

      &.visible {
        max-height: 28px;
      }

      span {
        padding: 2px 12px 8px 12px;
        background: #21262c;
        position: relative;
        display: inline-block;
      }
    }
  }
}


.sectionTitle {
  display: block;
  cursor: pointer;
}


.filters__slider {
  h2 {
    span.selection-none {
      border-bottom: 1px solid white;
      width: 14px;
      margin-right: 5px;
      display: inline-block;
      opacity: .7;
      margin-top: 2px;
      font-size: 11px;
      color: #F2F2F4;
    }

    /*span.link {
      cursor: pointer;
      text-decoration: underline;

      &.active {
        text-decoration: none;
      }
    }*/

    .icon-check {
      color: #F2F2F4;
      opacity: .9;
      margin-top: 2px;
      margin-right: 4px;
      font-size: 11px;
    }
  }

  h3 {
    margin-left: 50px;
  }

  &.filters__sliderid_2 h2 {
    margin-bottom: 3px;
  }

  &.filters__slider--season_id {
    border-top: 1px solid #171B20 !important;
    margin-left: 16px;
    margin-right: 5px;

    h2 {
      margin-left: -8px;
    }

    .slider {
      margin-top: 5px !important;
    }

    .slider-value {
      text-align: center;
    }

    .handle-1 {
      display: none;
    }
  }

  .horizontal-slider {
    width: calc(100% - 80px);
  }

  .handle {
    background: desaturate(#35495C, 15%);
    cursor: pointer;
    border: 1px solid $dark_sidebar_secondary;
  }

  .bar {
    &,
    &.bar-2 {
      background-color: darken(#23282F, 5%);
    }

    &.bar-1 {
      background: desaturate(#35495C, 15%);
    }
  }

  .filters__slider__checkbox {
    margin: -6px -16px 0px -20px;
  }
}

.levelFirst, .levelSecond, .levelThird {
  font-size: 13px;
  padding: 8px 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  &.active {
    font-weight: 500;
  }

  span:first-child {
    opacity: .7;
    color: white;
    margin-top: 2px;
    margin-right: 4px;
    font-size: 11px;
  }

  &:hover {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
    outline: none;

    & span,
    & a {
      color: white;
      outline: none;
      border: none;
    }
  }

  &:focus,
  &:active {
    background-color: inherit;
    color: $dark_link_text;
  }

  span.selection-none {
    border-bottom: 1px solid white;
    width: 14px;
    margin-right: 5px;
    display: inline-block;
    opacity: .7;
  }

}

.levelFirst {
  padding-left: 28px;
  //text-transform: uppercase;
  span {
    margin-right: 8px !important;
  }
}

.levelSecond {
  padding-left: 28px;
  //text-transform: uppercase;
  border-top: 1px solid $dark_sidebar_line_hor_seconday;
}

.levelSecond-half {
  padding-left: 42px;
}

.levelThird {
  padding-left: 56px;
  border-top: 1px solid lighten(#111, 2%);

  &__favorite {
    padding-left: 48px;
  }

  &:hover {
    .add-to-favorite {
      background: rgb(1, 186, 140);
      background: linear-gradient(90deg, rgba(1, 186, 140, 0) 0%, rgba(1, 186, 140, 1) 15%, rgba(1, 186, 140, 1) 100%);
    }
  }

  &:active {
    .add-to-favorite {
      background: rgb(2, 0, 36);
      background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(35, 40, 47, 1) 15%, rgba(35, 40, 47, 1) 100%);
    }
  }

  span:first-child(1) {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 88%;
  }
}

.longList {
  overflow: auto !important;
}


.level-second, level-third {
  position: relative;

  .icon-collapse {
    color: #fff;
    @include position(absolute, -6px 8px none none);
    font-size: 7px;
    padding: 26px 13px 18px 13px;
    @include rotate(90deg);

    &.open {
      @include position(absolute, 2px 8px none none);
      @include transform(rotate(0deg));
      padding: 14px 16px 14px 21px;
    }
  }

  .list-icon-collapse {
    width: 18px;
    height: 18px;
    padding: 9px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: 8px;
    stroke: #777D85;
    cursor: pointer;

    &.open {
      stroke: #FFF;
      transform: rotate(180deg);
    }

    &:hover {
      stroke: $color_active;
    }
  }
}

.add-to-favorite {
  display: inline-block;
  position: absolute;
  box-sizing: content-box;
  width: 20px;
  padding-right: 8px;
  padding-left: 8px;
  height: 20px;
  right: 10px;
  top: 8px;

  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(35, 40, 47, 1) 15%, rgba(35, 40, 47, 1) 100%);

  svg {
    width: 20px;
    height: 20px;
    display: block;
    fill: none;
    stroke: #FFF;
  }

  &:hover {
    svg {
      stroke: #FFF;
    }
  }

  &.checked {
    svg {
      fill: #FFF;
    }
  }
}

.levelThird__league {
  &:hover {
    .add-to-favorite {
      background: rgb(1, 186, 140);
      background: linear-gradient(90deg, rgba(1, 186, 140, 0) 0%, rgba(1, 186, 140, 1) 15%, rgba(1, 186, 140, 1) 100%);
    }
  }

  &:active {
    .add-to-favorite {
      background: rgb(2, 0, 36);
      background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(35, 40, 47, 1) 15%, rgba(35, 40, 47, 1) 100%);
    }
  }

}

.section {
  &.league_id {
    .section {
      &.favorite_leagues {
        button.h2 {
          padding-left: 28px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.checkbox-field {
  &.checkbox-field--hidetransfered,
  &.checkbox-field--marketvalue {
    &:hover {
      background-color: #01ba8c;
    }
  }

  &.checkbox-field--hidetransfered {
    padding-left: 14px;
    background-color: #23282F;
    border-bottom: 1px solid #171B20;
  }
}

.filters__list_item_row {
  position: relative;

  .list-icon-collapse {
    width: 18px;
    height: 18px;
    padding: 9px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    right: 8px;
    stroke: #777D85;
    cursor: pointer;

    &.open {
      stroke: #FFF;
      transform: rotate(180deg);
    }

    &:hover {
      stroke: $color_active;
    }
  }
}

.filters__list_item {
  font-size: 13px;
  line-height: 20px;
  padding: 8px 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: #fff;

  &.level-1 {
    padding-left: 28px;
    span {
      margin-right: 8px !important;
    }
  }

  &.level-2 {
    padding-left: 28px;
    border-top: 1px solid $dark_sidebar_line_hor_seconday;
  }

  &.level-3 {
    padding-left: 42px;
  }

  &.level-4 {
    padding-left: 56px;
  }

  .pic {
    height: auto;
    display: inline-block;
    position: relative;
    width: 13px;
    margin-bottom: -4px;
    margin-right: 3px;
  }

  &.active {
    font-weight: 500;
  }

  span:first-child {
    opacity: .7;
    color: white;
    margin-top: 2px;
    margin-right: 4px;
    font-size: 11px;
  }

  &:hover  {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
    outline: none;

    & span,
    & a {
      color: white;
      outline: none;
      border: none;
    }

    .add-to-favorite {
      background: rgb(1, 186, 140);
      background: linear-gradient(90deg, rgba(1, 186, 140, 0) 0%, rgba(1, 186, 140, 1) 15%, rgba(1, 186, 140, 1) 100%);
    }
  }

  &:focus,
  &:active {
    background-color: inherit;
    color: $dark_link_text;

    .add-to-favorite {
      background: rgb(2, 0, 36);
      background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(35, 40, 47, 1) 15%, rgba(35, 40, 47, 1) 100%);
    }
  }

  span.selection-none {
    border-bottom: 1px solid white;
    width: 14px;
    margin-right: 5px;
    display: inline-block;
    opacity: .7;
  }
}

.filters__list_item__deselect_all {
  display: block;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 14px;
  padding: 2px 8px;
  border: 1px solid;
  color: #FFF;
  border-radius: 2px;
  cursor: pointer;
  opacity: .75;

  &:hover {
    color: $color_active;
  }
}

.of-cb-unchecked, .of-cb-checked, .of-cb-intermediate, .of-radio-checked, .of-radio-unchecked, .overview-expand {
  width: 18px;
  height: 18px;
  vertical-align: top;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.search-input-container {
  position: relative;

  input {
    padding-left: 54px !important;
  }

  .of-search {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 28px;
    top: 50%;
    margin-top: -10px;
  }
}


h3.list__subtitle {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  color: #FFF;
  padding: 10px 28px 0px;
  margin: 0;
}
