.player-ranking {
  &__player {
    padding: 3px 10px 3px 0;
    border-bottom: 1px solid #119A7E;
    position: relative;

    &.hover {
      color: #FFF;
      font-weight: bold;
      cursor: pointer;
    }

    .hover-backdrop {
      background: rgba(53, 73, 92, .5);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  &__player-content {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    z-index: 3;
  }

  &__player-name {
    flex-grow: 1;
    position: relative;
    padding-left: 23px;
    margin-left: 7px;
    white-space: nowrap;

    .pic {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
    }

    .star-category-selector {
      display: inline-block;
      position: relative;
      margin-left: 13px;
      z-index: 5;

      svg {
        height: 16px;
        width: 25px;
        top: -1px;
        position: relative;
      }

      .list {
        right: auto;
        left: 0;
        top: 18px;
      }
    }

    .favorite-icon {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 6px;
      position: relative;
      top: -1px;
    }
  }

  &__player-index {
    margin-left: 10px;
    font-size: 16px;
    line-height: 20px;
    width: 15px;
  }

  &__player-rating {
    margin-left: 7px;
    color: rgba(0, 0, 0, .6);
    width: 30px;
  }

  &__player-mins {
    margin-left: 13px;
    color: rgba(0, 0, 0, .6);
    width: 35px;
  }

  &__rating-bar {
    position: absolute;
    background: transparent;
    width: 0;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: .45;
  }

  .hover {
    .player-ranking__player-rating {
      color: #FFF;
    }

    .player-ranking__player-mins {
      color: #FFF;
    }
  }
}
