@import '../../../containers/colors';
@import '../../../containers/mixin';

.note-tag-select {
  &.Select.is-searchable.Select--multi {
    .Select-control {
      border: none;
      height: 24px;
    }

    .Select-placeholder {
      color: rgba(17, 50, 69, .4);
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      height: 24px;
    }

    .Select-input {
      width: 160px !important;
      font-size: 12px;
      line-height: 24px;
      height: 24px;

      input {
        margin-top: 0;
        padding-top: 3px;
      }
    }

    .Select-value {
      color: #FFF;
      border: none;
      display: inline-block;
      background: $color_tag_bg;
      font-size: 13px;
      line-height: 18px;
      height: 24px;
      padding: 3px 10px 3px 7px;
      margin: 0 0 5px 5px;
      border-radius: 12px;
      display: inline-flex;

      .Select-value-icon {
        border: none;
        padding: 0px 4px 0px 8px;
        font-size: 20px;
        line-height: 17px;
        display: block;

        &:hover {
          background: transparent;
          color: $blue_light;
        }
      }

      .Select-value-label {
        &:before {
          content: '# ';
          position: relative;
          top: -1px;
        }

        display: block;
        font-size: 13px;
        height: 18px;
        line-height: 18px;
        padding: 0 0 0 5px;
      }
    }

    .Select-arrow-zone {
      display: none;
    }
  }

  &.has-value {
  }
}
