@import '../../../../../containers/colors';

.quick-copy {
  margin: 0 12px;
  display: inline-block;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    stroke: #FFFFFF;
    stroke-width: 2px;
  }

  &:hover {
    svg {
      stroke: #35495C;
      fill: #35495C;
    }
  }
}

#PlayerInfoBox {
  position: absolute;
  color: -webkit-text;
  left: -999999px;
  top: -9999999px;
  pointer-events: none;
}
