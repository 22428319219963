/*
 * Style for compare
 */

@import '../../containers/colors';
@import '../../containers/mixin';

.compare {
  background-color: $light_background_primary;
  position: relative;
  z-index: 1;
  will-change: transform, opacity;
  @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  opacity: 1;
  @include transform(scale(1));

  svg[name="icon-lock"] {
    display: block;
    height: 16px;
    width: 16px;
    margin: 0;
  }

  .compare-legend {
    text-align: right;

    .ColorScale {
      margin-top: 13px;
      position: static;
      display: inline-block;
    }

    .mean-description {
      font-size: 12px;
      font-weight: 400;
      margin-top: 0;
      color: #21252b;
      opacity: .6;
    }
  }

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
  }

  & > div > div {
    overflow-x: hidden !important;
  }

  .content_group.performance_ownstats .h4 {
    margin-top: 10px;
  }

  .card {
    margin-top: 13px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.20), 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    background: #fff;

    .content__data {
      height: 255px;
      color: white;
      border-radius: 4px 4px 0 0;
      background: #35495C;
      border-left: 6px solid;

      &.player {
        height: 323px;
      }

      .content__item_add_actionbar {
        .callAction {
          margin-top: 15px;
          background: #113245;
          position: relative;
          font-weight: 500;

          .icon-compare {
            padding: 0;
            margin-right: 7px;
          }

          &:hover {
            .icon-compare {
              color: $color_active;
            }
          }
        }

        &.is-loading {
          pointer-events: none;

          .btn {
            color: #113245;
            width: 100%;

            .icon-compare {
              color: #113245;
            }
          }

          .btn-loader {
            display: block;
          }
        }

      }
    }

    .row {
      height: auto;
    }

    .content__data > .row:first-child {
      background: #2E3F4F;
    }

    .col-3_5 .pic {
      max-width: 114px;
      height: 114px;
      position: relative;
      min-width: 100%;

      > div {
        @include clip-path(polygon(0 0, 100% 0, 75% 100%, 0 100%));
        clip-path: url('#clipPolygon'); /*Firefox*/
        background-size: cover !important;
      }
    }

    .content__data_header {
      &:after {
        display: block;
        clear: both;
        content: '';
      }

      .content__data_header-container {
        float: right;
      }

      .btn {
        padding: 0;
        display: inline-block;
        vertical-align: top;

        & > div {
          padding-bottom: 13px;
        }
      }

      .icon-more, .icon-close, .icon-edit {
        font-size: 17px;
        color: white;
        padding: 15px 20px 15px 15px;
      }

      .icon-more {
        font-size: 20px;
        margin-top: -1px;
        padding: 15px 15px;
      }

      .icon-edit {
        font-size: 18px;
        margin-top: -1px;
        padding: 15px 15px;
      }
    }

    .data-item .data-text {
      font-weight: bold;
    }

    .content__data_name {
      padding-top: 0;
      margin-bottom: 14px;
      margin-top: -2px;

      & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      .lastname, .firstname {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        display: block;
        line-height: 130%;
        letter-spacing: .5px;
      }

      .firstname {
        padding-right: 5px;
        font-size: 14px;
        font-weight: 400;
      }

      .lastname {
        margin-bottom: 6px;
        font-size: 22px;
      }

      .age {
        padding-right: 5px;
        font-size: 13px;
        line-height: 100%;
        margin: -5px 0 10px;
      }

      .pos, .place {
        padding-right: 5px;
        font-size: 13px;
        line-height: 140%;

      }

      .pos {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin-bottom: 4px;
      }

      .place {
        font-weight: 400;
      }

    }

    .row.firstLine {
      padding-top: 12px;
      border-top: 1px solid rgba(0, 0, 0, .1);
    }

    .row.lastLine {
      margin-top: 11px;
    }

    &.compare_content_card_add {
      border: none;
      background: $dark_actionbar;
      position: relative;


      .icon-dropdown {
        font-size: 7px;
        display: inline-block;
        vertical-align: top;
        margin: 21px 0 3px 26px;
        @include rotate(0deg);

        &.open {
          @include rotate(180deg)

        }
      }

      .leaguesHeader > span,
      .seasonsHeader > span,
      .formationsHeader > span,
      .positionsHeader > span {
        width: 82%;
        padding: 15px 0 5px 10px;
        display: inline-block;
      }

      .content__item_add_content {
        padding-top: 8px;
        height: 255px;
        border-radius: 4px;
        color: #fff;

        .content__seasons {
          padding: 0 20px 0 26px;
          height: 41px;

          .date-range-dropdown {
            display: inline-block;
            white-space: nowrap;
            width: 82%;
            padding: 4px 0 4px 0;

            .dropdown-control {
              border: none;
              padding: 11px 1px 4px 20px;

              &:hover {
                color: inherit;
              }

              &:after {
                content: "\E907";
                font-family: 'scout', 'sans-serif' !important;
                width: auto;
                border: none;
                font-size: 7px;
                left: 0;
              }
            }

            .dropdown-menu {
              left: -6px;
              width: 230px;
              right: auto;
            }
          }
        }

        input {
          @include placeholder {
            color: white;
            opacity: .8;
          }
        }

        .content__items, .content__leagues, .content__seasons, .content__formations, .content__positions {
          font-size: 14px;

          &.deactivate {
            color: #70777f;
            pointer-events: none;

            .dropdown-control {
              color: #70777f;
            }
          }
        }

        .content__items {
          padding: 0;

          input {
            padding: 15px 0 5px 10px;
            width: 82%;
            display: inline-block;
          }

        }
      }

      .content__item_add_actionbar {
        position: absolute;
        bottom: 0;
        border-top: 1px solid lighten($dark_actionbar, 6%);
        width: 100%;
        font-weight: bold;
        display: flex;

        .btn {
          text-align: center;
          width: 100%;
        }

        .last_seasons {
          font-size: 11px;
          width: 40%;
          padding: 8px 10px;
          border-right: 1px solid lighten($dark_actionbar, 6%);
          display: none;
        }

        &.is-loading {
          pointer-events: none;

          .btn {
            color: #113245;
            width: 100%;
          }

          .last_seasons {
            display: none;
          }

          .btn-loader {
            display: block;
          }
        }
      }
    }

    .content__data > .row:first-child {
      height: 173px;
      background: darken($blue, 4%);
      @include border-radius(4px 4px 0px 0px);

      .btn:hover, .btn > div:hover {
        color: lighten(desaturate($blue, 35%), 30%);
      }
    }
  }

  .card_0 {
    .content__data {
      border-color: $light_chart_primary;
    }
  }

  .card_1 {
    .content__data {
      border-color: $light_chart_secondary;
    }
  }

  .card_2 {
    .content__data {
      border-color: $light_chart_tertiary;
    }
  }

  .card_3 {
    .content__data {
      border-color: $light_chart_fourth;
    }
  }

  .chart_group {
    .players-name {
      margin-top: 3px;
      display: flex;
      font-size: 11px;
      color: #35495C;
      text-transform: uppercase;

      .player-name-abr {
        text-align: left;
        flex-grow: 1;

        span {
          display: inline-block;
          margin-left: 7%;
        }

        &:first-child span {
          margin-left: 0;
        }
      }
    }
  }

  .compare-latest-seasons {
    display: block;
    width: 200px;
    position: absolute;
    top: 64px;
    left: 100%;
    border: 1px solid;

    background: none;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    white-space: nowrap;
    color: #FFF;
    background: $color_active;
    margin-left: 20px;
    font-weight: bold;
    transition: .2s ease-out background-color;

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid $color_active;
      top: 10px;
      left: -10px;
      transition: .2s ease-out border-right-color;
    }

    &:hover {
      color: #FFF;
      background: $color_dark_blue_background;

      &:before {
        border-right: 10px solid $color_dark_blue_background;
      }
    }
  }
}


.paywall {

  svg[name="icon-lock"], svg[name="icon-unlock"] {
    fill: $blue;
    height: 18px;
    width: 18px;
    margin: 2px -5px 0px 3px;
  }

  svg[name="icon-unlock"] {
    fill: white;
    margin: 2px 2px 0 0;
  }

  background: #FFF;
  width: 100%;
  position: relative;
  padding: 0 20px 20px;
  text-align: center;


  &:before {
    display: none;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    padding-top: 30px;
    color: $black_light;
    position: relative;
  }

  p {
    font-size: 14px;
    opacity: .6;
    color: #000;
  }

  a {
    margin-top: 20px;
    margin-bottom: 60px;
    display: inline-block;
    background: $blue_auth_container;

    line-height: 50px;
    width: 260px;
    padding: 0;
    text-align: center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .2px;
    cursor: pointer;

    &:hover {
      color: #fff !important;
      background: $black_blue;
    }
  }
}
