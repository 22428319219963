.competition-level-hint {
  background: rgba(#fff, .97);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24);
  padding: 16px;
  display: flex;
  //max-width: 480px;

  .hint-icon {
    margin-right: 18px;
    width: 34px;

    svg {
      width: 34px;
      height: 34px;
    }

    &.higher {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .hint-content {
    .hint-description-text {
      font-size: 15px;
      line-height: 24px;
      color: #111;
    }

    .hint-secondary-text {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #636464;
      margin-top: 10px;
    }

    b {
      font-weight: 500;
    }

    .pic {
      width: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
    }
  }
}
