@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.tooltip-player-container {
  position: fixed;
  transform: translateY(-50%);
  z-index: 100;

  &.left {
    padding-right: 20px;
  }

  &.right {
    padding-left: 20px;
  }
}

.tooltip.player-popup {
  display: block;
  position: relative;
}
