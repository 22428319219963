/*
 * Style for details, header
 */

@import '../../../containers/colors';
@import '../../../containers/mixin';


.details .bar-container {
  background-color: $primary-color;
  color: white;

  .header {
    height: 114px;
    width: 99%;

    .header__profile_key, .header__profile_detail, .header__actionButtons {
      height: 100%;
      position: relative;
    }

    .header__profile_key {
      .col-3 .pic {
        max-width: 114px;
        overflow: hidden;
        height: 114px;
        position: relative;

        > div {
          @include clip-path(polygon(0 0, 99% 0, 75% 100%, 0 100%));
          clip-path: url('#clipPolygon'); /*Firefox*/
          opacity: 1 !important;
          background-size: cover !important;
          background-color: darken($primary-color, 5%);
        }
      }

      .header__profile_key_name {
        position: absolute;

        bottom: 0px;
        right: 0px;
        padding-left: 6px;

        @media (min-width: $mq-tablet-portrait + 1) {
          bottom: 15px;
        }

        .firstname {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: .6px;
        }

        .lastname {
          text-transform: uppercase;
          font-size: 26px;
          font-weight: 600;
          line-height: 115%;
          padding-top: 0px;
          padding-bottom: 1px;
          letter-spacing: 1px;
        }

        .team {
          font-size: 13px;
          line-height: 140%;
        }

        a.team {
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;

          &:hover {
            color: rgba(#fff, .8);
          }
        }
      }
    }

    .header__profile_detail_container, .header__actionButtons .row {
      @include position(absolute, none 0 9px);
      height: auto;

      .data-item {
        margin-right: 6%;
        width: auto;
        text-overflow: ellipsis;

        &:last-child {
          margin-right: 0;
        }

        .data-text {
          overflow: visible;
        }
      }

    }

    .header__profile_detail_container {

      .break {
        flex-basis: 100%;
        height: 0;
      }

      @media (min-width: 979px) {
        .break {
          display: none;
        }
      }

      .data-item {
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;

        .pic {
          margin-bottom: -2px;
          margin-right: 4px;
        }

        .data-text {
          overflow: hidden;
        }
      }

    }

    .data-item {
      &.overflow-visible {
        overflow: visible;

        .data-text {
          overflow: visible;
        }
      }
    }


    .header__actionButtons_container {
      z-index: 1;


      &.primary {
        @include position(absolute, 0 0 none none);

        .icon-compare:before {
          font-size: 18px;
        }

        .icon-search:before {
          font-size: 19px;
        }

        .icon-close {
          font-size: 18px;
        }

        .icon-search {
          padding-right: 23px;
        }

        .details-favorite-action,
        .details-transfermarkt-action {
          padding: 13px 18px;
          display: inline-block;
          vertical-align: top;

          .icon-favorite {
            stroke: #FFF;

            &.active {
              stroke: #FFF;
              fill: #FFF;
            }
          }

          &:hover {
            .icon-favorite {
              stroke: $blue;

              &.active {
                stroke: $blue !important;
                fill: $blue !important;
              }
            }
          }
        }
      }

      &.season, &.league, &.formation, &.position {
        .data-item {
          margin: 0;
          width: 100%;
        }
      }

      &.position {
        .position_group {
          font-weight: bold;
        }

        .position_detail {
          padding-left: 24px !important;
        }
      }
    }
  }

  .btn_star {
    margin: 0 12px;
    display: inline-block;

    &:hover .icon-favorite {
      stroke: $blue !important;
      color: $blue !important;
    }

    .label {
      svg {
        width: 27px;
        height: 20px;

      }
    }
  }
}
