@import '../../../../containers/colors';
@import '../../../../containers/mixin';

.competition-level-chart {
  position: relative;
  padding: 0 0 76px 76px;

  .axis-label {
    color: #4D4D4D;
    font-size: 18px;
    position: absolute;
    line-height: 24px;
    white-space: nowrap;

    &.axis-label--x {
      right: 0;
      left: 76px;
      bottom: 0;
      text-align: center;
    }

    &.axis-label--y {
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      margin-top: -38px;
      transform: rotate(-90deg);

      .axis-label-text {
        position: absolute;
        transform: translate(-50%, -50%);
      }
    }
  }

  .competition-level-svg {
    overflow: visible !important;
    position: relative;

    .grid {
      line {
        stroke: #E9E9E9;
        stroke-width: 1px;
        stroke-dasharray: 3px;
      }
    }

    .axis {
      path {
        stroke: #E9E9E9;
      }

      line {
        stroke: transparent;
      }

      text {
        fill: #9E9E9E;
      }
    }

    .point {
      circle {
        stroke-width: 1px;
        fill: transparent;

        &.green {
          stroke-width: 2px;
          stroke: $neongreen;
        }

        &.white {
          stroke: white;
        }

        &.black {
          stroke: #111;
        }
      }

      image {
        clip-path: circle(12px at center);
      }
    }
  }
}
