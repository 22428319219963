@import '../../../containers/mixin';
@import '../../../containers/colors';

.team-compare {
  .bar-title {
    margin-bottom: 11px;
  }

  .bar-align {
    width: 280px;
    margin: 0 auto;
  }

  .row-bars {
    margin-top: 20px;

    & + .row-bars {
      margin-top: 33px;
    }
  }

  .BarChart__bar_bardesc_1 {
    color: $team_home;
  }

  .BarChart__bar_rect_home_season {
    fill: rgba($team_home, .5);
  }

  .BarChart__bar_rect_home_match {
    fill: $team_home;
  }

  .BarChart__bar_rect_away_match {
    fill: $team_away;
  }

  .BarChart__bar_rect_away_season {
    fill: rgba($team_away, .5);
  }

  .abk-home,
  .abk-away {
    font-size: 11px;
    color: #0F5983;
    display: inline-block;
    width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 1280px) {
      width: 59px;
    }
  }

  .abk-home {
    margin-left: 42px;

    @media (max-width: 1280px) {
      margin-left: 34px;
    }
  }

  .abk-away {
    margin-left: 45px;

    @media (max-width: 1280px) {
      margin-left: 34px;
    }
  }

  .chart-legend {
    margin-top: 22px;
  }
}
