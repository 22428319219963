/*
 * Style for overview, legend under scatterplot
 */
@import '../../../containers/colors';
@import '../../../containers/mixin';

.legend__list {
  position: absolute;
  left: 0px;
  top: -356px;

  .ReactVirtualized__List {
    background-color: white;
    @include box-shadow(0px, 0px, 6px, rgba(0, 0, 0, .3));
    @include border-radius(4px);
    position: relative;
    z-index: 2;

    .legend__list_item {
      font-size: 14px;
      padding: 10px 13px;
      color: $dark_actionbar;
      border-bottom: 1px solid lighten($blue_grey_light, 0%);

      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        color: white;
        outline: none;
        background-color: $primary-color;
      }
    }
  }
}

.overview .dataView .legend {
  position: relative;
  height: 50px;
  font-size: 13px;
  color: $blue_grey;
  text-transform: uppercase;

  .icon-dropdown {
    font-size: 6px;
    vertical-align: top;
    display: inline-block;
    margin: 6px 4px 0px 0px;
    @include rotate(0deg);

    &.open {
      @include rotate(180deg)
    }
  }

  .legendHeader {
    display: inline-block;

    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }

  .content-legend-inline {
    display: inline-block;
    vertical-align: top;
    margin: 16px 0 0 46px;
    position: relative;

    &:first-child {
      margin-left: 26px;
    }
  }

  .content_legendLeft {
    position: absolute;
    left: -58px;
    top: -100px;
    line-height: 20px;

    .legendYHeader {
      width: 150px;
    }

    @include rotate(-90deg);

    .legend__list.show {
      top: -73px;
      left: 99px;

      .ReactVirtualized__List {
        @include rotate(90deg)
      }
    }
  }

  .legend_desc, .legend_domain, .legend_circle, .legend_bar {
    display: inline-block;
  }

  .legend_desc {
    margin-left: 3px;
  }

  .legend_domain {
    margin: 0px 4px;
    color: #8092A5;
  }

  .legend_bar {
    height: 8px;
    width: 60px;
    background: $blue_grey;
    @include border-radius(1px);
  }

  .legend_circle {
    height: 8px;
    width: 12px;
    @include border-radius(30px 30px 4px 4px);
    background: #8092A5;;
  }

  .content_legendX, .content_legendCol, .content_legendRad {
    position: absolute;
    top: 16px;
  }

  .content_legendX {
    left: 60px;
    top: 16px;
  }

  .content_legendCol {
    right: 210px;
  }

  .content_legendRad {
    right: 10px;
  }
}

