@import '../../containers/colors';
@import '../../containers/mixin';

.shadow-team {
  .nav-container-white {
    /*height: 43px;*/
    background: lighten($dark_background_primary, 5%);
    position: relative;
    z-index: 2;

    .shadow-container {
      padding-bottom: 0 !important;
    }

    a, span {
      padding: 12px 10px 9px;
      margin-right: 0;
      color: white;
      cursor: pointer;
      height: 44px;
      overflow: hidden;

      .create-icon {
        width: 11px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin-right: 6px;
      }

      .close-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        cursor: pointer;

        &:hover {
          color: $neongreen;
        }

        svg {
          width: 10px;
          height: 10px;
          position: relative;
        }

      }

      .more-icon {
        width: 6px;
        height: 3px;
        transform: rotateX(180deg);
        transform-origin: 50% 50%;
      }

      &.open {
        .more-icon {
          transform: rotateX(0deg);
        }
      }

      &.more {
        position: relative;
        overflow: visible;
      }

      &.active {
        color: darken($primary-color, 1%);
        font-weight: 500;
        border-bottom: 3px solid $primary-color;
        padding: 12px 10px 8px;
        cursor: default;
      }

      &:hover {
        color: $primary-color;
        //font-weight: 500;
      }
    }

    .dropdown {
      height: auto;
      width: 100%;
      position: absolute;
      background: lighten($dark_background_primary, 5%);
      padding: 0;
      top: 100%;
      left: 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      & > a, & > span {
        text-align: left;
        width: 100%;
        padding: 12px 10px 9px 20px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          border-bottom-color: transparent;
          background: $blue;
          color: #FFF;

          .close-icon {
            svg {
              color: #FFF;
              fill: #FFF;
            }
          }
        }
      }
    }
  }

  .heading {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 200;
    color: $blue;
    margin: 0 0 25px;

    &.lined {
      position: relative;

      span {
        display: inline-block;
        padding-right: 25px;
        background: #f5f6f7;
        position: relative;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #DFE3E6;
        left: 0;
        top: 50%;
      }
    }
  }


  &__table {
    color: $blue;
    width: 100%;
    table-layout: fixed;
    font-size: 14px;
    line-height: 1.2;
    // max-width: 765px;

    .team-author {
      font-size: 11px;
      line-height: 14px;
    }

    th, td {
      padding: 10px 0px 10px 30px;
    }

    th {
      padding-top: 0;
      text-align: left;
      font-size: 13px;
      font-weight: normal;
      text-transform: uppercase;
      border-bottom: 1px solid $squad_thead_border;
      color: rgba(53, 73, 92, 0.8);

      .sort {
        width: 6px;
        height: 3px;

        transform: rotate(180deg);

        &.sort-asc {
          transform: rotate(0deg);
        }
      }

      &:last-child {
        width: 41%;
      }
    }

    a {
      color: $squad_link_hover;

      &:hover {
        color: $primary-color;
      }
    }

    tr:nth-child(odd) {
      td {
        background: rgba(196, 211, 235, .2);
      }
    }

    .col__date, .col__formation {
      width: 120px;
    }

    .col__controls {
      width: 95px;
    }

    .shadow-team__table-controls {
      min-width: 130px;
      display: block;
    }

    .control-btn {
      display: inline-block;
      margin-left: 20px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      color: $blue_neon;

      &:nth-child(1) {
        margin-left: 0;
      }

      svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        color: $primary-color;
      }
    }

    input {
      width: calc(100% + 10px);
      height: 30px;
      margin: -5px;
      padding: 4px;
      border: 1px solid $grey;
      background: #fff;
      color: $black_blue;

      &.invalid {
        color: $red;

        &::-webkit-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &::-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }

        &:-moz-placeholder {
          color: $red;
          transition: opacity .2s;
          opacity: 1;
        }
      }

      &::-webkit-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #8B8282;
        transition: opacity .2s;
        opacity: 1;
      }

      &:focus {
        &::-webkit-input-placeholder {
          opacity: 0;
        }

        &::-moz-placeholder {
          opacity: 0;
        }

        &:-ms-input-placeholder {
          opacity: 0;
        }

        &:-moz-placeholder {
          opacity: 0;
        }
      }
    }

    blue-btn,
    .orange-btn,
    .green-btn,
    .red-btn {
      font-weight: 400;
    }

    .blue-btn {
      color: $blue_neon;
      margin-right: 20px;
    }

    .orange-btn {
      color: $orange;
      margin-right: 20px;
    }

    .green-btn {
      color: $green;
    }

    .red-btn {
      color: $red;
      margin-left: 20px;
    }

    .styled-react-select {
      &.Select--single {
        .Select-control {
          height: auto;
          background: #FFF;
          border: 1px solid $grey !important;

          input {
            border-bottom: none;
            padding-top: 3px;
          }

          .Select-placeholder {
            line-height: 30px;
            left: 4px;
          }

          .Select-value {
            line-height: 30px;
            height: 28px;
            left: 0;

            .Select-value-label {
              padding: 0;
              line-height: 28px;
              height: 28px;
            }
          }

          .Select-input {
            height: 28px;
            line-height: 28px;
            padding-left: 4px;

            input {
              padding-top: 0;
              padding-left: 0;
              background: transparent;
            }
          }
        }
      }

      &.has-value.Select--single, &.is-focused.Select--single, &.has-value.is-pseudo-focused.Select--single {
        .Select-control {
          .Select-value {
            .Select-value-label {
              padding: 0 4px !important;
            }
          }
        }
      }

      &.invalid {
        &.Select--single {
          .Select-control {
            .Select-placeholder {
              color: $red !important;
            }

            //border-color: $red !important;
          }
        }
      }
    }
  }

  &__edit-row-controls {
    text-align: right;
  }

  .create-team {
    cursor: pointer;
    color: $squad_link_hover;
    font-size: 15px;
    line-height: 1.2;
    display: inline-block;
    margin-top: 25px;

    .create-icon {
      width: 11px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-right: 6px;
      margin-left: 6px;

    }

    &:hover {
      color: $primary-color;
    }
  }

  .group-check {
    margin: 0 0 25px;
    color: $squad_link_hover;
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;

    .checkbox {
      display: inline-block;
      vertical-align: middle;
      width: 22px;
      height: 22px;
      border: 1px solid $squad_link_hover;
      margin-right: 8px;
      position: relative;

      .icon-check {
        position: absolute;
        top: 4px;
        left: 2px;
        font-size: 12px;
        color: $squad_link_hover;
      }
    }

    label {
      cursor: pointer;
    }

    label.checked {
      opacity: 1;
    }
  }

}
