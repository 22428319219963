@import '../../../../containers/mixin';
@import '../../../../containers/colors';

.player-ranking {

  h4 {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-transform: uppercase;
  }

  &__navigation {
    margin-top: 16px;
    height: 34px;
    overflow: hidden;
    position: relative;
    z-index: 3;
  }

  &__navigation-list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 3px 3px 0;
    border: 1px solid #35495C;
    border-radius: 6px;
    height: 34px;
    box-sizing: border-box;
    align-items: stretch;
    flex-grow: 10;
  }

  &__navigation-item {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: #35495C;
    border-radius: 6px;
    margin: 3px 0 0 3px;
    padding: 4px;
    flex-grow: 10;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: #FFF;
      background: rgba(53, 73, 92, .5);
    }

    &.active {
      cursor: default;
      background: #35495C;
      color: #FFF;
    }

    &.inactive {
      opacity: .5;
      pointer-events: none;
    }
  }

  &__heading {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;

    div {
      width: 48px;
      font-size: 13px;
      line-height: 16px;

      &.h_rank {
        width: 30px;
        margin-right: 16px;
      }

      &.h_color {
        width: 45px;
        text-transform: uppercase;
      }
    }
  }

  &__list-container {
    margin-top: 5px;
    position: relative;
    z-index: 2;
  }

  &__list-legend {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;

    .legend {
      display: inline-block;
      margin-right: 10px;
    }

    .second_score {
      display: inline-block;
      vertical-align: top;
    }

    .second_score + .color {
      margin-left: 10px;
    }

    .color + .second_score {
      margin-left: 10px;
    }

    .color {
      display: inline-block;
      width: 10px;
      height: 16px;
      vertical-align: top;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__empty-message {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-top: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .6);
  }
}

.ranking-nav-tooltip {
  max-width: 340px;
}
