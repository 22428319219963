@import '../../../containers/mixin';
@import '../../../containers/colors';

.match-lineups {
  padding-top: 6px;

  @media (min-width: $mq-tablet-portrait + 1) {
    padding-top: 40px;
  }

  .h4.team-name {
    margin-top: 10px;
    text-transform: none;
    position: relative;

    .pic {
      @include position(absolute, none none none 0);
      width: 20px;
      display: inline-block;
      vertical-align: middle;
    }

    .description {
      padding-left: 28px;
      font-weight: 400;
      font-size: 16px;
    }

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 28px;
    }

    &.right {
      text-align: right;

      .pic {
        @include position(absolute, none 0 none auto);
      }

      .description {
        padding-right: 28px;
      }

      span {
        padding: 0 28px 0 0;
      }
    }
  }

  .subsitutions {
    margin-top: 15px;

    .substitution {
      position: relative;
      padding-left: 20px;

      @media (min-width: $mq-tablet-portrait + 1) {
        padding-left: 30px;
      }

      &.substitution-out {
        &:before {
          content: '';
          @include position(absolute, 50% none none 0);
          margin-top: -5px;
          border: 6px solid transparent;
          border-top: 9px solid #BB5700;
          border-bottom: none;
        }
      }

      &.substitution-in {
        &:before {
          content: '';
          @include position(absolute, 50% none none 0);
          margin-top: -5px;
          border: 6px solid transparent;
          border-bottom: 9px solid #45BB00;
          border-top: none;
        }
      }

      & + .substitution {
        margin-top: 24px;
      }
    }

    .jersey-number {
      position: relative;
      padding-left: 36px;
      font-size: 12px;
      display: inline-block;

      @media (min-width: $mq-tablet-portrait + 1) {
        font-size: 14px;
        padding-left: 39px;
      }

      .number {
        @include position(absolute, 50% none none 0);
        margin-top: -15px;
        width: 30px;
        height: 30px;
        display: inline-block;
        color: #fff;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        font-size: 13px;
        border: 1px solid;
      }

      &.home {
        .number {
          background: rgba($team_home, .9);
          border-color: $team_home;
        }
      }

      &.away {
        .number {
          background: rgba($team_away, .9);
          border-color: $team_away;
        }
      }

      &:hover {
        &.home {
          .number {
            background: $team_home;
          }
        }

        &.away {
          .number {
            background: $team_away;
          }
        }
      }
    }

  }

  .bench-title,
  .subsitutions-title {
    text-transform: uppercase;
    color: #575757;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 12px;
  }

  .team-positions {
    margin-bottom: 20px;

    @media (min-width: $mq-tablet-portrait + 1) {
      margin-top: 40px;
    }

    .canvas {
      display: inline-block;
      margin: 0 -3px;
    }
  }

  .positions-left {
    .team-positions {
      text-align: right;
    }
  }

  .lineup-col {
    position: relative;

    &:first-child {
      padding-right: 5%;
    }

    & + .lineup-col {
      padding-left: 5%;
    }
  }
}
