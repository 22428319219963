@import '../../containers/colors';
@import '../../containers/mixin';

/*
 * Style for slider
 */

.slider-value {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  padding-top: 3px;
}

.slider-value:nth-child(1) {
  text-align: right;
  padding-right: 5px;
}

.slider-value:nth-child(3) {
  padding-left: 5px;
}

.horizontal-slider {
  display: inline-block;
  // max-width: 500px;
  height: 27px;

}

.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}

.handle {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  border-radius: 50%;
}

.handle.active {
  background-color: grey;
}

.bar {
  position: relative;
  background: #ddd;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  -khtml-border-radius: 30px;
  border-radius: 30px;
}

.bar.bar-1 {
  background: #f00;
}

.bar.bar-2 {
  background: #ddd;
}

.horizontal-slider .bar {
  top: 10px;
  height: 5px;
}

.horizontal-slider .handle {
  top: 0px;
  width: 26px;
  height: 26px;
  line-height: 26px;
}

#horizontal-0,
#horizontal-1, #horizontal-2, #horizontal-3,
#vertical {
  margin: 20px 10px;
}

.slider-on-white {
  .slider {
    position: relative;
    padding-top: 4px;
    padding-left: 20px;

    .horizontal-slider {
      display: block;
      height: 31px;
    }

    .bar {
      // height: 1px;
      background: rgba($blue_neon, .4);
      // top: 13px;

      &.bar-1 {
        background: rgba($blue_neon, 1);
      }
    }

    .handle {
      background: rgba($blue_neon, 1);
      border: 1px solid $light_background_primary;
    }

    .slider-value {
      position: absolute;
      top: 1px;
      padding-top: 0;
      line-height: 32px;
      color: $blue;

      &:first-child {
        left: -20px;
      }

      &:last-child {
        right: -40px;
      }
    }
  }

  label {
    font-weight: bold;
    line-height: 100%;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.5;
    display: block;
    margin: 3px 0 0;
    letter-spacing: .5px;
  }
}
