@import '../containers/colors';
@import '../containers/mixin';

.account {
  background-color: $light_background_primary;
  position: relative;
  z-index: 1;
  will-change: transform, opacity;
  @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  opacity: 1;
  @include transform(scale(1));

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .header-container {
    background: $primary-color;
    color: #fff;

    .account-logo {
      padding: 15px 0;

      svg {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        display: inline-block;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        margin: 0px 0px -2px 0px;
        font-weight: 300;
      }
    }

    .account-logo svg {
      fill: none;
      stroke: currentColor;
    }

    .pull-right {
      float: right;
    }

    .btn {
      padding: 2px 0;
    }
  }

  .nav-container {
    height: 43px;
    background: $dark_background_primary;

    a {
      padding: 13px 10px 8px;

      &.active {
        color: $primary-color;
        font-weight: 500;
        border-bottom: 3px solid $primary-color;
        padding: 13px 10px 7px;
      }

      &:hover {
        color: $primary-color;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;
    width: 90%;
  }

  .content-container {
    .container {
      padding: 40px 0;
    }
  }
}
