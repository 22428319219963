/*
 * Style for search
 */

@import '../../containers/colors';
@import '../../containers/mixin';

.search {
  background-color: $light_background_primary;
  @include position(fixed, 0 0 none);

  &.mobile {
    width: 100%;
    right: auto;
    /*overflow:hidden;*/
  }

  margin-top: 0;
  z-index: 1;
  will-change: transform, opacity;

  .modal-enter & {
    opacity: 0;
    @include transform(scale(.95));
  }

  .modal-enter-active & {
    opacity: 1;
    @include transform(scale(1));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  .modal-leave & {
    opacity: 1;
    @include transform(scale(1));
  }

  .modal-leave-active & {
    opacity: 0;
    @include transform(scale(.95));
    @include transition(transform 500ms ease-out, opacity 500ms ease-out);
  }

  @media (min-width: $mq-tablet-portrait + 1) {
    z-index: 1;
  }

  .search-logo {
    display: none;
    @include position(absolute, -180px 0 none);
    text-align: center;

    svg {
      width: 90px;
      height: 90px;
      color: $neongreen;
    }

    .logo-text {
      font-weight: 700;
      color: #fff;
      font-size: 28px;
      letter-spacing: -0.5%;
      margin-top: 16px;
      line-height: 36px;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  small {
    font-size: 14px;
  }

  .pic {
    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  .col-3.text-right {
    .pic {
      margin: 0px 0px 0px 10px;
      float: right;
    }
  }

  .header-container {
    background-color: $primary-color;
    color: white;
    height: 50px;
    @include box-shadow(0px, 2px, 5px, rgba(0, 0, 0, 0.1));
    margin: 0;
    @include transition(margin .5s);

    @media (min-width: $mq-tablet-portrait + 1) {
      height: 70px;
      @include box-shadow(0px, 0px, 0px, rgba(0, 0, 0, 0))
    }

    .container {
      overflow: hidden;
    }

    .icon-search {
      float: left;
      font-size: 20px;
      padding: 14px;

      @media (min-width: $mq-tablet-portrait + 1) {
        padding: 24px 15px 24px 10px;
      }
    }

    input {
      width: calc(100% - 95px);
      height: 50px;
      color: white;
      font-size: 17px;
      font-weight: 400;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 75%;
        height: 70px;
      }

      @include placeholder {
        color: white;
        opacity: .7;
      }
    }

    .icon-close {
      float: right;
      font-size: 12px;
      padding: 19px;

      @media (min-width: $mq-tablet-portrait + 1) {
        font-size: 15px;
        padding: 24px 15px 25px 10px;
      }
    }

    .header__actionButtons {
      position: relative;
      z-index: 2;
      width: 50px;
      float: right;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 18%;
      }
    }
  }

  .nav-container {
    height: 42px;
    background: $blue;
    @include box-shadow(0px, 2px, 5px, rgba(0, 0, 0, 0.1));

    .row {
      margin: 0 auto;

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 95%;
      }
    }

    a {
      padding: 13px 3px 8px;
      cursor: pointer;

      &:hover {
        color: $neongreen;
      }

      &.active {
        color: $neongreen;
        font-weight: 500;
        border-bottom: 3px solid $neongreen;
        padding: 13px 10px 7px;
      }
    }
  }

  .content-container {
    width: 100%;
    margin: 0;
  }

  .search-link {
    color: $light_link_text;
    display: block;
    height: 100%;
    border-bottom: 1px solid $blue_grey_light;
    font-size: 14px;
    white-space: nowrap;


    @media (max-width: $mq-tablet-portrait - 1) {
      font-size: 15px;
    }

    .name {
      width: 30%;
      display: inline-block;
    }

    .info {
      width: 50%;
      color: $blue_light;

      .pic {
        margin-right: 10px;
      }
    }

    .row {
      margin: 0 auto;
      padding: 11px 7px;
      width: 97%;

      .col-6 {
        min-height: 1px;
      }

      @media (min-width: $mq-tablet-portrait + 1) {
        width: 95%;

      }
    }

    span[class^="col-"], span[class*=" col-"] {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .loading-message {
    text-align: center;
    color: $light_link_text;
    font-size: 14px;
    line-height: 46px;
  }

  .highlight {
    .search-link {
      background-color: $primary-color !important;
      cursor: pointer;

      & > * {
        color: white !important;
      }
    }
  }

  .word_highlight {
    background-color: lighten($primary-color, 5%);
    font-weight: normal;
  }

  .search-not-found {
    @include position(absolute, 30% 0 none);
    text-align: center;
  }

  &.search--hidden {
    background: none;

    .nav-container {
      background: none;
      box-shadow: none;
    }

    .nav-container,
    .content-container {
      display: none;
    }
  }

  &.search--overview {
    position: relative;

    &.search--center {
      @include position(relative, 50px none none);
      @include transform(translateY(35vh));

      .header-container {
        margin: 0 20px;

        input {
          width: calc(100% - 45px);
        }
      }

      .search-logo {
        display: block;
      }
    }
  }
}

