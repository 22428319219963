/*
 * Colors
 */

// highlight and branding color
$neongreen: #01BA8C;
$primary-color: $neongreen;
$epi_color: #119A7E;

// plus and minus color for charts
$green: #018766;
$red: #ff5959;

// plus and minus color from polar scale
$green_polar: #187141;
$red_polar: #7A3B43;

// radar charts
$blue_neon: #3f809c;
$lila: #7F6788;
$orange: #e3753a;
$yellow: #e3ab3a;
$green_palette: #3f9c6f;
$purple: #88007F;


// general interface
$black_blue: #1B3345;
$black_blue_light: #0B2839;
$black_blue_dark: #113245;

$blue: #35495C;
$blue_light: #374B5F;
$blue_lightest: #314455;
$blue_light2: #476381;
$blue_auth_container: #2B3F52;

$blue_grey: #97ADC4;
$blue_grey_light: #c8d7e3;
$blue_grey_lightest: #E6ECF1;

$grey: #dfe3e6;
$grey_light: #f5f6f7;
$grey_dark: #C9C9C9;
$grey_darkness: #A4A4A4;
$grey_darkness_ever: #54605b;

$anthrazit: #2D343C;
$anthrazit_dark: #23282F;

$black: #111;
$black_light: #21252b;

$dark_background_primary: $blue;
$dark_background_secondary: $blue_light;
$dark_background_tertiary: $blue_lightest;

$dark_sidebar_primary: $anthrazit;
$dark_sidebar_secondary: $anthrazit_dark;

$dark_sidebar_line_hor_primary: $black_light;
$dark_sidebar_line_hor_seconday: #171B20;

$dark_link_text: #F2F2F4;
$dark_h1: $blue_grey_lightest;
$dark_h2: $blue_grey;
$dark_actionbar: $black_blue_dark;


// overlay
$light_background_primary: #f8f9fa; //#;EEF0F2

$light_headline_primary: $blue;
$light_subheadline_primary: $blue_light;

$light_link_text: $black_blue_light;

$light_divider: $grey;
$light_mean: $grey_dark;
$light_benchmark: #0c5b48;

$light_chart_mean: darken($light_mean, 20%);
$light_chart_primary: #1D9879; //#41ab8c;// #16A085;//#1D9879; //$neongreen;
$light_chart_secondary: #9f51c0; //#8a5ecf;//#821CA6;//#8E44AD;//#285999;//#285A9A;//$yellow;
$light_chart_tertiary: #f5ac3a; //#f6aa56;//#FAA822;//#F39C12;//#DB2A49;//#B25446;//$orange;
$light_chart_fourth: #2980B9; //#F05B75;//#2980B9 ;//#EAA22D;//#E28D32;//$blue_neon;
$light_chart_fifth: $lila;

$overview_navigation: #181C20;
$squad_tr_color: rgba(#C4D3E1, .4);
$squad_thead_border: #95A9BC;
$squad_link_hover: #0F5983;


//notes
$color_light_border: #E1E8EE;
$color_meta_text: rgba(53, 73, 92, .6);
$color_active_bg_blue: #D8DEE3;
$color_active_light_blue: #F5F8FA;
$color_link: #0F5983;
$color_active: #5CBB8D;
$color_tag_bg: #A4B2BF;
$color_text_light: #63717F;
$time_transition: .15s;
$time_transition_long: .2s;
$color_content_title: #113245;
$color_content_text: #222222;
$color_placeholder: rgba(17, 50, 69, .2);
$color_dark_blue_background: #113245;


// match
$team_home: $light_chart_fourth;
$team_away: $light_chart_tertiary;



