@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.note-form {
  margin-bottom: 35px;

  textarea {
    display: block;
    width: 100%;
    resize: none;
    padding-left: 0;
    border-left: 0px solid transparent;
    transition: border $time_transition_long ease-out, padding $time_transition_long ease-out;

    &::placeholder {
      color: rgba(17, 50, 69, .3);
      transform: translateX(0) translateZ(0);
      transition: color $time_transition_long ease-out, transform $time_transition_long ease-out;
    }

    &:focus {
      border-left: 2px solid $color_active;
      padding-left: 10px;

      &::placeholder {
        transform: translateX(100px) translateZ(0);
        color: transparent;
      }
    }
  }

  &__done {
    transition: color $time_transition ease-out;
    color: #fff;
    font-size: 14px;
    // line-height: 21px;
    // padding: 5px 10px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 2px;
    line-height: 36px;
    width: 120px;
    padding: 0;
    background: #53B78F;
    text-align: center;
    float: right;

    &:hover {
      color: #fff;
      background: $primary-color;
    }
  }

  &__player {
    padding-bottom: 5px;
    border-bottom: 1px solid $color_light_border;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .note-player-select {
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    color: $color_content_title;
    font-weight: 500;
    min-height: 32px;
    width: 425px !important;
    @media (max-width: $mq-tablet-landscape) {
      width: 360px !important;
    }

    display: inline-block !important;
  }

  &__text {
    font-size: 15px;
    line-height: 22px;
    margin-top: 2px;
  }

  &__tags {
    margin-top: 15px;
    border-top: 1px solid $color_light_border;
    padding-top: 15px;

    .Select-value {
      white-space: nowrap;
    }
  }

}
