@import '../../../containers/colors';
@import '../../../containers/mixin';

.heatmap {
  font-size: 0;
  padding: 10px 0 20px 20px;
  @include mobile-landscape-and-below {
    padding: 0 10px 0;
  }

  .group {
    position: relative;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    @include transition(height 1s ease-in-out, width 1s ease-in-out, left 1s ease-in-out, top 1s ease-in-out);

    .group-name {
      font-size: 13px;
      letter-spacing: .05em;
      color: #B2C8DD;
      line-height: 27px;
      padding: 10px 0 2px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      @include mobile-landscape-and-below {
        padding: 5px 0 2px;
      }
    }

    .group-list {
      display: flex;
      flex-wrap: wrap;

      .team {
        font-size: 13px;
        color: #fff;
        line-height: 120%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        @include transition(background-color 1s);

        &:before {
          display: inline-block;
          content: '';
          padding-top: 61.8%;
          background: black;
          opacity: .1;
          width: 0;
          vertical-align: middle;
          overflow: hidden;
        }


        .align {
          width: 100%;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          display: inline-block;
        }

        .team-name {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 5px;
        }

        .team-value {
          padding: 0 5px;
        }

        &.active {
          background-color: #fff !important;
          color: $green;
          @include transition(background-color .2s);
        }

        .pic {
          height: auto;
          width: 14%;
          display: inline-block;
          position: relative;
          margin-bottom: 0;
        }


        &.detail {
          .team-name {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}
