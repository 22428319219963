@import '../../../../../containers/colors';
@import '../../../../../containers/mixin';

.notes-desktop-container {
  .notes-aside {
    width: 30%;
    max-width: 321px;
    min-width: 260px;
    flex-grow: 1;
    flex-shrink: 1;
    border-right: 1px solid $color_light_border;
    border-left: 1px solid $color_light_border;
    padding-top: 15px;

    &__wrapper {
      overflow: hidden;
      padding-bottom: 35px;
    }

    .notes-add {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid $color_light_border;

      .search-field-container {
        position: relative;
        flex-grow: 1;

        .icon-search {
          position: absolute;
          left: 10px;
          color: $blue;
          font-size: 14px;
          line-height: 36px;
          top: 0;
          transition: color $time_transition ease-out;

          &:hover {
            color: $color_active;
            cursor: pointer;
          }
        }
      }

      .search-field {
        border: 1px solid $color_light_border;
        color: $blue;
        font-size: 14px;
        line-height: 36px;
        height: 36px;
        border-radius: 4px;
        padding: 0 15px 0 37px;
        transition: border-color $time_transition ease-out;
        width: 100%;
        display: block;

        &::placeholder {
          color: $color_meta_text;
          transform: translateX(0) translateZ(0);
          transition: color $time_transition ease-out, transform .2s ease-out;
        }

        &:focus {
          border: 1px solid $color_active;

          &::placeholder {
            transform: translateX(50px) translateZ(0);
            color: transparent;
          }
        }
      }

      .note-add-button {
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid;
        text-align: center;
        font-size: 14px;
        line-height: 36px;
        color: $color_link;
        transition: color $time_transition ease-out;
        margin-left: 12px;
        position: relative;
        padding: 0;

        &:before, &:after {
          content: '';
          left: 50%;
          top: 50%;
          position: absolute;
          background: $color_link;
          transition: background $time_transition ease-out;
        }

        &:before {
          width: 12px;
          height: 2px;
          margin-top: -1px;
          margin-left: -6px;
        }

        &:after {
          width: 2px;
          height: 12px;
          margin-left: -1px;
          margin-top: -6px;
        }

        &:hover {
          color: $color_active;

          &:before, &:after {
            background: $color_active;
          }
        }
      }
    }
  }
}
