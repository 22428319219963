/*
 * Style for details, history page
 */

@import '../../../containers/colors';
@import '../../../containers/mixin';


.history {
  .scatterplot__svg {
    svg {
      overflow: visible;
    }
  }

  .bar_chart_group.row {
    padding: 15px 0;
    margin: 0;
    border-radius: 4px;

    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, .04)
    }

    > div:nth-child(1) > div {
      padding-left: 20px;
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      margin-top: -4px;
    }
  }

  .inactive-link {
    pointer-events: none;
    cursor: default;
  }

  h3 {
    font-size: 15px;
    margin-bottom: 0;
  }

  .legend {
    @include position(absolute, 63px 120px none none);

    .data-item {
      margin-left: 10px;

      .data-text {
        display: none;
      }

      .data-desc {
        opacity: 1;
        padding-bottom: 4px;
        font-size: 11px;
        border-bottom: 1px solid;
      }

      &.rating .data-desc {
        color: darken($light_chart_primary, 4%);
      }

      &.defence .data-desc {
        color: darken($light_chart_secondary, 4%);
      }

      &.offence .data-desc {
        color: darken($light_chart_tertiary, 4%);
      }

      &.mean .data-desc {
        color: $light_chart_mean;
      }
    }
  }

  canvas {
    opacity: .9;
  }

  .axis.x_axis .tick text {
    fill: #6b7f8e;
  }

  .axis.x_axis .tick a {
    text {
      cursor: pointer;
      fill: $squad_link_hover;
    }

    &:hover {
      text {
        fill: darken($squad_link_hover, 10%);
      }
    }

    .red {
      fill: $red;
    }

    .green {
      fill: $green;
    }

    .blue {
      fill: $blue_lightest;
    }
  }

  .axis_label {
    position: relative;
    font-size: 13px;
    color: $blue_grey;
    text-transform: uppercase;
    height: auto;

    .axisLeft {
      position: absolute;
      left: -16px;
      top: -300px;
      @include rotate(-90deg);
    }

    .axisX {
      position: absolute;
      left: -9px;
      top: -21px;
    }

  }

  .scatterplot .menu {
    display: none;
  }

  .content_group.history_overtime {
    margin-bottom: 0;
  }
}

