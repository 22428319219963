@import '../containers/colors';
@import '../containers/mixin';

.auth {
  height: 100vh;
  background: $blue;
  line-height: 99vh;
  text-align: center;
  vertical-align: middle;
  overflow: auto;

  @media (max-width: $mq-tablet-portrait) {
    background: $blue_auth_container;
  }

  .auth-logo {
    display: none;
    width: 187px;
    vertical-align: middle;
    line-height: 0;

    @media (min-width: $mq-tablet-portrait + 1) {
      display: inline-block;
    }

    svg {
      width: 90px;
      height: 90px;
      color: $neongreen;
    }

    .logo-text {
      font-weight: 700;
      color: #fff;
      font-size: 28px;
      letter-spacing: -0.5%;
      margin-top: 16px;
      line-height: 36px;
    }
  }

  .auth-align {
    display: inline-block;
    text-align: left;
    line-height: 24px;
    width: 75%;
    margin: -50px auto 0px;
    vertical-align: middle;

    @media (min-width: $mq-tablet-portrait + 1) {
      margin: 0 0 0 88px;
      width: 338px;
    }
  }

  .auth-container {
    padding: 35px 54px 54px;
    background: $blue_auth_container;
    border-radius: 4px;

    @media (max-width: $mq-tablet-portrait) {
      background: none;
      padding: 25px 0 54px;
    }
  }

  .auth-title {
    font-weight: 700;
    color: #fff;
    font-size: 28px;
    letter-spacing: -0.5%;
    margin-top: 16px;
    line-height: 36px;
  }

  .auth-text {
    color: #fff;
    margin-top: 20px;
    font-weight: 200;

    b {
      font-weight: 400;
    }
  }

  .auth-helpers {
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
    color: $grey_darkness;
    text-align: justify;
    padding: 0 5px;

    .pull-left {
      float: left;

      @media (max-width: $mq-tablet-portrait) {
        margin-bottom: 20px;
        float: none;
      }
    }

    .pull-right {
      float: right;

      @media (max-width: $mq-tablet-portrait) {
        float: none;
      }
    }

    a {
      @media (max-width: $mq-tablet-portrait) {
        display: block;
      }

      cursor: pointer;
      color: #999;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
