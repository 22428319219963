/*
 * Style for tooltip used e.g. on overview page
 */

@import '../../containers/colors';
@import '../../containers/mixin';

// .overview_scatterplot .scatterplott .tooltip {
//   .col-6 > .pic,
//   .col-5 .pic,
//   .col-3 .pic {
//     margin-top: 20px;
//   }
// }

.tooltip {
  z-index: 10000 !important;
  display: none;
  color: white !important;
  border: 0 !important;
  @include border-radius(4px);
  width: 340px !important;
  height: auto !important;
  padding: 0 !important;
  background-color: $primary-color !important;
  @include box-shadow(0px, 3px, 6px, rgba(0, 0, 0, .16));
  @include box-shadow(0px, 3px, 6px, rgba(0, 0, 0, .23));

  .callAction {
    display: block;
    width: 50%;
    transition: background-color .3s;
    border-radius: 0 0 2px 2px;
    height: 50px;

    &.col-6 {
      margin: 0;
    }

    &:nth-child(1) {
      border-right: 1px solid rgba(255, 255, 255, .6);
    }

    &:hover {
      background-color: $primary-color !important;
      color: white;
      cursor: pointer;
      outline: none;
    }
  }

  h1 {
    font-size: 20px;
  }

  .btn, .btn_star {
    font-size: 13px;
  }

  .row {
    position: relative;

    &.tooltip__content {
      min-height: 168px;
    }
  }

  .col-6 > .pic,
  .col-5 .pic,
  .col-3 .pic {
    max-width: 150px;
    height: 168px;
    position: relative;

    > div {
      min-height: 168px;
      @include clip-path(polygon(0 0, 100% 0, 75% 100%, 0 100%));
      clip-path: url('#clipPolygon'); /*Firefox*/
      background-size: cover !important;
      background-color: darken($primary-color, 5%);
      padding-bottom: 0;

      @include border-radius(4px 0px 0px 0px);
    }
  }

  .away .pic {
    right: 0;

    > div {
      border-radius: 0 4px 0 0;
      min-height: 168px;
      @include clip-path(polygon(25% 0, 100% 0, 100% 100%, 0 100%));
      clip-path: url('#clipPolygonRight'); /*Firefox*/
      background-size: cover !important;
    }
  }

  .tooltip__content_header {
    &:after {
      display: block;
      clear: both;
      content: '';
    }

    .tooltip__content_header-container {
      float: right;
      padding: 0 2px;
    }

    .copy-url {
      display: inline-block;
      width: 50px;
      height: 45px;
      padding-top: 11px;

      .quick-copy {
        &:hover {
          svg {
            stroke: #35495C;
          }
        }
      }
    }

    .btn, .btn_star {
      padding: 0;
      display: inline-block;
      vertical-align: top;
      position: relative;
      z-index: 1;
      height: 45px;
      width: 44px;
      line-height: 45px;
      text-align: center;

      .icon-close {
        padding: 0;
        vertical-align: middle;
      }

      &:hover {
        .icon-close {
          color: $blue;
        }

        .icon-favorite {
          stroke: $blue;
          color: $blue;
        }
      }
    }

    .icon-close {
      font-size: 15px;
      color: white;
    }
  }

  .tooltip__content_name {
    padding-top: 0;
    margin-bottom: 0;

    .firstname {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 110%;
      letter-spacing: .1px;
    }

    .lastname {
      text-transform: uppercase;
      font-size: 19px;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: .8px;
    }

    .team {
      font-size: 13px;
      padding: 2px 5px 2px 0;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 140%; /* fallback */
      max-height: 40px; /* fallback */
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .country {
      font-size: 13px;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 2px 5px 2px 0;

      .country-flag, .pic {
        display: inline-block;
        vertical-align: middle;
        top: -2px;
        width: 16px;
      }
    }
  }

  .market-row {
    margin-top: 4px;
    padding-bottom: 4px;

    .contract-until, .market-value {
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 2px;

    }
  }

  .grey-container {
    background: #F8F9FA;
  }


  .tooltip__content_header {
    position: relative;
    z-index: 5;
  }

  .tooltip__content_header + .tooltip__content_name {
    margin-top: -7px;
    // overflow: auto;
    width: 100%;
  }

  .tooltip__content_stats {
    margin: 10px -10px;

    .data-item {
      margin: 0 10px;
      color: #FFF;

      .data-text {
        font-size: 16px;
        font-weight: bold;
      }

      .data-text, .data-desc {
        color: #FFF;
      }
    }
  }

  .tooltip__content_stats-grey {
    background: #35495C;
    color: #FFF;
    width: 100%;

    .divider {
      width: 1px;
      height: 40px;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 40px;
        position: relative;
        background: rgba(255, 255, 255, .6);
        transform: translateY(-4px) rotate(13deg);
      }
    }

    .r {
      display: flex;
      padding: 25px 15px 15px;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-around;
    }

    .data-item {
      margin: 0 5px;
      color: #FFF;

      .data-text {
        font-size: 16px;
        font-weight: bold;
      }

      .data-text, .data-desc {
        color: #FFF;
      }
    }
  }

  .tooltip__content_actionbar {
    background: #35495C;
    z-index: 4;
    position: relative;
    margin-bottom: -1px;
    @include border-radius(0px 0px 4px 4px);
    display: flex;

    &.row:after {
      content: '';
    }

    .icon-more, .icon-compare {
      display: inline-block;
      padding: 2px 5px 0 0;
      margin-bottom: -2px;

      &:hover {
        color: white;
      }
    }

    .icon-compare:before {
      font-size: 15px;
    }

    .icon-more {
      font-size: 15px;
    }
  }

  .match-tooltip {
    .tooltip__content_name {
      .team {
        text-align: center;
        margin-top: 12px;
      }
    }

    .tooltip__content_stats {
      text-align: center;
    }

    .tooltip__content_stats + .tooltip__content_name {
      text-align: right;
      margin-left: -10px;
      margin-right: 5px;
    }

    .tooltip__content_header {
      margin-right: -20px;
    }

    .tooltip__content_header + .tooltip__content_name {
      margin-bottom: 0;
    }

    .col-3 {
      min-height: 20px;

      .pic {
        width: 70px;
        height: 100%;
        position: absolute;
      }
    }

    .col-6 {
      min-height: 167px;
    }
  }

  &.hovered {
    pointer-events: none;

    .tooltip__content_header {
      display: none;
    }

    .tooltip__content_actionbar {
      display: none;
    }

    .tooltip__content_name {
      margin-top: 20px;
    }

  }

  &__charts {
    background: #F8F9FA;
    display: flex;
    position: relative;

    .polar-chart-info {
      display: block;
      position: absolute;
      left: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      z-index: 100;


      svg {
        color: #0B2839;
        width: 20px;
        height: 20px;
      }

      &:hover {
        svg {
          color: $primary-color;
        }

      }
    }

    .polar-chart {
      text-align: center;
      position: relative;

      .Polarchart__pie-label__bg {
        display: none;
      }

      .Polarchart__pie-label {
        display: none;
      }

      .axis_name {
        font-size: 8px !important;
        line-height: 12px !important;
      }

      .icon-on, .icon-against {
        position: absolute;
        display: block;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #35495C;
      }
    }
  }
}

.tooltip-popup {
  z-index: 1001;
}

